import React from 'react';
import GridComponent from "../../gridComponent/GridComponent";

//style
import "./CustomerService.scss";

export default function CustomerService () {
    const subCategories = ['Customer Service Representative']

    return (
        <>
            <GridComponent
                subCategories={subCategories}
            />
        </>

     
    )
}
