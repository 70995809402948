import React, { useContext, useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import * as  userApiClient from '../../../../apiClient/userApiClient';
import { CategoryContext } from '../../../../context/CategoriesContext';

//style
import "./TrainingsExpired.scss"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function TrainingExpired(prop) {
  const {all} = prop.data;
  const {subCategory} = useContext(CategoryContext);

  return (
      <div>
          <h3 className="reports-revenue-heading">{subCategory}</h3>
          <div className="reports-revenue-container">
              <div>
                  <Box id="revenue-box" sx={{ width: "100%" }}>
                      <Grid
                          id="reports-revenue-element"
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                          <label>All</label>
                          <Item id="item-reports-revenue">{all}</Item>
                      </Grid>
                  </Box>
              </div>
          </div>
      </div>
  )
}