import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import * as  userApiClient from '../../../apiClient/userApiClient';

import PageTemplate from '../../../components/templates/PageTemplate/PageTemplate';

export default function EditAdminPassword() {

  const Navigate = useNavigate();

  const [editData, setEditData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const isButtonDisabled =
    editData.oldPassword === '' ||
    editData.newPassword === '' ||
    editData.confirmNewPassword === '' ||
    editData.newPassword !== editData.confirmNewPassword;

  const handleChange = (name, value) => {
    setEditData({ ...editData, [name]: value });
  };

  const handleSubmitInfoChange = async (event) => {
    event.preventDefault();
    const catchError = await userApiClient.editPassword(editData);
    if(catchError) {
      alert('You entered wrong password or new password is the same as the old one');
    } else {
      Navigate('/home');
    }
  };

  return (
    <PageTemplate>
      <div>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Edit Password
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmitInfoChange} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="oldPassword"
                    required
                    fullWidth
                    id="oldPassword"
                    label="Old password"
                    onChange={(event) => handleChange('oldPassword', event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="newPassword"
                    label="New password"
                    name="newPassword"
                    onChange={(event) => handleChange('newPassword', event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="confirmNewPassword"
                    label="Confirm new password"
                    name="confirmNewPassword"
                    onChange={(event) => handleChange('confirmNewPassword', event.target.value)}
                  />
                </Grid>
              </Grid>
              <Button
                disabled={isButtonDisabled}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
    </PageTemplate>
  );
}