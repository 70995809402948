import ImageCell from "../components/cells/ImageCell";

const dateSortComparator = (date1, date2) => {
    return new Date(date1) - new Date(date2)
};

export default {
    'Customer Service Representative' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'phone', headerName: 'PHONE NUMBER', width: 300},
        {field: 'userId', headerName: 'USER ID', width: 300},
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'type', headerName: 'TYPE', width: 300},
        {field: 'createdAt', headerName: 'CREATED AT', width: 300, sortComparator: dateSortComparator},
        {field: 'gender', headerName: 'GENDER', width: 300},
        {field: 'isActive', headerName: 'ACTIVE', width: 300, type: 'boolean'}
    ],
    'Purchase History Coach' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'userId', headerName: 'COACH ID', width: 300},
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'phone', headerName: 'PHONE NUMBER', width: 300},
        {field: 'nationality', headerName: 'NATIONALITY', width: 300},
        {field: 'gender', headerName: 'GENDER', width: 300},
        {field: 'exerciserName', headerName: 'EXERCISER NAME', width: 300},  
        {field: 'timeOfPurchase', headerName: 'TIME AND DATE OF PURCHASE', width: 300, sortComparator: dateSortComparator},
        {field: 'package', headerName: 'PACKAGE', width: 300},
        {field: 'price', headerName: 'PRICE', width: 300},
        {field: 'startDateTime', headerName: 'SESSION START', width: 300},
        {field: 'endDateTime', headerName: 'SESSION END', width: 300},

    ],

    'Current Purchases Coach' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'userId', headerName: 'COACH ID', width: 300}, 
        {field: 'phone', headerName: 'PHONE NUMBER', width: 300},
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'nationality', headerName: 'NATIONALITY', width: 300},
        {field: 'gender', headerName: 'GENDER', width: 300},
        {field: 'package', headerName: 'PACKAGE', width: 300},
        {field: 'timeOfPurchase', headerName: 'TIME AND DATE OF PURCHASE', width: 300, sortComparator: dateSortComparator},
        {field: 'price', headerName: 'PRICE', width: 300},
        {field: 'exerciserName', headerName: 'EXERCISER NAME', width: 300},
        {field: 'remainingSessions', headerName: 'REMAINING SESSIONS', width: 300},
    ],
    'Booked Training Coach' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'userId', headerName: 'COACH ID', width: 300}, 
        {field: 'phone', headerName: 'PHONE NUMBER', width: 300},
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'nationality', headerName: 'NATIONALITY', width: 300},
        {field: 'gender', headerName: 'GENDER', width: 300},
        {field: 'sessionStart', headerName: 'SESSION START', width: 300},
        {field: 'oneSessionLocation', headerName: 'SESSION LOCATION', width: 300},
        {field: 'exerciserName', headerName: 'EXERCISER NAME', width: 300},
    ],
    'Completed Training Sessions History Coach' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'userId', headerName: 'COACH ID', width: 300},
        {field: 'phone', headerName: 'PHONE NUMBER', width: 300}, 
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'nationality', headerName: 'NATIONALITY', width: 300},
        {field: 'gender', headerName: 'GENDER', width: 300},
        {field: 'package', headerName: 'PACKAGE', width: 300},
        {field: 'timeOfPurchase', headerName: 'TIME AND DATE OF PURCHASE', width: 300, sortComparator: dateSortComparator},
        {field: 'price', headerName: 'PRICE', width: 300},
        {field: 'expiryDate', headerName: 'EXPIRY DATE', width: 300, sortComparator: dateSortComparator},
        {field: 'remainingSessions', headerName: 'REMAINING SESSIONS', width: 300},
        {field: 'exerciserName', headerName: 'EXERCISER NAME', width: 300},
    ],
    'Cancelled Training Sessions History Coach' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'userId', headerName: 'COACH ID', width: 300},
        {field: 'phone', headerName: 'PHONE NUMBER', width: 300}, 
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'nationality', headerName: 'NATIONALITY', width: 300},
        {field: 'gender', headerName: 'GENDER', width: 300}, 
        {field: 'package', headerName: 'PACKAGE', width: 300},
        {field: 'timeOfPurchase', headerName: 'TIME AND DATE OF PURCHASE', width: 300, sortComparator: dateSortComparator},
        {field: 'price', headerName: 'PRICE', width: 300},
        {field: 'expiryDate', headerName: 'EXPIRY DATE', width: 300, sortComparator: dateSortComparator},
        {field: 'remainingSessions', headerName: 'REMAINING SESSIONS', width: 300},
        {field: 'exerciserName', headerName: 'EXERCISER NAME', width: 300},

    ],
    'Expired Training Package History Coach' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'userName', headerName: 'USERNAME', width: 300},
        {field: 'userId', headerName: 'COACH ID', width: 300},
        {field: 'phone', headerName: 'PHONE NUMBER', width: 300}, 
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'nationality', headerName: 'NATIONALITY', width: 300},
        {field: 'gender', headerName: 'GENDER', width: 300},

    ],
    'Purchase History Exerciser' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'userId', headerName: 'EXERCISER ID', width: 300},
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'nationality', headerName: 'NATIONALITY', width: 300}, 
        {field: 'gender', headerName: 'GENDER', width: 300},
        {field: 'package', headerName: 'PACKAGE', width: 300},
        {field: 'timeOfPurchase', headerName: 'TIME AND DATE OF PURCHASE', width: 300, sortComparator: dateSortComparator},
        {field: 'price', headerName: 'PRICE', width: 300},
        {field: 'startDateTime', headerName: 'SESSION START', width: 300},
        {field: 'endDateTime', headerName: 'SESSION COMPLETION', width: 300},
        {field: 'coachName', headerName: 'COACH NAME', width: 300},
    ],
    'Current Purchases Exerciser' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'userId', headerName: 'EXERCISER ID', width: 300},
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'nationality', headerName: 'NATIONALITY', width: 300},
        {field: 'gender', headerName: 'GENDER', width: 300},
        {field: 'package', headerName: 'PACKAGE', width: 300},
        {field: 'price', headerName: 'PRICE', width: 300},
        {field: 'timeOfPurchase', headerName: 'TIME AND DATE OF PURCHASE', width: 300, sortComparator: dateSortComparator},
        {field: 'coachName', headerName: 'COACH NAME', width: 300},
        {field: 'remainingSessions', headerName: 'REMAINING SESSIONS', width: 300},
    ],
    'Booked Training Exerciser' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'userId', headerName: 'EXERCISER ID', width: 300}, 
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'nationality', headerName: 'NATIONALITY', width: 300},
        {field: 'gender', headerName: 'GENDER', width: 300},
        {field: 'sessionStart', headerName: 'SESSION START', width: 300},
        {field: 'oneSessionLocation', headerName: 'SESSION LOCATION', width: 300},
        {field: 'coachName', headerName: 'COACH NAME', width: 300},
    ],
    'Completed Training Sessions History Exerciser' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'userId', headerName: 'EXERCISER ID', width: 300}, 
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'nationality', headerName: 'NATIONALITY', width: 300},
        {field: 'gender', headerName: 'GENDER', width: 300},
        {field: 'package', headerName: 'PACKAGE', width: 300}, 
        {field: 'timeOfPurchase', headerName: 'TIME AND DATE OF PURCHASE', width: 300, sortComparator: dateSortComparator},
        {field: 'price', headerName: 'PRICE', width: 300},
        {field: 'expiryDate', headerName: 'EXPIRY DATE', width: 300, sortComparator: dateSortComparator},
        {field: 'remainingSessions', headerName: 'REMAINING SESSIONS', width: 300}, 
        {field: 'coachName', headerName: 'COACH NAME', width: 300}, 
        
    ],
    'Cancelled Training Sessions History Exerciser' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'userId', headerName: 'EXERCISER ID', width: 300}, 
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'nationality', headerName: 'NATIONALITY', width: 300},
        {field: 'gender', headerName: 'GENDER', width: 300},
        {field: 'package', headerName: 'PACKAGE', width: 300},
        {field: 'timeOfPurchase', headerName: 'TIME AND DATE OF PURCHASE', width: 300, sortComparator: dateSortComparator},
        {field: 'price', headerName: 'PRICE', width: 300},
        {field: 'expiryDate', headerName: 'EXPIRY DATE', width: 300},
        {field: 'remainingSessions', headerName: 'REMAINING SESSIONS', width: 300},
        {field: 'coachName', headerName: 'COACH NAME', width: 300}, 
    ],
    'Expired Training Sessions History Exerciser' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'trainerId', headerName: 'COACH ID', width: 300},
        {field: 'numberOfSessions', headerName: 'NUMBER OF SESSIONS IN BUNDLE', width: 300},
    ],
    'Manage Coaches' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'isActive', headerName: 'ACTIVE', width: 300, type: 'boolean'},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'phone', headerName: 'PHONE', width: 300},
        {field: 'userId', headerName: 'USER ID', width: 300},
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'createdAt', headerName: 'CREATED AT', width: 300, sortComparator: dateSortComparator},
        {field: 'gender', headerName: 'GENDER', width: 300},
    ],

    'Manage Exercisers' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'username', headerName: 'USERNAME', width: 300},
        {field: 'userId', headerName: 'USER ID', width: 300},
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'type', headerName: 'TYPE', width: 300},
        {field: 'createdAt', headerName: 'CREATED AT', width: 300, sortComparator: dateSortComparator},
        {field: 'gender', headerName: 'GENDER', width: 300},
    ],

    'Manage Admins' : [
        {field: 'rowNumber', headerName: 'ROW', width: 300},
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'lastName', headerName: 'LAST NAME', width: 300},
        {field: 'userId', headerName: 'USER ID', width: 300},
        {field: 'email', headerName: 'EMAIL', width: 300},
        {field: 'timeOfPurchase', headerName: 'CREATION TIME AND DATE', width: 300, sortComparator: dateSortComparator},
        {field: 'type', headerName: 'TYPE', width: 300},
        {field: 'isVerified', headerName: 'VERIFIED', width: 300, type: 'boolean'},

    ],

    'Manage Gyms': [
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'imageUrl', headerName: "IMAGE", flex: 1, renderCell: ImageCell}
    ],

    'Manage Zones': [
        {field: 'name', headerName: 'NAME', width: 300},
        {field: 'latitude', headerName: "LATITUDE", width: 300},
        {field: 'longitude', headerName: "LONGITUDE", width: 300},
        {field: 'imageUrl', headerName: "IMAGE", flex: 1, renderCell: ImageCell}
    ]

}