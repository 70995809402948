import * as userApiClient from "../apiClient/userApiClient";

let rowNumber = 0;
let subCategory;
const setRowNumber = (gridCategoryName) => {
  if (!subCategory) {
    subCategory = gridCategoryName;
    return ++rowNumber;
  } else if (gridCategoryName === subCategory) {
    return ++rowNumber;
  } else if (gridCategoryName !== subCategory) {
    subCategory = gridCategoryName;
    rowNumber = 0;
    return ++rowNumber;
  }
};

export const getAllUsers = async () => {
  try {
    let result = await userApiClient.getAllUsers();

    const exercisers = result.data.exercisers.map((exerciser) => ({
      rowNumber: setRowNumber("allUsers"),
      name: exerciser?.userId?.firstName,
      lastName: exerciser?.userId?.lastName,
      username: exerciser?.userId?.username,
      userId: exerciser?._id,
      phone: exerciser?.userId?.phoneNumber,
      email: exerciser?.userId?.email,
      type: exerciser?.userId?.type == 1 ? "Trainer" : "Exerciser",
      createdAt: `${new Date(
        exerciser?.createdAt || exerciser?.CreatedAt
      ).toLocaleDateString()}, ${new Date(
        exerciser?.createdAt || exerciser?.CreatedAt
      ).toLocaleTimeString()}`,
      gender: exerciser?.userId?.gender == 0 ? "Male" : "Female",
      rowType: "all users",
      isActive: true,
    }));

    const trainers = result.data.trainers.map((trainer) => ({
      rowNumber: setRowNumber("allUsers"),
      name: trainer?.userId?.firstName,
      lastName: trainer?.userId?.lastName,
      userId: trainer?._id,
      username: trainer?.userId?.username,
      phone: trainer?.userId?.phoneNumber,
      email: trainer?.userId?.email,
      type: trainer?.userId?.type == 1 ? "Trainer" : "Exerciser",
      createdAt: `${new Date(
        trainer?.createdAt || trainer?.CreatedAt
      ).toLocaleDateString()}, ${new Date(
        trainer?.createdAt || trainer?.CreatedAt
      ).toLocaleTimeString()}`,
      gender: trainer?.userId?.gender == 0 ? "Male" : "Female",
      rowType: "all users",
      isActive: trainer.isActive
    }));

    const rowData = [...exercisers, ...trainers];

    return rowData;
  } catch (error) {
    console.log("error while getting All Users Data =>", error);
  }
};

export const getPurchasesTrainerFilter = async () => {
  try {
    let result = await userApiClient.getPurchasesTrainer();
    let completed = await userApiClient.getSessionsByStatusExerciserCompleted();

    const formatStartDateTimeArray = (sessions, roll) => {
      let completedSessions = [];
      for (let i = 0; i < completed.data.length; i++) {
        for (let j = 0; j < sessions.length; j++) {
          if (completed.data[i]._id === sessions[j]._id) {
            completedSessions.push(completed.data[i]);
          }
        }
      }

      if (roll === "display") {
        return completedSessions.map((session) => {
          return `${new Date(
            session.startDateTime
          ).toLocaleDateString()}/ ${new Date(
            session.startDateTime
          ).toLocaleTimeString()},`;
        });
      } else if (roll === "count") {
        if (sessions.length > 0) {
          console.log("count");
          return sessions.length;
        } else return 0;
      }
    };

    const formatEndDateTimeArray = (sessions) => {
      let completedSessions = [];
      for (let i = 0; i < completed.data.length; i++) {
        for (let j = 0; j < sessions.length; j++) {
          if (completed.data[i]._id === sessions[j]._id) {
            completedSessions.push(completed.data[i]);
          }
        }
      }

      return completedSessions.map((session) => {
        return `${new Date(
          session.endDateTime
        ).toLocaleDateString()}/ ${new Date(
          session.endDateTime
        ).toLocaleTimeString()},`;
      });
    };

    const trainingLocations = (sessions) => {
      const locations = sessions.map((sessison) => {
        return sessison?.pickedLocations?.name;
      });

      return locations;
    };

    let data = result.data.map((trainer) => ({
      rowNumber: setRowNumber("trainerPurchase"),
      name: trainer?.bundle?.trainerId?.userId?.firstName,
      lastName: trainer?.bundle?.trainerId?.userId?.lastName,
      username: trainer?.bundle?.trainerId?.userId?.username,
      userId: trainer?.bundle?.trainerId?._id,
      email: trainer?.bundle?.trainerId?.userId?.email,
      phone: trainer?.bundle?.trainerId?.userId?.phoneNumber,
      nationality: trainer?.bundle?.trainerId?.userId?.nationality,
      gender:
        trainer?.bundle?.trainerId?.userId?.gender == 0 ? "Male" : "Female",
      exerciserName: trainer?.bundle?.excerciserId?.userId?.firstName,
      exerciserLastName: trainer?.bundle?.excerciserId?.userId?.lastName,
      exerciserEmail: trainer?.bundle?.excerciserId?.userId?.email,
      exerciserGender: trainer?.bundle?.excerciserId?.userId?.gender
        ? "Male"
        : "Female",
      timeOfPurchase: `${new Date(
        trainer?.bundle?.createdAt
      ).toLocaleDateString()}, ${new Date(
        trainer?.bundle?.createdAt
      ).toLocaleTimeString()}`,
      package: trainer?.bundle?.name,
      price: trainer?.bundle?.price,
      startDateTime: formatStartDateTimeArray(trainer?.sessions, "display"),
      endDateTime: formatEndDateTimeArray(trainer?.sessions),
      numberOfCompletedSessions: formatStartDateTimeArray(
        trainer?.sessions,
        "count"
      ),
      trainingLocation: trainingLocations(trainer?.sessions),
      remainingSessions: trainer?.bundle?.isActive
        ? trainer?.bundle?.sessionsLeft
        : "",
      expiryDate: `${new Date(
        trainer?.bundle?.expiryDate
      ).toLocaleDateString()}, ${new Date(
        trainer?.bundle?.expiryDate
      ).toLocaleTimeString()}`,
      rowType: "Purchase History Coach",
    }));

    console.log(data);

    return data;
  } catch (error) {
    console.log("error getting Purchases Trainer =>", error);
  }
};

export const getActiveBundlesByTrainer = async () => {
  try {
    let result = await userApiClient.getActiveBundlesByTrainer();
    let completed = await userApiClient.getSessionsByStatusExerciserCompleted();

    const formatStartDateTimeArray = (sessions, roll) => {
      let completedSessions = [];

      for (let i = 0; i < completed.data.length; i++) {
        for (let j = 0; j < sessions?.length; j++) {
          if (completed.data[i]._id === sessions[j]._id) {
            completedSessions.push(completed.data[i]);
          }
        }
      }

      if (roll === "display") {
        return completedSessions.map((session) => {
          return `${new Date(
            session.startDateTime
          ).toLocaleDateString()}/ ${new Date(
            session.startDateTime
          ).toLocaleTimeString()},`;
        });
      } else if (roll === "count") {
        if (sessions?.length > 0) {
          console.log("count");
          return sessions.length;
        } else return 0;
      }
    };

    let data = result.data.map((trainer) => ({
      rowNumber: setRowNumber("trainerCurrentPurchase"),
      name: trainer?.trainerId?.userId?.firstName,
      lastName: trainer?.trainerId?.userId?.lastName,
      username: trainer?.trainerId?.userId?.username,
      userId: trainer?.trainerId?._id,
      phone: trainer?.trainerId?.userId?.phoneNumber,
      email: trainer?.trainerId?.userId?.email,
      nationality: trainer?.trainerId.userId?.nationality,
      gender: trainer?.trainerId?.userId?.gender == 0 ? "Male" : "Female",
      package: trainer?.name,
      price: trainer?.price,
      timeOfPurchase: `${new Date(
        trainer?.createdAt
      ).toLocaleDateString()}, ${new Date(
        trainer?.createdAt
      ).toLocaleTimeString()}`,
      exerciserName: trainer?.excerciserId?.userId?.firstName,
      exerciserLastName: trainer?.excerciserId?.userId?.lastName,
      exerciserEmail: trainer?.excerciserId?.userId?.email,
      exerciserGender:
        trainer?.excerciserId?.userId?.gender == 0 ? "Male" : "Female",
      startDateTime: formatStartDateTimeArray(trainer?.sessions, "display"),
      expiryDate: `${new Date(
        trainer?.expiryDate
      ).toLocaleDateString()}, ${new Date(
        trainer?.expiryDate
      ).toLocaleTimeString()}`,
      numberOfCompletedSessions: formatStartDateTimeArray(
        trainer?.sessions,
        "count"
      ),
      remainingSessions: trainer?.sessionsLeft,
      rowType: "Active Packages Coach",
    }));

    return data;
  } catch (error) {
    console.log("error getting Active Packages =>", error);
  }
};

export const bookedTrainingTrainer = async () => {
  try {
    let result = await userApiClient.getSessionsByStatusTrainerBooked();

    let data = result.data.map((client) => ({
      rowNumber: setRowNumber("trainerBookedSessions"),
      name: client?.trainerId?.userId?.firstName,
      lastName: client?.trainerId?.userId?.lastName,
      username: client?.trainerId?.userId?.username,
      userId: client?.trainerId?._id,
      phone: client?.trainerId?.userId?.phoneNumber,
      email: client?.trainerId?.userId?.email,
      nationality: client?.trainerId?.userId?.nationality,
      gender: client?.trainerId?.userId?.gender == 0 ? "Male" : "Female",
      sessionStart: `${new Date(
        client?.startDateTime
      ).toLocaleDateString()}, ${new Date(
        client?.startDateTime
      ).toLocaleTimeString()}`,
      oneSessionLocation: client?.pickedLocations?.name,
      exerciserName: client?.exerciserId?.userId?.firstName,
      exerciserLastName: client?.exerciserId?.userId?.lastName,
      exerciserEmail: client?.exerciserId?.userId?.email,
      exerciserGender: client?.exerciserId?.userId?.gender,
      package: client?.bundleId?.name,
      timeOfPurchase: `${new Date(
        client?.bundleId?.createdAt
      ).toLocaleDateString()}, ${new Date(
        client?.bundleId?.createdAt
      ).toLocaleTimeString()}`,
      price: client?.bundleId?.price,
      rowType: "Booked Training Coach",
    }));

    return data;
  } catch (error) {
    console.log("error getting Booked Training  =>", error);
  }
};

export const completedTrainingTrainer = async () => {
  try {
    let result = await userApiClient.getSessionsByStatusTrainerCompleted();

    let data = result.data.map((trainer) => ({
      rowNumber: setRowNumber("trainerCompletedSessions"),
      name: trainer?.trainerId?.userId?.firstName,
      lastName: trainer?.trainerId?.userId?.lastName,
      username: trainer?.trainerId?.userId?.username,
      userId: trainer?.trainerId?._id,
      phone: trainer?.trainerId?.userId?.phoneNumber,
      email: trainer?.trainerId?.userId?.email,
      nationality: trainer?.trainerId?.userId?.nationality,
      gender: trainer?.trainerId?.userId?.gender == 0 ? "Male" : "Female",
      package: trainer?.bundleId?.name,
      timeOfPurchase: `${new Date(
        trainer?.bundleId?.createdAt
      ).toLocaleDateString()}, ${new Date(
        trainer?.bundleId?.createdAt
      ).toLocaleTimeString()}`,
      price: trainer?.bundleId?.price,
      expiryDate: `${new Date(
        trainer?.bundleId?.expiryDate
      ).toLocaleDateString()}, ${new Date(
        trainer?.bundleId?.expiryDate
      ).toLocaleTimeString()}`,
      remainingSessions: trainer?.bundleId?.sessionsLeft,
      exerciserName: trainer?.exerciserId?.userId?.firstName,
      exerciserLastName: trainer?.exerciserId?.userId.lastName,
      exerciserEmail: trainer?.exerciserId?.userId?.email,
      exerciserGender:
        trainer?.exerciserId?.userId?.gender == 0 ? "Male" : "Female",
      bookedDateTime: `${new Date(
        trainer?.createdAt
      ).toLocaleDateString()}, ${new Date(
        trainer?.createdAt
      ).toLocaleTimeString()}`,
      sessionStart: `${new Date(
        trainer?.startDateTime
      ).toLocaleDateString()}, ${new Date(
        trainer?.startDateTime
      ).toLocaleTimeString()}`,
      sessionEnd: `${new Date(
        trainer?.endDateTime
      ).toLocaleDateString()}, ${new Date(
        trainer?.endDateTime
      ).toLocaleTimeString()}`,
      oneSessionLocation:
        trainer?.pickedLocations?.address || trainer?.pickedLocations?.name,
      rowType: "Completed Training Sessions History Coach",
    }));

    return data;
  } catch (error) {
    console.log("error getting Completed Training Sessions History =>", error);
  }
};

export const cancelledTrainingTrainer = async () => {
  try {
    let result = await userApiClient.getSessionsByStatusTrainerCancelled();

    let data = result.data.map((session) => ({
      rowNumber: setRowNumber("trainerCanceledSesisons"),
      name: session?.trainerId?.userId?.firstName,
      lastName: session?.trainerId?.userId?.lastName,
      username: session?.trainerId?.userId?.username,
      userId: session?.trainerId?._id,
      phone: session?.trainerId?.userId?.phoneNumber,
      email: session?.trainerId?.userId?.email,
      nationality: session?.trainerId?.userId?.nationality,
      gender: session?.trainerId?.userId?.gender == 0 ? "Male" : "Female",
      package: session?.bundleId?.name,
      timeOfPurchase: `${new Date(
        session?.bundleId?.createdAt
      ).toLocaleDateString()}, ${new Date(
        session?.bundleId?.createdAt
      ).toLocaleTimeString()}`,
      price: session?.bundleId?.price,
      expiryDate: `${new Date(
        session?.bundleId?.expiryDate
      ).toLocaleDateString()}, ${new Date(
        session?.bundleId?.expiryDate
      ).toLocaleTimeString()}`,
      remainingSessions: session?.bundleId?.sessionsLeft,
      trainingSessionNumber: session?.sessionId?.numberOfSessionInBundle,
      // bookedDateTime: `${new Date(session?.sessionId?.createdAt).toLocaleDateString()}, ${new Date(session?.sessionId?.createdAt).toLocaleTimeString()}`,
      cancelationDateTime: `${new Date(
        session?.createdAt
      ).toLocaleDateString()}, ${new Date(
        session?.createdAt
      ).toLocaleTimeString()}`,
      exerciserName: session?.exerciserId?.userId?.firstName,
      exerciserLastName: session?.exerciserId?.userId.lastName,
      exerciserEmail: session?.exerciserId?.userId?.email,
      exerciserGender:
        session?.exerciserId?.userId?.gender == 0 ? "Male" : "Female",
      rowType: "Cancelled Training Sessions History Coach",
    }));

    return data;
  } catch (error) {
    console.log("error getting Cancelled Training Sessions History =>", error);
  }
};

export const expiredTrainingTrainer = async () => {
  try {
    let result = await userApiClient.getSessionsByStatusTrainerExpired();

    let data = result.data.map((session) => ({
      rowNumber: setRowNumber("trainerExpiredSessions"),
      name: session?.trainerId?.userId?.firstName,
      lastName: session?.trainerId?.userId?.lastName,
      userName: session?.trainerId?.userId?.username,
      userId: session?.trainerId?._id,
      phone: session?.trainerId?.userId?.phoneNumber,
      email: session?.trainerId?.userId?.email,
      nationality: session?.trainerId?.userId?.nationality,
      gender: session?.trainerId?.userId?.gender == 0 ? "Male" : "Female",
      rowType: "Expired Training Packages History Coach",
    }));

    return data;
  } catch (error) {
    console.log("error getting Expired Training Sessions History =>", error);
  }
};

export const getPurchasesExerciserFilter = async () => {
  try {
    let result = await userApiClient.getPurchasesExerciserFilter();
    let completed = await userApiClient.getSessionsByStatusExerciserCompleted();

    const formatStartDateTimeArray = (sessions, roll) => {
      let completedSessions = [];
      for (let i = 0; i < completed.data.length; i++) {
        for (let j = 0; j < sessions.length; j++) {
          if (completed.data[i]._id === sessions[j]._id) {
            completedSessions.push(completed.data[i]);
          }
        }
      }

      if (roll === "display") {
        return completedSessions.map((session) => {
          return `${new Date(
            session.startDateTime
          ).toLocaleDateString()}/ ${new Date(
            session.startDateTime
          ).toLocaleTimeString()},`;
        });
      } else if (roll === "count") {
        if (sessions.length > 0) {
          return sessions.length;
        } else return 0;
      }
    };

    const formatEndDateTimeArray = (sessions) => {
      let completedSessions = [];
      for (let i = 0; i < completed.data.length; i++) {
        for (let j = 0; j < sessions.length; j++) {
          if (completed.data[i]._id === sessions[j]._id) {
            completedSessions.push(completed.data[i]);
          }
        }
      }

      return completedSessions.map((session) => {
        return `${new Date(
          session.endDateTime
        ).toLocaleDateString()}/ ${new Date(
          session.endDateTime
        ).toLocaleTimeString()},`;
      });
    };

    const trainingLocations = (sessions) => {
      const locations = sessions.map((sessison) => {
        return sessison?.pickedLocations?.name;
      });

      return locations;
    };

    let data = result.data.map((client) => ({
      rowNumber: setRowNumber("exerciserPurchase"),
      name: client.bundle.excerciserId.userId.firstName,
      lastName: client.bundle.excerciserId.userId.lastName,
      username: client.bundle.excerciserId.userId.username,
      userId: client.bundle.excerciserId._id,
      email: client.bundle.excerciserId.userId.email,
      package: client.bundle.name,
      timeOfPurchase: `${new Date(
        client.bundle.createdAt
      ).toLocaleDateString()}, ${new Date(
        client.bundle.createdAt
      ).toLocaleTimeString()}`,
      price: client.bundle.price,
      startDateTime: formatStartDateTimeArray(client.sessions, "display"),
      endDateTime: formatEndDateTimeArray(client.sessions),
      nationality: client.bundle.excerciserId.userId.nationality,
      gender: client.bundle.excerciserId.userId.gender == 0 ? "Male" : "Female",
      coachName: client.bundle.trainerId?.userId.firstName,
      coachLastName: client.bundle.trainerId?.userId.lastName,
      coachPhone: client.bundle.trainerId?.userId.phoneNumber,
      coachEmail: client.bundle.trainerId?.userId.email,
      coachGender:
        client.bundle?.trainerId?.userId.gender == 0 ? "Male" : "Female",
      numberOfCompletedSessions: formatStartDateTimeArray(
        client.sessions,
        "count"
      ),
      trainingLocation: trainingLocations(client?.sessions),
      remainingSessions: client.bundle.isActive
        ? client.bundle.sessionsLeft
        : "",
      expiryDate: `${new Date(
        client.bundle.expiryDate
      ).toLocaleDateString()}, ${new Date(
        client.bundle.expiryDate
      ).toLocaleTimeString()}`,
      rowType: "Purchase History Exerciser",
    }));

    return data;
  } catch (error) {
    console.log("error getting Purchases Exerciser Filter  =>", error);
  }
};

export const getActiveBundlesByExerciser = async () => {
  try {
    let result = await userApiClient.getActiveBundlesByExerciser();
    let completed = await userApiClient.getSessionsByStatusExerciserCompleted();

    const formatStartDateTimeArray = (sessions, roll) => {
      let completedSessions = [];
      for (let i = 0; i < completed.data.length; i++) {
        for (let j = 0; j < sessions.length; j++) {
          if (completed.data[i]._id === sessions[j]._id) {
            completedSessions.push(completed.data[i]);
          }
        }
      }

      if (roll === "display") {
        return completedSessions.map((session) => {
          return `${new Date(
            session.startDateTime
          ).toLocaleDateString()}/ ${new Date(
            session.startDateTime
          ).toLocaleTimeString()},`;
        });
      } else if (roll === "count") {
        if (sessions.length > 0) {
          return sessions.length;
        } else return 0;
      }
    };

    let data = result.data.map((client) => ({
      rowNumber: setRowNumber("exerciserCurrentPurchase"),
      name: client.bundle.excerciserId.userId.firstName,
      lastName: client.bundle.excerciserId.userId.lastName,
      email: client.bundle.excerciserId.userId.email,
      username: client.bundle.excerciserId.userId.username,
      userId: client.bundle.excerciserId._id,
      nationality: client.bundle.excerciserId.userId.nationality,
      gender: client.bundle.excerciserId.userId.gender == 0 ? "Male" : "Female",
      timeOfPurchase: `${new Date(
        client.bundle.createdAt
      ).toLocaleDateString()}, ${new Date(
        client.bundle.createdAt
      ).toLocaleTimeString()}`,
      package: client.bundle.name,
      price: client.bundle.price,
      remainingSessions: client?.bundle?.sessionsLeft,
      startDateTime: formatStartDateTimeArray(client.sessions, "display"),
      expiryDate: `${new Date(
        client.bundle.expiryDate
      ).toLocaleDateString()}, ${new Date(
        client.bundle.expiryDate
      ).toLocaleTimeString()}`,
      numberOfCompletedSessions: formatStartDateTimeArray(
        client.sessions,
        "count"
      ),
      coachName: client.bundle.trainerId.userId.firstName,
      coachLastName: client.bundle.trainerId.userId.lastName,
      coachPhone: client.bundle.trainerId.userId.phoneNumber,
      rowType: "Active Packages exerciser",
    }));

    return data;
  } catch (error) {
    console.log("error getting Active Bundles by Exerciser  =>", error);
  }
};

export const bookedTrainingExerciser = async () => {
  try {
    let result = await userApiClient.getSessionsByStatusExerciserBooked();

    let data = result.data.map((client) => ({
      rowNumber: setRowNumber("exerciserBookedSessions"),
      name: client?.exerciserId?.userId?.firstName,
      lastName: client?.exerciserId?.userId?.lastName,
      username: client?.exerciserId?.userId?.username,
      userId: client?.exerciserId?._id,
      email: client?.exerciserId?.userId?.email,
      nationality: client?.exerciserId?.userId?.nationality,
      gender: client?.exerciserId?.userId?.gender == 0 ? "Male" : "Female",
      sessionStart: `${new Date(
        client?.startDateTime
      ).toLocaleDateString()}, ${new Date(
        client?.startDateTime
      ).toLocaleTimeString()}`,
      oneSessionLocation: client?.pickedLocations?.name,
      timeOfPurchase: `${new Date(
        client?.bundleId?.createdAt
      ).toLocaleDateString()}, ${new Date(
        client?.bundleId?.createdAt
      ).toLocaleTimeString()}`,
      package: client?.bundleId?.name,
      price: client?.bundleId?.price,
      coachName: client?.trainerId?.userId?.firstName,
      coachLastName: client?.trainerId?.userId?.lastName,
      coachPhone: client?.trainerId?.userId?.phoneNumber,
      coachEmail: client?.trainerId?.userId?.email,
      rowType: "Booked Training Exerciser",
    }));

    return data;
  } catch (error) {
    console.log("error getting Booked Training  =>", error);
  }
};

export const completedTrainingExerciser = async () => {
  try {
    let result = await userApiClient.getSessionsByStatusExerciserCompleted();

    let data = result.data.map((client) => ({
      rowNumber: setRowNumber("exericserCompletedSessions"),
      name: client?.exerciserId?.userId?.firstName,
      lastName: client?.exerciserId?.userId?.lastName,
      username: client?.exerciserId?.userId?.username,
      userId: client?.exerciserId?._id,
      email: client?.exerciserId?.userId?.email,
      nationality: client?.exerciserId?.userId?.nationality,
      gender: client?.exerciserId?.userId?.gender == 0 ? "Male" : "Female",
      package: client?.bundleId?.name,
      price: client?.bundleId?.price,
      timeOfPurchase: `${new Date(
        client?.bundleId?.createdAt
      ).toLocaleDateString()}, ${new Date(
        client?.bundleId?.createdAt
      ).toLocaleTimeString()}`,
      expiryDate: `${new Date(
        client?.bundleId?.expiryDate
      ).toLocaleDateString()}, ${new Date(
        client?.bundleId?.expiryDate
      ).toLocaleTimeString()}`,
      remainingSessions: client?.bundleId?.sessionsLeft,
      bookedDateTime: `${new Date(
        client?.createdAt
      ).toLocaleDateString()}, ${new Date(
        client?.createdAt
      ).toLocaleTimeString()}`,
      oneSessionLocation: client?.pickedLocations?.address,
      sessionStart: `${new Date(
        client?.startDateTime
      ).toLocaleDateString()}, ${new Date(
        client?.startDateTime
      ).toLocaleTimeString()}`,
      sessionEnd: `${new Date(
        client?.endDateTime
      ).toLocaleDateString()}, ${new Date(
        client?.endDateTime
      ).toLocaleTimeString()}`,
      coachName: client?.trainerId?.userId?.firstName,
      coachLastName: client?.trainerId?.userId?.lastName,
      coachPhone: client?.trainerId?.userId?.phoneNumber,
      coachEmail: client?.trainerId?.userId?.email,
      rowType: "Completed Training Sessions History Exerciser",
    }));

    return data;
  } catch (error) {
    console.log("error getting Completed Training  =>", error);
  }
};

export const cancelledSessionsExerciser = async () => {
  try {
    let result = await userApiClient.getSessionsByStatusExerciserCancelled();

    let data = result.data.map((session) => ({
      rowNumber: setRowNumber("exerciserCanceledSessions"),
      name: session?.exerciserId?.userId?.firstName,
      lastName: session?.exerciserId?.userId?.lastName,
      username: session?.exerciserId?.userId?.username,
      userId: session?.sessionId?.trainerId,
      email: session?.exerciserId?.userId?.email,
      nationality: session?.exerciserId?.userId?.nationality,
      gender: session?.exerciserId?.userId?.gender == 0 ? "Male" : "Female",
      timeOfPurchase: `${new Date(
        session?.bundleId?.createdAt
      ).toLocaleDateString()}/ ${new Date(
        session?.bundleId?.createdAt
      ).toLocaleTimeString()}`,
      package: session?.bundleId?.name,
      price: session?.bundleId?.price,
      remainingSessions: session?.bundleId?.sessionsLeft,
      expiryDate: `${new Date(
        session?.bundleId?.expiryDate
      ).toLocaleDateString()}/ ${new Date(
        session?.bundleId?.expiryDate
      ).toLocaleTimeString()}`,
      trainingSessionNumber: session?.sessionId?.numberOfSessionInBundle,
      // bookedDateTime: `${new Date(session?.sessionId?.createdAt).toLocaleDateString()}/ ${new Date(session?.sessionId?.createdAt).toLocaleTimeString()}`,
      cancelationDateTime: `${new Date(
        session?.createdAt
      ).toLocaleDateString()}/ ${new Date(
        session?.createdAt
      ).toLocaleTimeString()}`,
      coachName: session?.trainerId?.userId?.firstName,
      rowType: "Cancelled Training Sessions History Exerciser",
    }));

    return data;
  } catch (error) {
    console.log(
      "error getting Cancelled Training History Exerciser  =>",
      error
    );
  }
};

export const expiredTrainingSessions = async () => {
  try {
    let result = await userApiClient.getSessionsByStatusExerciserExpired();

    let data = result.data.map((session) => ({
      rowNumber: setRowNumber("exerciserExpiredSessions"),
      trainerId: session.trainerId,
      numberOfSessions: session.numberOfSessionInBundle,
      rowType: "Expired Training Packages History Exerciser",
    }));

    return data;
  } catch (error) {
    console.log("error getting Expired Training  =>", error);
  }
};

export const newRegistratedExercisers = async () => {
  try {
    const newExercisers =
      await userApiClient.getNumberNewRegistratedExercisers();

    let data = {
      all: newExercisers[0].data.number,
      male: newExercisers[1].data.number,
      female: newExercisers[2].data.number,
    };

    return data;
  } catch (error) {
    console.log("error getting New Registrated Exercisers  =>", error);
  }
};

export const newRegistratedTrainers = async () => {
  try {
    const newTrainers = await userApiClient.getNumberNewRegistratedTrainers();

    let data = {
      all: newTrainers[0].data.number,
      male: newTrainers[1].data.number,
      female: newTrainers[2].data.number,
    };

    return data;
  } catch (error) {
    console.log("error getting New Registrated Trainers  =>", error);
  }
};

export const getExercisersAndTrainers = async () => {
  try {
    const users = await userApiClient.getExercisersAndTrainersData();

    console.log(users.data.exercisers.length);

    users.data.exercisers.forEach((user) => {
      console.log(user.userId);
    });
    return users;
  } catch (error) {
    console.log("error getting Users  =>", error);
  }
};

export const allTrainers = async () => {
  try {
    let result = await userApiClient.getAllTrainers();

    const trainers = result.data.map((trainer) => ({
      rowNumber: setRowNumber("coaches"),
      userImage: trainer?.userId?.profilePhotoUrl,
      name: trainer?.userId?.firstName,
      lastName: trainer?.userId?.lastName,
      userId: trainer?._id,
      username: trainer?.userId?.username,
      phone: trainer?.userId?.phoneNumber,
      email: trainer?.userId?.email,
      isActive: trainer?.isActive,
      createdAt: `${new Date(
        trainer?.CreatedAt || trainer?.createdAt
      ).toLocaleDateString()}, ${new Date(
        trainer?.CreatedAt || trainer?.createdAt
      ).toLocaleTimeString()}`,
      gender: trainer?.userId?.gender == 0 ? "Male" : "Female",
      userSecondId: trainer?.userId?._id,
      rowType: "Coach Information",
    }));

    const rowData = [...trainers];

    return rowData;
  } catch (error) {
    console.log("error getting Trainers", error);
  }
};

export const allTrainerLocations = async (trainerUserId) => {
  try {
    let result = await userApiClient.getAllTrainerLocations(trainerUserId);

    return result.data;
  } catch (error) {
    console.log("error getting trainer locations", error);
  }
};

export const updateLocationImage = async (trainerUserId, formData) => {
  try {
    await userApiClient.updateLocationImage(trainerUserId, formData);
  } catch (error) {
    console.log("error updating location image");
  }
};

export const toggleTrainerActivity = async (trainerUserId) => {
  try {
    let result = await userApiClient.toggleTrainerActivity(trainerUserId)
    
    return result.data;
  } catch (error) {
    console.log("error updating status");
  }
}

export const allAdmins = async () => {
  try {
    let result = await userApiClient.getAllAdmins();

    const admins = result.data.map((admin) => ({
      rowNumber: setRowNumber("admins"),
      name: admin?.firstName,
      lastName: admin?.lastName,
      userId: admin?._id,
      email: admin?.email,
      type: admin?.type == 1 ? "Regular Admin" : "Super Admin",
      isVerified: admin?.isEmailVerified,
      timeOfPurchase: `${new Date(
        admin?.createdAt
      ).toLocaleDateString()}, ${new Date(
        admin?.createdAt
      ).toLocaleTimeString()}`,
      rowType: "Admin Information",
    }));

    const rowData = [...admins];

    return rowData;
  } catch (error) {
    console.log("error getting Admins", error);
  }
};

export const inactiveExercisers = async () => {
  try {
    const inactiveExercisers = await userApiClient.getInactiveExerciser();

    let data = {
      all: inactiveExercisers[0].data.numberOfInactiveExercisers,
      male: inactiveExercisers[1].data.numberOfInactiveExercisers,
      female: inactiveExercisers[2].data.numberOfInactiveExercisers,
    };

    return data;
  } catch (error) {
    console.log("error getting Inactive Exerciser  =>", error);
  }
};

export const inactiveTrainers = async () => {
  try {
    const inactiveTrainers = await userApiClient.getInactiveTrainers();

    let data = {
      all: inactiveTrainers[0].data.numberOfInactiveTrainers,
      male: inactiveTrainers[1].data.numberOfInactiveTrainers,
      female: inactiveTrainers[2].data.numberOfInactiveTrainers,
    };

    return data;
  } catch (error) {
    console.log("error getting Inactive Trainers  =>", error);
  }
};

export const percentageOfActiveTrainers = async () => {
  try {
    const activeTrainersPercentage =
      await userApiClient.getPercentageofActiveTrainers();

    let data = {
      all: Number(activeTrainersPercentage[0].data.difference.toFixed(1)),
      male: Number(activeTrainersPercentage[1].data.difference.toFixed(1)),
      female: Number(activeTrainersPercentage[2].data.difference.toFixed(1)),
    };

    return data;
  } catch (error) {
    console.log("error getting Percentage of Trainers  =>", error);
  }
};

export const percentageOfActiveExercisers = async () => {
  try {
    const activeTrainersPercentage =
      await userApiClient.getPercentageofActiveExercisers();

    let data = {
      all: Number(activeTrainersPercentage[0].data.difference.toFixed(1)),
      male: Number(activeTrainersPercentage[1].data.difference.toFixed(1)),
      female: Number(activeTrainersPercentage[2].data.difference.toFixed(1)),
    };

    return data;
  } catch (error) {
    console.log("error getting Percentage of Exercisers  =>", error);
  }
};

export const numberOfTrainingPackagesPurchased = async () => {
  try {
    const packagesPurchased =
      await userApiClient.getNumberOfTrainingPackagesPurchased();
      

    let data = {
      all: packagesPurchased[0].data.numberOfBundles,
      male: packagesPurchased[1].data.numberOfBundles,
      female: packagesPurchased[2].data.numberOfBundles,
    };

    return data;
  } catch (error) {
    console.log("error getting Number of Purchased Packages  =>", error);
  }
};

export const numberOfTrainingSessionsPurchased = async () => {
  try {
    const trainingPurchased =
      await userApiClient.getNumberOfTrainingSessionsPurchased();

    let data = {
      all: trainingPurchased[0].data.number,
      male: trainingPurchased[1].data.number,
      female: trainingPurchased[2].data.number,
    };

    return data;
  } catch (error) {
    console.log("error getting Number of Purchased Sessions  =>", error);
  }
};

export const numberOfOutstandingTraining = async () => {
  try {
    const trainingPurchased =
      await userApiClient.getNumberOfOutstandingTraining();

    let data = {
      all: trainingPurchased[0].data.number,
      male: trainingPurchased[1].data.number,
      female: trainingPurchased[2].data.number,
    };

    return data;
  } catch (error) {
    console.log("error getting Number of Outstanding Trainings  =>", error);
  }
};

export const averageValueOfOutstandingTraining = async () => {
  try {
    const trainingPurchased =
      await userApiClient.getAverageValueOfOutstandingTraining();

    let data = {
      all: Number(trainingPurchased[0].data.averagePrice.toFixed(0)),
      male: Number(trainingPurchased[1].data.averagePrice.toFixed(0)),
      female: Number(trainingPurchased[2].data.averagePrice.toFixed(0)),
    };

    return data;
  } catch (error) {
    console.log(
      "error getting Average Value of Outstanding Trainings  =>",
      error
    );
  }
};

export const totalNumberOfTrainingPrchased = async () => {
  try {
    const trainingPurchased =
      await userApiClient.getNumberOfTotalTrainingPurchased();

    let data = {
      all: trainingPurchased[0].data.numberOfTrainingsPurchased,
      male: trainingPurchased[1].data.numberOfTrainingsPurchased,
      female: trainingPurchased[2].data.numberOfTrainingsPurchased,
    };

    return data;
  } catch (error) {
    console.log("error getting Number of Purchased Trainings  =>", error);
  }
};

export const numberOfCompletedTrainings = async () => {
  try {
    const trainingCompleted =
      await userApiClient.getNumberOfTrainingsCompleted();

    let data = {
      all: trainingCompleted[0].data.numberOfSessions,
      male: trainingCompleted[1].data.numberOfSessions,
      female: trainingCompleted[2].data.numberOfSessions,
    };

    return data;
  } catch (error) {
    console.log("error getting Number of Completed Trainings  =>", error);
  }
};

export const numberOfExpiredTrainings = async () => {
  try {
    const trainingExpired = await userApiClient.getNumberOfTrainingsExpired();

    let data = {
      all: trainingExpired[0].data.numberOfSessions,
    };

    return data;
  } catch (error) {
    console.log("error getting Number of Expired Trainings  =>", error);
  }
};

export const numberOfCancelledTrainings = async () => {
  try {
    const trainingCancelledCount =
      await userApiClient.getNumberOfTrainingsCancelled();

    let data = {
      all: trainingCancelledCount[0].data.numberOfCancelledSessions,
      male: trainingCancelledCount[1].data.numberOfCancelledSessions,
      female: trainingCancelledCount[2].data.numberOfCancelledSessions,
    };

    return data;
  } catch (error) {
    console.log("error getting Number of Expired Trainings  =>", error);
  }
};

export const numberOfBookedTrainings = async () => {
  try {
    const trainingBooked = await userApiClient.getNumberOfTrainingsBooked();

    let data = {
      all: trainingBooked[0].data.numberOfSessions,
      male: trainingBooked[1].data.numberOfSessions,
      female: trainingBooked[2].data.numberOfSessions,
    };

    return data;
  } catch (error) {
    console.log("error getting Number of Booked Trainings  =>", error);
  }
};

export const valueOfBookedPurchasedTrainingPackages = async () => {
  try {
    const sessionValue = await userApiClient.getBookedSessionValue();

    let data = {
      all: Number(sessionValue[0].data.number.toFixed(0)),
      male: Number(sessionValue[1].data.number.toFixed(0)),
      female: Number(sessionValue[2].data.number.toFixed(0)),
    };

    return data;
  } catch (error) {
    console.log("error getting Price  =>", error);
  }
};

export const valueOfOutstandingPurchasedTrainingPackages = async () => {
  try {
    const sessionValue = await userApiClient.getOutstandingSessionValue();

    let data = {
      all: Number(sessionValue[0].data.number.toFixed(0)),
      male: Number(sessionValue[1].data.number.toFixed(0)),
      female: Number(sessionValue[2].data.number.toFixed(0)),
    };

    return data;
  } catch (error) {
    console.log("error getting Price  =>", error);
  }
};

export const valueOfCancelledPurchasedTrainingPackages = async () => {
  try {
    const cancelledSessionValue =
      await userApiClient.getCancelledSessionValue();

    let data = {
      all: Number(
        cancelledSessionValue[0].data.valueOfCancelledSessions.toFixed(0)
      ),
      male: Number(
        cancelledSessionValue[1].data.valueOfCancelledSessions.toFixed(0)
      ),
      female: Number(
        cancelledSessionValue[2].data.valueOfCancelledSessions.toFixed(0)
      ),
    };

    return data;
  } catch (error) {
    console.log("error getting Price  =>", error);
  }
};

export const valueOfCompletedPurchasedTrainingPackages = async () => {
  try {
    const sessionValue = await userApiClient.getCompletedSessionValue();

    let data = {
      all: Number(sessionValue[0].data.number.toFixed(0)),
      male: Number(sessionValue[1].data.number.toFixed(0)),
      female: Number(sessionValue[2].data.number.toFixed(0)),
    };

    return data;
  } catch (error) {
    console.log("error getting Price  =>", error);
  }
};

export const valueOfExpiredPurchasedTrainingPackages = async () => {
  try {
    const sessionValue = await userApiClient.getExpiredSessionValue();

    let data = {
      all: Number(sessionValue[0].data.price.toFixed(0)),
      male: Number(sessionValue[1].data.price.toFixed(0)),
      female: Number(sessionValue[2].data.price.toFixed(0)),
    };

    return data;
  } catch (error) {
    console.log("error getting Price  =>", error);
  }
};

export const totalValueOfTrainingPurchased = async () => {
  try {
    const sessionValue = await userApiClient.getTotalSessionValue();

    let data = {
      all: Number(sessionValue[0].data.number.toFixed(0)),
      male: Number(sessionValue[1].data.number.toFixed(0)),
      female: Number(sessionValue[2].data.number.toFixed(0)),
    };

    return data;
  } catch (error) {
    console.log("error getting Price  =>", error);
  }
};

export const averageValueOfBookedSessions = async () => {
  try {
    const sessionValue = await userApiClient.getAverageValueOfBookedSessions();

    let data = {
      all: Number(sessionValue[0].data.averagePrice.toFixed(0)),
      male: Number(sessionValue[1].data.averagePrice.toFixed(0)),
      female: Number(sessionValue[2].data.averagePrice.toFixed(0)),
    };

    return data;
  } catch (error) {
    console.log("error getting Price  =>", error);
  }
};

export const averageValueOfCancelledTraining = async () => {
  try {
    const sessionValue =
      await userApiClient.getAverageValueOfCancelledSessions();

    let data = {
      all: Number(
        sessionValue[0].data.averageValueOfCancelledSessions.toFixed(0)
      ),
      male: Number(
        sessionValue[1].data.averageValueOfCancelledSessions.toFixed(0)
      ),
      female: Number(
        sessionValue[2].data.averageValueOfCancelledSessions.toFixed(0)
      ),
    };

    return data;
  } catch (error) {
    console.log("error getting Price  =>", error);
  }
};

export const averageValueOfCompletedSessions = async () => {
  try {
    const sessionValue =
      await userApiClient.getAverageValueOfCompletedSessions();

    let data = {
      all: Number(sessionValue[0].data.averagePrice.toFixed(0)),
      male: Number(sessionValue[1].data.averagePrice.toFixed(0)),
      female: Number(sessionValue[2].data.averagePrice.toFixed(0)),
    };

    return data;
  } catch (error) {
    console.log("error getting Price  =>", error);
  }
};

export const averageValueOfExpiredSessions = async () => {
  try {
    const sessionValue = await userApiClient.getAverageValueOfExpiredSessions();

    let data = {
      all: Number(sessionValue[0].data.averagePrice.toFixed(0)),
      male: Number(sessionValue[1].data.averagePrice.toFixed(0)),
      female: Number(sessionValue[2].data.averagePrice.toFixed(0)),
    };

    return data;
  } catch (error) {
    console.log("error getting Price  =>", error);
  }
};

export const AverageValueOfTrainingFromAllTrainingPackagesPurchased =
  async () => {
    try {
      const sessionValue = await userApiClient.getAverageValueFromAllPackages();

      let data = {
        all: Number(sessionValue.data.averagePriceOfSessions.toFixed(0)),
      };

      return data;
    } catch (error) {
      console.log("error getting average value  =>", error);
    }
  };

export const getAdminProfile = async () => {
  try {
    const adminProfile = await userApiClient.getAdminProfileInformation();

    const admin = {
      name: adminProfile.data.firstName,
      lastName: adminProfile.data.lastName,
      userId: adminProfile.data._id,
      email: adminProfile.data.email,
      type: adminProfile.data.type == 1 ? "Regular Admin" : "Super Admin",
      isVerified: adminProfile.data.isEmailVerified,
      createdAt: `${new Date(
        adminProfile.data.createdAt
      ).toLocaleDateString()}, ${new Date(
        adminProfile.data.createdAt
      ).toLocaleTimeString()}`,
      rowType: "Admin Information",
      isFromProfile: true,
    };

    const rowData = [admin];

    return rowData;
  } catch (error) {
    console.log("error getting Admin Profile Information =>", error);
  }
};

export const totalNumberCompletedVsTotalNumberOfPurchasedTrainings =
  async () => {
    try {
      const sessionValue =
        await userApiClient.getTotalNumberCompletedVsTotalNumberOfPurchasedTrainings();

      let data = {
        all: Number(sessionValue[0].data.difference.toFixed(0)),
        male: Number(sessionValue[1].data.difference.toFixed(0)),
        female: Number(sessionValue[2].data.difference.toFixed(0)),
      };

      return data;
    } catch (error) {
      console.log(
        "error getting Pecent for totalNumberCompletedVsTotalNumberOfPurchasedTrainings =>",
        error
      );
    }
  };

export const totalNumberExpiredVsTotalNumberOfPurchasedTrainings = async () => {
  try {
    const sessionValue =
      await userApiClient.getTotalNumberExpiredVsTotalNumberOfPurchasedTrainings();

    let data = {
      all: Number(sessionValue[0].data.difference.toFixed(0)),
      male: Number(sessionValue[1].data.difference.toFixed(0)),
      female: Number(sessionValue[2].data.difference.toFixed(0)),
    };

    return data;
  } catch (error) {
    console.log(
      "error getting Percent for totalNumberExpiredVsTotalNumberOfPurchasedTrainings =>",
      error
    );

    const responseError = {
      error: true,
      errorMessage: error?.response?.data.message,
    };

    return responseError;
  }
};

export const totalNumberOutstandingVsTotalNumberOfPurchasedTrainings =
  async () => {
    try {
      const sessionValue =
        await userApiClient.getTotalNumberOutstandingVsTotalNumberOfPurchasedTrainings();

      let data = {
        all: Number(sessionValue[0].data.percentageDifference.toFixed(0)),
        male: Number(sessionValue[1].data.percentageDifference.toFixed(0)),
        female: Number(sessionValue[2].data.percentageDifference.toFixed(0)),
      };

      return data;
    } catch (error) {
      console.log(
        "error getting Percent For Total Number Outstanding Vs TotalNumber Of Purchased Trainings =>",
        error
      );
    }
  };

export const totalValueCompletedVsTotalValueOfPurchasedTrainings = async () => {
  try {
    const sessionValue =
      await userApiClient.getTotalValueCompletedVsTotalValueOfPurchasedTrainings();

    let data = {
      all: Number(sessionValue[0].data.percentageDifference.toFixed(0)),
      male: Number(sessionValue[1].data.percentageDifference.toFixed(0)),
      female: Number(sessionValue[2].data.percentageDifference.toFixed(0)),
    };
    console.log(data);
    return data;
  } catch (error) {
    console.log(
      "error getting Percent for totalValueCompletedVsTotalValueOfPurchasedTrainings =>",
      error
    );
  }
};

export const totalValueOutstadndingVsTotalValueOfPurchasedTrainings =
  async () => {
    try {
      const sessionValue =
        await userApiClient.getTotalValueOutstadndingVsTotalValueOfPurchasedTrainings();

      let data = {
        all: Number(sessionValue[0].data.percentageDifference.toFixed(0)),
        male: Number(sessionValue[1].data.percentageDifference.toFixed(0)),
        female: Number(sessionValue[2].data.percentageDifference.toFixed(0)),
      };

      return data;
    } catch (error) {
      console.log(
        "error getting Percent for totalValueOutstadndingVsTotalValueOfPurchasedTrainings =>",
        error
      );
    }
  };


export const getAllZones = async () => {
  try {

    const zones = await userApiClient.getAllZones();
    return zones.map(zone => ({
      ...zone,
      rowNumber: setRowNumber("zones"),
      rowType: "zone"
    }));
  } catch (error) {
    console.log("Error getting zones", error);
  }
}

export const updateZone = async(id, zone) => {
  try {
    const updatedZone = await userApiClient.updateZone(id, zone);
    return {
      ...updatedZone,
      rowNumber: setRowNumber("zones"),
      rowType: "zone"
    }
  } catch (error) {
    console.log("Error updating zone", error);
  }
}

export const updateZoneImage = async(id, formData) => {
  try {
    const updatedZone = await userApiClient.updateZoneImage(id, formData);
    return {
      ...updatedZone,
      rowNumber: setRowNumber("zones"),
      rowType: "zone"
    }
  } catch (error) {
    console.log("Error updating zone image", error);
  }
}

export const createZone = async(zone) => {
  try {
    const createdZone = await userApiClient.createZone(zone);

    return {
      ...createdZone,
      rowNumber: setRowNumber("zones"),
      rowType: "zone",
    };
  } catch (error) {
    console.log("Error creating zone", error);
  }
}

export const deleteZone = async(id) => {
  try {
    await userApiClient.deleteZone(id);
  } catch(error) {
    console.log("Error deleting zone", error);
  }
}


export const getAllGyms = async () => {
  try {

    const gyms = await userApiClient.getAllGyms();
    return gyms.map(gym => ({
      ...gym,
      rowNumber: setRowNumber("gyms"),
      rowType: "gym"
    }));
  } catch (error) {
    console.log("Error getting gyms", error);
  }
}

export const updateGymImage = async(id, formData) => {
  try {
    const updatedGym = await userApiClient.updateGymImage(id, formData);
    return {
      ...updatedGym,
      rowNumber: setRowNumber("gyms"),
      rowType: "gym"
    }
  } catch (error) {
    console.log("Error updating gym image", error);
  }
}

export const updateGym = async(id, gym) => {
  try {
    const updatedGym = await userApiClient.updateGym(id, gym);
    return {
      ...updatedGym,
      rowNumber: setRowNumber("gyms"),
      rowType: "gym"
    }
  } catch (error) {
    console.log("Error updating gym", error);
  }
}

export const createGym = async(gym) => {
  try {
    const createdGym = await userApiClient.createGym(gym);

    return {
      ...createdGym,
      rowNumber: setRowNumber("gyms"),
      rowType: "gym",
    };
  } catch (error) {
    console.log("Error creating gym", error);
  }
}

export const deleteGym = async(id) => {
  try {
    await userApiClient.deleteGym(id);
  } catch(error) {
    console.log("Error deleting gym", error);
  }
}

export const deleteTrainer = async(trainerUserId) => {
  try {
    await userApiClient.deleteTrainer(trainerUserId);
  } catch(error) {
    console.log("Error deleting trainer", error);
  }
}

export const deleteExerciser = async(exerciserUserId) => {
  try {
    await userApiClient.deleteExerciser(exerciserUserId);
  } catch(error) {
    console.log("Error deleting exerciser", error);
  }
}

export const getAllExercisers = async () => {
  try {
    let result = await userApiClient.getAllExercisers();

    const exercisers = result.map((exerciser) => ({
      rowNumber: setRowNumber("Exerciser Information"),
      name: exerciser?.userId?.firstName,
      lastName: exerciser?.userId?.lastName,
      username: exerciser?.userId?.username,
      userId: exerciser?._id,
      email: exerciser?.userId?.email,
      type: exerciser?.userId?.type == 1 ? "Trainer" : "Exerciser",
      createdAt: `${new Date(
        exerciser?.createdAt || exerciser?.CreatedAt
      ).toLocaleDateString()}, ${new Date(
        exerciser?.createdAt || exerciser?.CreatedAt
      ).toLocaleTimeString()}`,
      gender: exerciser?.userId?.gender == 0 ? "Male" : "Female",
      rowType: "Exerciser Information",
      userSecondId: exerciser?.userId?._id,
      credit: exerciser.credit,
    }));

    return exercisers;
  } catch (error) {
    console.log("error while getting All Exercisers Data =>", error);
  }
};

export const checkForTrainerSessions = async(trainerId, sucessCallback, errorCallback) => {
  try {
    await userApiClient.checkForTrainerSessions(trainerId)
    .catch(errorCallback);

    sucessCallback();
  } catch (error) {
    errorCallback(error?.response?.data);
  }
}

export const checkForExerciserSessions = async(trainerId, errorCallback) => {
  try {
    const response = await userApiClient.checkForExerciserSessions(trainerId);

    return response;
  } catch (error) {
    errorCallback(error?.response?.data);
  }
}