export default {
    'Customer Service Representative': 'getAllUsers',
    'Purchase History Exerciser' : 'getPurchasesExerciserFilter',
    'Current Purchases Exerciser' : 'getActiveBundlesByExerciser',
    'Booked Training Exerciser' : 'bookedTrainingExerciser',
    'Completed Training Sessions History Exerciser' : 'completedTrainingExerciser',
    'Cancelled Training Sessions History Exerciser' : 'cancelledSessionsExerciser',
    'Expired Training Package History Exerciser' : 'expiredTrainingSessions',
    'Purchase History Coach' : 'getPurchasesTrainerFilter',
    'Current Purchases Coach' : 'getActiveBundlesByTrainer',
    'Booked Training Coach' : 'bookedTrainingTrainer',
    'Completed Training Sessions History Coach' : 'completedTrainingTrainer',
    'Cancelled Training Sessions History Coach' : 'cancelledTrainingTrainer',
    'Expired Training Package History Coach' : 'expiredTrainingTrainer',
    'Number Of New Registered Exercisers' : 'newRegistratedExercisers',
    'Number Of Registered Exercisers' : 'getExercisersAndTrainers',
    'Number Of New Registered Coaches' : 'newRegistratedTrainers',
    'Number Of Registered Coaches' : 'getExercisersAndTrainers',
    'Number Of Inactive Exercisers' : 'inactiveExercisers',
    'Number Of Inactive Coaches' : 'inactiveTrainers',
    'Percentage Of Total Number Of Active Coaches vs Total Number Of Registered Coaches' : 'percentageOfActiveTrainers',
    'Percentage Of Total Number Of Active Exercisers vs Total Number Of Registered Exercisers' : 'percentageOfActiveExercisers',
    'Number Of Training Packages Purchased' : 'numberOfTrainingPackagesPurchased',
    'Number Of Training Sessions Purchased' : 'numberOfTrainingSessionsPurchased',
    'Number Of Outstanding Training' : 'numberOfOutstandingTraining',
    'Total Number Of Training Purchased In All The Packages Purchased' : 'totalNumberOfTrainingPrchased',
    'Number Of Training Completed' : 'numberOfCompletedTrainings',
    'Number Of Expired Training' : 'numberOfExpiredTrainings',
    'Number Of Booked Training' : 'numberOfBookedTrainings',
    'Number Of Cancelled Training' : 'numberOfCancelledTrainings',
    // 'Value Of Active Training Packages Purchased' : 'valueOfActivePurchasedTrainingPackages',
    'Value Of Outstanding Training Packages Purchased' : 'valueOfOutstandingPurchasedTrainingPackages',
    'Value Of Booked Training Packages Purchased' : 'valueOfBookedPurchasedTrainingPackages',
    'Value Of Completed Training Packages Purchased' : 'valueOfCompletedPurchasedTrainingPackages',
    'Value Of Expired Training Packages Purchased' : 'valueOfExpiredPurchasedTrainingPackages',
    'Value Of Cancelled Training' : 'valueOfCancelledPurchasedTrainingPackages',
    'Total Value Of Training Packages Purchased' : 'totalValueOfTrainingPurchased',
    'Average Value Of Training From All Training Packages Purchased' : 'AverageValueOfTrainingFromAllTrainingPackagesPurchased',
    'Average Value Of Booked Training' : 'averageValueOfBookedSessions',
    'Average Value Of Completed Training' : 'averageValueOfCompletedSessions',
    'Average Value Of Expired Training' : 'averageValueOfExpiredSessions',
    'Average Value Of Outstanding Training' : 'averageValueOfOutstandingTraining',
    'Average Value Of Cancelled Training' : 'averageValueOfCancelledTraining',
    'Percetange Of Total Number Completed Training Vs Total Number Of Purchased Training' : 'totalNumberCompletedVsTotalNumberOfPurchasedTrainings',
    // 'Percetange Of Total Number Of Expired Training Vs Total Number Of Purchased Training' : 'totalNumberExpiredVsTotalNumberOfPurchasedTrainings',
    'Percetange Of Total Number Of Outstanding Training Vs Total Number Of Purchased Training' : 'totalNumberOutstandingVsTotalNumberOfPurchasedTrainings',
    'Percetange Of Total Value Of Completed Training Vs Total Value Of Purchased Training' : 'totalValueCompletedVsTotalValueOfPurchasedTrainings',
    'Percetange Of Total Value Of Outstanding Training Vs Total Value Of Purchased Training' : 'totalValueOutstadndingVsTotalValueOfPurchasedTrainings',
    // 'Percetange Of Total Value Of Expired Training Vs Total Value Of Purchased Training' : 'totalValueExpiredVsTotalValueOfPurchasedTrainings',
    'Manage Admins' : 'allAdmins',
    'Manage Coaches' : 'allTrainers',
    'Profile' : 'getAdminProfile',
    'Manage Gyms': 'getAllGyms',
    'Manage Zones': 'getAllZones',
    'Manage Exercisers': 'getAllExercisers',
}
