import React from 'react';
import GridComponent from "../../gridComponent/GridComponent";

//style
import "./CoachInfo.scss";

export default function CoachPanel () {
    const subCategories = ['Purchase History Coach', 'Current Purchases Coach', "Booked Training Coach", 
    "Completed Training Sessions History Coach", 'Cancelled Training Sessions History Coach',
    'Expired Training Package History Coach'
    ];

    return (
      
        <>
            <GridComponent
            subCategories={subCategories}
            />
        </>

     
    )
}

