import React, { useContext } from "react";

import "./Loader.scss";

export default function loader () {

    return (
		<div className="loading-spinner">
			<div id='spinner-wrapper'></div>  
		</div>
		
    )   
}