import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Alert, Button, CardMedia, Dialog, Input } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { DataContext } from "../../../context/DataContext";
import { userService } from "../../../services";
import Loader from "../../loader/Loader";

export default function ZoneInformation({ zone }) {
    const {
        rowType,
        name,
        latitude,
        longitude,
        imageUrl,
        _id
    } = zone;

    const navigate = useNavigate();
    const { setData, setRowData, data } = useContext(DataContext);

    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [formData, setFormData] = useState({
        name,
        latitude,
        longitude
    });
    const [error, setError] = useState();

    const validateFormValue = (fieldName, value) => { 
        let isNumeric = true;
        let error;

        switch (fieldName) {
            case "latitude":
                isNumeric = /^-?\d+(\.\d+)?$/.test(value);
                if (!isNumeric)
                    error = "Latitude and Longitude can only contain numbers";
                break;
            case "longitude":
                isNumeric = /^-?\d+(\.\d+)?$/.test(value);
                if (!isNumeric)
                    error = "Latitude and Longitude can only contain numbers";
                break;
            case "name":
                if (value.length === 0)
                    error += "\nName field is required";
                break;
            default:
                break;
            
        }

        return error;
    }

    const handleFormValueChange = (fieldName, e) => {
        const newValue = e.nativeEvent.target.value;

        setFormData({
            ...formData,
            [fieldName]: newValue
        });
    };

    const handleUploadImage = async (file) => {
        let formData = new FormData();
        if (file) {
            setIsLoading(true);

            formData.append("imageUrl", file);
            const result = await userService.updateZoneImage(_id, formData);
            setRowData(result);
            
            const updatedZones = await userService.getAllZones();
            setData(updatedZones);

            setIsLoading(false);
        }
    };

    const handleChangeImage = () => (event) => {
        if (event.target.files) {
            const file = event.target.files[0];
            handleUploadImage(file);
        }
    };

    const handleOnSave = async () => {
        setError();

        let error;
        Object.entries(formData).forEach((entry) => {
            error = validateFormValue(entry[0], entry[1]);
        });

        if (error) {
            setError(error);
            return;
        }

        setIsLoading(true);

        await userService.updateZone(_id, formData);

        const updatedZones = await userService.getAllZones();
        setData(updatedZones);
        setRowData(updatedZones.find(zone => zone._id === _id));

        setIsLoading(false);
    };

    const handleOnClickDelete = () => {
        setShowDeleteConfirm(true);
    }

    const handleOnCloseDeleteConfirmDialog = () => {
        setShowDeleteConfirm(false);
    }

    const handleOnConfirmDelete = async () => {
        setIsLoading(true);

        await userService.deleteZone(_id);

        const updatedZones = await userService.getAllZones();
        setData(updatedZones);

        navigate('/dataGrid');

        setIsLoading(false);
    }

    const isSaveDisabled = useMemo(() => {
        return formData.latitude === latitude.toString()
            && formData.longitude === longitude.toString()
            && formData.name === name;
    }, [formData, latitude, longitude, name, data]);

    return (
        <div>
            <h3 className="details-heading">{rowType}</h3>
            <div className="details-container">
                <div className="details-box">
                    <div className="input-container">
                        <label>Name</label>
                        <input
                            type="text"
                            className="input-fields"
                            value={formData.name}
                            onChange={(e) => handleFormValueChange("name", e)}
                        />
                    </div>
                    <div className="input-container">
                        <label>Latitude</label>
                        <input
                            type="text"
                            className="input-fields"
                            value={formData.latitude}
                            onChange={(e) => handleFormValueChange("latitude", e)}
                        />
                    </div>
                    <div className="input-container">
                        <label>Longitude</label>
                        <input
                            type="text"
                            className="input-fields"
                            value={formData.longitude}
                            onChange={(e) => handleFormValueChange("longitude", e)}
                        />
                    </div>
                    <div className="input-container">
                        <label>Image</label>
                        {
                            imageUrl?.length ? (
                                <CardMedia
                                    component="img"
                                    src={imageUrl}
                                    alt="Location image"
                                />
                            ) : <p style={{ marginLeft: 15 }}>No image found</p>
                        }
                        <label htmlFor="zone-image-file">
                            <Input
                                style={{ display: "none" }}
                                accept=".png, .jpg, .jpeg"
                                id="zone-image-file"
                                type="file"
                                key="image"
                                onChange={handleChangeImage()}
                            />
                            <Button
                                variant="contained"
                                component="span"
                                startIcon={<CloudUploadIcon />}
                                sx={{ marginTop: 1.5 }}
                            >
                                Upload Image
                            </Button>
                        </label>
                    </div>
                    <div className="input-container">
                        <Button
                            variant="contained"
                            component="span"
                            disabled={isSaveDisabled}
                            onClick={handleOnSave}
                            sx={{ marginTop: 1.5 }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            component="span"
                            startIcon={<DeleteIcon />}
                            sx={{ marginTop: 1.5, marginBottom: 1.5 }}
                            onClick={handleOnClickDelete}
                        >
                            Delete zone
                        </Button>
                        {
                            error && (
                                <Alert variant="standard" severity="error">{error}</Alert>
                            )
                        }
                    </div>
                </div>
            </div>
            <Dialog open={showDeleteConfirm} onClose={handleOnCloseDeleteConfirmDialog}>
                <div className="delete-gym-confirm-dialog-container">
                    <p>Are you sure you want to delete this zone?</p>
                    <div className="delete-gym-confirmation-dialog-actions-container">
                        <Button
                            variant="contained"
                            onClick={handleOnConfirmDelete}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ marginLeft: 1 }}
                            onClick={handleOnCloseDeleteConfirmDialog}
                        >
                            No
                        </Button>
                    </div>
                </div>
            </Dialog>
            {
                isLoading && (
                    <div className="action-blocking-overlay">
                        <Loader />
                    </div>
                )
            }
        </div>
    );
}
