import React, { useContext, useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import * as  userApiClient from '../../../../apiClient/userApiClient';
import { CategoryContext } from '../../../../context/CategoriesContext';


//style
import "./AverageValueOfAllPackages.scss"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function AverageValueOfAllPackagesPurchased(prop) {
  const { all } = prop.data;
  const {loader, setLoader} = prop;
  const {subCategory} = useContext(CategoryContext);
  const [filterData, setFilterData] = useState();
  const [valueOfSessions, setValueOfSessions] = useState(all);

  const inputs = document.querySelectorAll('#fromDate, #toDate');

  const fetchFilterData = async () => {
    setLoader(true);
    let url = "?";

    Object.entries(filterData).map(([key, value]) => {
      url = url.concat(`${key}=${value}&`);
    });

    let response = await userApiClient.getAverageValueAllPackages(url);

    if(Number(response?.data?.averagePriceOfSessions).toFixed(0) == valueOfSessions) {
      setLoader(false);

      if(!response?.data.averagePriceOfSessions) setValueOfSessions(0);

      inputs.forEach(field => {
          field.disabled = false;
      })

      return
    }

    if(response?.data?.averagePriceOfSessions) {
      setValueOfSessions(Number(response.data.averagePriceOfSessions).toFixed(0));
  } else {
      setLoader(false);
      setValueOfSessions(0)
  };

  inputs.forEach(field => {
    field.disabled = false;
  })
    
  }

  const formHandler = (field_name, value) => {
    inputs.forEach(field => {
      if(field.id !== field_name)
        field.disabled = true;
    })

    if(!value) {
      delete filterData[field_name];

      if(Object.keys(filterData).length == 0) {
        setValueOfSessions(all);
      } else if (Object.keys(filterData).length > 0) {
        fetchFilterData();
      }

    } else {
      setFilterData({
        ...filterData,
        [field_name]: value
      });
    }  
  }

  const resetInputFields = () => {
    inputs.forEach(input => {
      if(input.value) 
        input.value = "";
    })

    setFilterData(null);  
    setValueOfSessions(all);

  }

  useEffect(() => {
    if(filterData)
      fetchFilterData();
  }, [filterData]);


  useEffect(() => {
   if(loader) 
    setLoader(false);
  }, [valueOfSessions])

  return (
    <div>
      <h3 className="reports-revenue-heading">{subCategory}</h3>
      <div className="reports-revenue-container">
        <div>
        <Box id="reports-revenue-box" sx={{ width: "100%" }}>
            <Grid
            id="reports-revenue-element"
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
            <label>All</label>
            <Item id="item-reports-revenue">{all}</Item>
            </Grid>
            <Grid
            id="reports-revenue-element"
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
            <Item id="item-reports-revenue" style={{background: "#1976d2", color: "white", marginTop: "20px", cursor: "pointer"}} onClick={resetInputFields}>Clear</Item>
            </Grid>
        </Box>
        </div>
        <div className="reports-revenue-input-container">
          <div className="reports-revenue-box-one">
            <div className="input-container">
              <label>From Date</label>
              <input
                type="date"
                className="input-fields"
                id="fromDate"
                // value={filterData?.fromDate}
                onChange={(event) =>
                  formHandler("fromDate", event.target.value)
                }
              />
            </div>
            <div className="input-container">
              <label>To Date</label>
              <input
                type="date"
                className="input-fields"
                id="toDate"
                // value={filterData?.toDate}
                onChange={(event) =>
                  formHandler("toDate", event.target.value)
                }
              />
            </div>
            <div id='result-box' className="input-container">
              <label>Filter Result</label>
              <p className="filter-result">{valueOfSessions}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}