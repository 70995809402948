import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import CommentIcon from "@mui/icons-material/Comment";
import imagePlaceholder from "../../../assets/images/location-placeholder.jpg";

export default function LocationCard({ location }) {
  const getImageUrl = () => {
    return !location.gym?.imageUrl ? imagePlaceholder : location.gym?.imageUrl;
  };

  const locationTypeToString = (locationType) => {
    switch (locationType) {
      case 2:
        return "Public Gym";
      case 3:
        return "Residential";
      case 4:
        return "Outdoors";
      case 5:
        return "Home";
      default:
        return "Other";
    }
  };

  return (
    <Card className="location-card" sx={{ width: 500 }}>
      <CardMedia
        component="img"
        height="200"
        src={getImageUrl()}
        alt="Location image"
      />
      <CardHeader title={location.name} />
      <CardContent sx={{ minHeight: 140 }}>
        <div className="secondary">
          {location.locationType && (
            <div className="line-wrapper">
              <FitnessCenterIcon
                sx={{
                  width: 30,
                  marginRight: 1,
                }}
              />
              <div className="line mt-0">
                {locationTypeToString(location.locationType)}
              </div>
            </div>
          )}

          {location.zone?.name && (
            <div className="line-wrapper">
              <BusinessOutlinedIcon
                className="mt-5"
                sx={{
                  width: 30,
                  marginRight: 1,
                }}
              />
              <div className="line">{location.zone?.name}</div>
            </div>
          )}

          {location.gym?.name && (
            <div className="line-wrapper">
              <BusinessOutlinedIcon
                className="mt-5"
                sx={{
                  width: 30,
                  marginRight: 1,
                }}
              />
              <div className="line">{location.gym?.name}</div>
            </div>
          )}

          {location?.name && (
            <div className="line-wrapper">
              <BusinessOutlinedIcon
                className="mt-5"
                sx={{
                  width: 30,
                  marginRight: 1,
                }}
              />
              <div className="line">{location.name}</div>
            </div>
          )}

          {location.address && (
            <div className="line-wrapper">
              <FmdGoodOutlinedIcon
                sx={{ width: 30, marginRight: 1, marginTop: 1 }}
              />
              <div className="line">{location.address}</div>
            </div>
          )}

          {location.description && (
            <div className="line-wrapper">
              <CommentIcon sx={{ width: 30, marginRight: 1, marginTop: 1 }} />
              <div className="line">{location.description}</div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
