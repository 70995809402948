import React from 'react';
import GridComponent from "../../gridComponent/GridComponent";

//style
import "./CustomerInfo.scss";

export default function CustomerPanel () {
    const subCategories = ['Purchase History Exerciser', 'Current Purchases Exerciser', "Booked Training Exerciser", 
        "Completed Training Sessions History Exerciser", 'Cancelled Training Sessions History Exerciser', 'Expired Training Package History Exerciser' ]

    return (
      
        <>
            <GridComponent
            subCategories={subCategories}
            />
        </>

     
    )
}
