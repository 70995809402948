import React, { useState, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import Avatar from "@mui/material/Avatar";
import { Alert, Button, Dialog, Input } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { DataContext } from "../../../context/DataContext";
import LocationCard from "../locations/LocationCard";
import Loader from "../../loader/Loader";

import * as userApiClient from "../../../apiClient/userApiClient";
import { allTrainers, toggleTrainerActivity } from "../../../services/userService";
import { getRowDataFromStorage, getRowDetailsFromStorage, setRowDataToStorage, setRowDetailsToStorage } from "../../../helper/storageFunctions";
import { userService } from "../../../services";

export default function TrainerInformation({ userData }) {
  const {
    name,
    lastName,
    username,
    userId,
    email,
    phone,
    timeOfPurchase,
    gender,
    rowType,
    userImage,
    userSecondId,
    isActive
  } = userData;
  const navigate = useNavigate();

  const { setData, data, setRowData } = useContext(DataContext);
  const [showSave, setShowSave] = useState(false);
  const [image, setImage] = useState();
  const [previewImage, setPreviewImage] = useState(userImage);
  const [locations, setLocations] = useState([]);
  const [isTrainerActive, setIsTrainerActive] = useState(isActive);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteError, setDeleteError] = useState();
  const [internalName, setInternalName] = useState(name);
  const [internalLastName, setInternalLastName] = useState(lastName);
  const [internalPhone, setInternalPhone] = useState(phone);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchTrainerLocations = async () => {
      const response = await userApiClient.getAllTrainerLocations(userSecondId);
      return response.data;
    };

    fetchTrainerLocations().then((data) => {
      setLocations(data);
    });
  }, []);

  const handleUploadImage = async () => {
    let formData = new FormData();
    if (image) {
      setIsLoading(true);

      formData.append("profilePhoto", image);
      await userApiClient.editTrainerImage(userSecondId, formData);
      setShowSave(false);

      const updatedCoaches = await allTrainers();
      setRowData(updatedCoaches.find(coach => coach.userId === userId));
      setData(updatedCoaches);

      setImage();
      setIsLoading(false);
    }
  };

  const handleChangeImage = () => (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setImage(file);
      fileToBase64(file);
      setShowSave(true);
    }
  };

  const fileToBase64 = async (file) => {
    if (file !== undefined) {
      const reader = new FileReader();

      reader.onloadend = () => {
        let base64data = reader.result;
        setPreviewImage(base64data);
      };

      reader.readAsDataURL(file);
      return;
    }
    setImage(null);
  };

  const updateTrainerActivityInStorage = (isActive) => {
    updateTrainerDetails(isActive);
    updateTrainerInRows(isActive);
  }

  const updateTrainerDetails = (isActive) => {
    const storageTrainer = getRowDetailsFromStorage();
    
    storageTrainer.isActive = isActive;

    setRowDetailsToStorage(storageTrainer);
  }

  const updateTrainerInRows = (isActive) => {
    const trainerRows = getRowDataFromStorage();
    const editingTrainerIndex = trainerRows.findIndex(trainer => trainer.userId === userId);

    if(editingTrainerIndex > -1)
      trainerRows[editingTrainerIndex].isActive = isActive;

    setData(trainerRows);
    setRowDataToStorage(trainerRows);
  }

  const handleToggleStatus = async() => {
    const trainer = await toggleTrainerActivity(userData.userId);
    
    updateTrainerActivityInStorage(trainer.isActive);

    return setIsTrainerActive(trainer.isActive);
  }

  const handleOnClickDelete = () => {
    setShowDeleteConfirm(true);
  }

  const handleOnCloseDeleteConfirmDialog = async () => {
    setShowDeleteConfirm(false);
  }

  const handleOnConfirmDelete = async () => {
    setIsLoading(true);
  
    userService.checkForTrainerSessions(
      userId,
      async () => {
        setShowDeleteConfirm(false);

        try {
          await userApiClient.deleteTrainer(userSecondId);

          const updatedCoaches = await allTrainers();
          setData(updatedCoaches);

          setIsLoading(false);

          navigate('/dataGrid');
        } catch (error) {
          setDeleteError(error?.response?.data?.message);
          setShowDeleteConfirm(false);
          setIsLoading(false);
        }
      },
      (error) => {
        setDeleteError(error.message);
        setShowDeleteConfirm(false);
        setIsLoading(false);
      }
    );
  }

  const handleOnSaveInfo = async () => {
    setIsLoading(true);

    await userApiClient.editUser(userSecondId, { 
      firstName: internalName,
      lastName: internalLastName,
      phoneNumber: internalPhone,
    });

    const updatedCoaches = await allTrainers();
    setRowData(updatedCoaches.find(coach => coach.userId === userId));

    setIsLoading(false);
  }

  const handleOnNameChange = (event) => {
    const newName = event.target.value;
    setInternalName(newName);
  }

  const handleOnLastNameChange = (event) => {
      const newLastName = event.target.value;
      setInternalLastName(newLastName);
  }

  const handleOnPhoneChange = (event) => {
    const newPhone = event.target.value;
    setInternalPhone(newPhone);
}

  const isSaveDisabled = useMemo(() => {
      return internalName === name 
          && internalLastName === lastName
          && internalPhone === phone
  }, [internalName, internalLastName, lastName, name, internalPhone, phone, data, userData])
  
  const trainerActivityText = isTrainerActive ? "Deactivate account" : "Activate account";

  return (
    <div>
      <h3 className="details-heading">{rowType}</h3>
      <div className="centered-content-container">
        <Avatar
          alt="Remy Sharp"
          src={previewImage}
          sx={{ width: 100, height: 100 }}
        />
        <label htmlFor="contained-button-file">
          <Input
            style={{ display: "none" }}
            accept=".png, .jpg, .jpeg"
            id="contained-button-file"
            multiple
            type="file"
            key="image"
            onChange={handleChangeImage()}
          />

          <Button
            variant="contained"
            component="span"
            sx={{ m: 1.5, width: "50ch" }}
          >
            Upload Image
          </Button>
        </label>
        <Button
          onClick={handleToggleStatus}
          variant="contained"
          component="span"
          sx={{ m: 1.5, width: "50ch" }}
        >
          {trainerActivityText}
        </Button>
        <Button
            variant="contained"
            color="error"
            component="span"
            startIcon={<DeleteIcon />}
            sx={{ marginTop: 1.5, width: "50ch" }}
            onClick={handleOnClickDelete}
        >
            Delete coach
        </Button>
        {
          deleteError && (
            <Alert sx={{ marginTop: 1.5, width: "50ch" }} severity="error">Failed to delete <br/>{deleteError}</Alert>
          )
        }
        <Button
          variant="contained"
          component="span"
          sx={{ marginTop: 3, width: "50ch" }}
          onClick={handleUploadImage}
          disabled={!showSave && !image}
        >
          Save profile photo
        </Button>
        <Button
            variant="contained"
            component="span"
            disabled={isSaveDisabled}
            onClick={handleOnSaveInfo}
            sx={{ marginTop: 3, width: "50ch" }}
        >
            Save profile information
        </Button>
      </div>

      <div className="details-container admin-coach-container">
        <div className="details-box">
          {name !== undefined && (
            <div id="grid-container">
              <label className="label-input-field">Name <EditIcon sx={{ fontSize: 18, marginLeft: 0.5 }} /></label>
              <input
                  type="text"
                  className="box-item input-fields--edit"
                  id="name"
                  value={internalName}
                  onChange={handleOnNameChange}
              />
            </div>
          )}
          {lastName !== undefined && (
            <div id="grid-container">
              <label className="label-input-field">Last Name <EditIcon sx={{ fontSize: 18, marginLeft: 0.5 }} /></label>
              <input
                  type="text"
                  className="input-fields--edit box-item"
                  id="lastName"
                  value={internalLastName}
                  onChange={handleOnLastNameChange}
              />
            </div>
          )}
          {username !== undefined && (
            <div id="grid-container">
              <label>Username</label>
              <div className="box-item input-fields--edit">{username}</div>
            </div>
          )}
          {phone !== undefined && (
            <div id="grid-container">
              <label className="label-input-field">Phone Number <EditIcon sx={{ fontSize: 18, marginLeft: 0.5 }} /></label>
              <input
                  type="text"
                  className="input-fields--edit box-item"
                  id="name"
                  value={internalPhone}
                  onChange={handleOnPhoneChange}
              />
            </div>
          )}
        </div>
        <div className="details-box">
          {userId !== undefined && (
            <div id="grid-container">
              <label>Id</label>
              <div className="box-item input-fields--edit">{userId}</div>
            </div>
          )}
          {email !== undefined && (
            <div id="grid-container">
              <label>Email</label>
              <div className="box-item input-fields--edit">{email}</div>
            </div>
          )}
          {timeOfPurchase !== undefined && (
            <div id="grid-container">
              <label>Time And Date Of Purchase</label>
              <div className="box-item input-fields--edit">{timeOfPurchase}</div>
            </div>
          )}
          {gender !== undefined && (
            <div id="grid-container">
              <label>Gender</label>
              <div className="box-item input-fields--edit">{gender}</div>
            </div>
          )}
        </div>
      </div>
      <div className="locations-container">
        <h3 className="locations-heading">Available Locations</h3>
        <div className="locations-wrapper">
          {locations.map((location) => {
            return (
              <LocationCard
                key={location._id}
                location={location}
                userId={userSecondId}
              />
            );
          })}
        </div>
      </div>
      <Dialog open={showDeleteConfirm} onClose={handleOnCloseDeleteConfirmDialog}>
          <div className="delete-dialog-container">
              <p>Are you sure you want to delete this coach?</p>
              <div className="delete-dialog-actions-container">
                  <Button
                      variant="contained"
                      onClick={handleOnConfirmDelete}
                  >
                      Yes
                  </Button>
                  <Button
                      variant="outlined"
                      sx={{ marginLeft: 1 }}
                      onClick={handleOnCloseDeleteConfirmDialog}
                  >
                      No
                  </Button>
              </div>
          </div>
      </Dialog>
      {
          isLoading && (
              <div className="action-blocking-overlay">
                  <Loader />
              </div>
          )
      }
    </div>
  );
}
