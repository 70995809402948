import React, { createContext, useState } from 'react';


const CategoryContext = createContext();

const CategoryContextProvider = props => {

    const [showCustomerService, setShowCustomerService] = useState(true);
    const [showCustomerPanel, setShowCustomerPanel] = useState(false);
    const [showCoachPanel, setshowCoachPanel] = useState(false);
    const [showUserReports, setshowUserReports] = useState(false);
    const [showTrainingRevenue, setshowTrainingRevenue] = useState(false);
    const [showAdminPanel, setShowAdminPanel] = useState(false);
    const [activePanel, setActivePanel] = useState(null)

    const [subCategory, setSubCategory] = useState(null);


    function menageCategoriesState (category, navigate) {
        setActivePanel("/" + " " + category);

        

        switch(category) {
            case "Customer-Service":
                setShowCustomerService(true);
                setShowCustomerPanel(false);
                setshowCoachPanel(false);
                setshowUserReports(false);
                setshowTrainingRevenue(false);
                setShowAdminPanel(false);
                break;
            case "Customer-Panel":
                setShowCustomerService(false);
                setShowCustomerPanel(false);
                setshowCoachPanel(true);
                setshowUserReports(false);
                setshowTrainingRevenue(false);
                setShowAdminPanel(false);
                break;
            case "Coach-Panel":
                setShowCustomerService(false);
                setShowCustomerPanel(true);
                setshowCoachPanel(false);
                setshowUserReports(false);
                setshowTrainingRevenue(false);
                setShowAdminPanel(false);
                break;
            case "User-Reports":
                setShowCustomerService(false);
                setShowCustomerPanel(false);
                setshowCoachPanel(false);
                setshowUserReports(true);
                setshowTrainingRevenue(false);
                setShowAdminPanel(false);
                break;
            case "Training-Revenue":
                setShowCustomerService(false);
                setShowCustomerPanel(false);
                setshowCoachPanel(false);
                setshowUserReports(false);
                setshowTrainingRevenue(true);
                setShowAdminPanel(false);
                break;
            case "Admin-Panel":
                setShowCustomerService(false);
                setShowCustomerPanel(false);
                setshowCoachPanel(false);
                setshowUserReports(false);
                setshowTrainingRevenue(false);
                setShowAdminPanel(true);
                break;

        }

        if(window.location.href.includes('dataGrid') || window.location.href.includes('editAdminPassword') || window.location.href.includes('editAdmin') || window.location.href.includes('addAdminPage')) {
              navigate()
        }

    

    }
  
    const providerValue = {
        showCustomerService, showCustomerPanel, showCoachPanel,
        showUserReports, showTrainingRevenue, subCategory,activePanel, showAdminPanel,
        setShowCustomerService, setShowCustomerPanel,setshowCoachPanel,
        setSubCategory, setshowUserReports, setshowTrainingRevenue, setActivePanel, setShowAdminPanel,
        
        menageCategoriesState
    };
  
    return (
      <CategoryContext.Provider value={providerValue}>
        {props.children}
      </CategoryContext.Provider>
    );
};

const CategoryConsumer = CategoryContext.Consumer;

export { CategoryContext, CategoryContextProvider, CategoryConsumer };