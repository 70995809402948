import React, { useContext, useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import * as  userApiClient from '../../../../apiClient/userApiClient';
import { CategoryContext } from '../../../../context/CategoriesContext';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function TotalValueCompletedVsTotalValueOfPurchasedTrainings(prop) {
  const {all, male, female} = prop.data;
  const {loader, setLoader} = prop;
  const {subCategory} = useContext(CategoryContext);

  const [filterData, setFilterData] = useState();
  const [valueOfSessions, setValueOfSessions] = useState(all);
  const [countries, setCountries] = useState([]);
  const [sessionTypes, setSessionTypes] = useState([]);

  const inputs = document.querySelectorAll('#fromDate, #toDate, #minAge, #maxAge, #minPrice, #maxPrice, #exerciserId, #nationality, #sessionPackageId');

  let typingTimer;
  let doneTypingInterval = 1000;

  const fetchFilterData = async () => {
    setLoader(true);
    let url = "?";

    Object.entries(filterData).map(([key, value]) => {
      url = url.concat(`${key}=${value}&`);
    });
  
    let response = await userApiClient.getValueOfCompletedVsPurchased(url);

    if(Number(response?.data?.percentageDifference).toFixed(0) == valueOfSessions) {
      setLoader(false);
      
      if(!response?.data?.percentageDifference) setValueOfSessions(0);

      inputs.forEach(field => {
          field.disabled = false;
      })

      return;
    }

    if(response?.data?.percentageDifference) {
      setValueOfSessions(Number(response.data.percentageDifference).toFixed(0));
    } else {
        setLoader(false);
        setValueOfSessions(0)
    };

    inputs.forEach(field => {
      field.disabled = false;
    })
    
  }

  const getLabelFromSelectValue = (value, field_name) => {
    inputs.forEach(field => {
      if(field.id !== field_name) {
        field.disabled = true;
      }
    })

    if(value === "All") {
        delete filterData[field_name];
        fetchFilterData();
        return
    }

    if(value)
      formHandler(field_name, value);
  }

  const getLabelFromSelectPackageType = (value, field_name) => {
    inputs.forEach(field => {
      if(field.id !== field_name) {
        field.disabled = true;
      }
    })

    if(value === "All") {
        delete filterData[field_name];
        fetchFilterData();
        return
    }
    
    if(value);
      formHandler(field_name, value);
  }

  const formHandler = (field_name, value) => {
    inputs.forEach(field => {
      if(field.id !== field_name)
        field.disabled = true;
    })

    if(!value) {
        delete filterData[field_name];
  
        if(Object.keys(filterData).length == 0) {
          setValueOfSessions(all)
        } else if (Object.keys(filterData).length > 0) {
          fetchFilterData();
        }
  
      } else {
        setFilterData({
          ...filterData,
          [field_name]: value
        });
      } 
  }

  const resetInputFields = () => {
    inputs.forEach(input => {

      if(input.value && input.id !== "nationality" && input.id !== "sessionPackageId") input.value = "";

      if(input.id === "nationality" || input.id === "sessionPackageId") input.value = "All";

      setFilterData(null);  
      setValueOfSessions(all);
    })

  }

  const keyDown = (field_name) => {
    inputs.forEach(field => {
      if(field.id !== field_name)
        field.disabled = true;
    })

    clearTimeout(typingTimer);
  }

  const keyUp = (field_name, value) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => formHandler(field_name, value), doneTypingInterval);
  }

  useEffect(() => {
    if(filterData)
      fetchFilterData();
  }, [filterData]);

  useEffect(async () => {
    const res = await userApiClient.getCountries();
    setCountries(res.data);
  }, []);

  useEffect(async () => {
    const res = await userApiClient.getPackageTypes();
    setSessionTypes(res.data);
  }, []);

  useEffect(() => {
    if(loader) 
     setLoader(false);
   }, [valueOfSessions])

  return (
    <div>
        <h3 className="reports-revenue-heading">{subCategory}</h3>
        <div className="reports-revenue-container">
            <div>
                <Box id="revenue-box" sx={{ width: "100%" }}>
                    <Grid
                        id="reports-revenue-element"
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <label>All</label>
                        <Item id="item-reports-revenue">{all}</Item>
                    </Grid>
                    <Grid
                        id="reports-revenue-element"
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <label>Male</label>
                        <Item id="item-reports-revenue">{male}</Item>
                    </Grid>
                    <Grid
                        id="reports-revenue-element"
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <label>Female</label>
                        <Item id="item-reports-revenue">{female}</Item>
                    </Grid>
                    <Grid
                      id="reports-revenue-element"
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                      <Item id="item-reports-revenue" style={{background: "#1976d2", color: "white", marginTop: "20px", cursor: "pointer"}} onClick={resetInputFields}>Clear</Item>
                    </Grid>
                </Box>
            </div>
            <div className={`${subCategory == 'Percetange Of Total Value Of Completed Training Vs Total Value Of Purchased Training' ? "revenue-container" : "reports-revenue-container"}`}>
                <div className="reports-revenue-box-one">
                    <div className="input-container">
                        <label>From Date</label>
                        <input
                            type="date"
                            className="input-fields"
                            id="fromDate"
                          //   value={filterData?.fromDate}
                            onChange={(event) =>
                                formHandler("fromDate", event.target.value)
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>To Date</label>
                        <input
                            type="date"
                            className="input-fields"
                            id="toDate"
                          //   value={filterData?.toDate}
                            onChange={(event) => formHandler("toDate", event.target.value)}
                        />
                    </div>
                    <div className="input-container">
                        <label>Min Age</label>
                        <input
                            type="text"
                            className="input-fields"
                            id="minAge"
                          //   value={filterData?.minAge}
                          onKeyDown={() => keyDown("minAge")}
                          onKeyUp={(event) => keyUp("minAge" ,event.target.value)} 
                        />
                    </div>
                    <div className="input-container">
                        <label>Max Age</label>
                        <input
                            type="text"
                            className="input-fields"
                            id="maxAge"
                          //   value={filterData?.maxAge}
                          onKeyDown={() => keyDown("maxAge")}
                          onKeyUp={(event) => keyUp("maxAge", event.target.value)} 
                        />
                    </div>
                </div>
                <div className="reports-revenue-box-one">
                    <div className="input-container">
                        <label>Min Price</label>
                        <input
                            type="text"
                            className="input-fields"
                            id="minPrice"
                          //   value={filterData?.minPrice}
                          onKeyDown={() => keyDown("minPrice")}
                          onKeyUp={(event) => keyUp("minPrice", event.target.value)}
                        />
                    </div>
                    <div className="input-container">
                        <label>Max Price</label>
                        <input
                            type="text"
                            className="input-fields"
                            id="maxPrice"
                          //   value={filterData?.maxPrice}
                          onKeyDown={() => keyDown("maxPrice")}
                          onKeyUp={(event) => keyUp("maxPrice", event.target.value)} 
                        />
                    </div>
                    <div className="input-container">
                        <label>Exercisers Id</label>
                        <input
                            type="text"
                            className="input-fields"
                            id="exerciserId"
                          //   value={filterData?.exerciserId}
                          onKeyDown={() => keyDown("exerciserId")}
                          onKeyUp={(event) => keyUp("exerciserId", event.target.value)}
                        />
                    </div>
                    <div className="input-container">
                        <label>Package Type</label>
                        <select
                            className="input-fields"
                            name="sessionType"
                            id="sessionPackageId"
                            onChange={(event) =>
                              getLabelFromSelectPackageType(event.target.value, "sessionPackageId") 
                            }
                        >
                            <option>All</option>
                            {sessionTypes.map((type) => (
                                <option value={type._id} key={type.name}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="reports-revenue-box-two">     
                    <div className="input-container" id="specialty-nationality-container">
                        <label>Nationality</label>
                        <select
                            className="input-fields"
                            name="countries"
                            id="nationality"
                            onChange={(event) =>
                                getLabelFromSelectValue(event.target.value, "nationality")
                            }
                        >
                            <option>All</option>
                            {countries.map((country) => (
                                <option value={country} key={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div id='result-container' className="input-container">
                        <label>Filter Result</label>
                        <p className="filter-result">{valueOfSessions}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}