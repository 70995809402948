import React, {useContext, useEffect} from 'react';
import { CategoryContext } from '../../context/CategoriesContext';
import { DataContext } from '../../context/DataContext';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { useNavigate } from 'react-router-dom';
import { userService } from '../../services';
import MockData from '../../mock/MockData';

import { setRowDetailsToStorage } from '../../helper/storageFunctions';

import "./Header.scss"

export default function Header ({onSliderOpen, isOpen}) {
    const { activePanel, setActivePanel, setSubCategory, subCategory } = useContext(CategoryContext);
    const { setRowData } = useContext(DataContext);

    const Navigate = useNavigate();

    const handleClick = () => {
        localStorage.clear();
        Navigate('/'); 
    }

    const handleProfileClick = async () => {
        let endPointName = MockData['Profile'];
        let result = await userService[endPointName]();

        setActivePanel("/" + " " + "Admin-Panel");
        setSubCategory("Manage Admins");

        setRowData(result[0]);
        setRowDetailsToStorage(result[0]);
        Navigate('/dataGrid/rowDetails');
    }

    useEffect(() => {
        if(!activePanel) {
            setActivePanel("Customer-Service")
        }
    })

    return (
        <div className='header-container'>
            <Box id='header' sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={onSliderOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                            <Typography id='logo' variant="h6" component="div" sx={{ flexGrow: 1 }} className={isOpen && "move-logo"}>
                                Fit-Connector-Admin  {activePanel} {subCategory && ` - ${subCategory}`}
                            </Typography>
                            
                        <Button onClick={handleProfileClick} color="inherit">Profile</Button>
                        <Button onClick={handleClick} color="inherit">Logout</Button>
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    )
}

