import React, { useMemo, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { Alert, Button, CardMedia, Input } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { userService } from "../../../services";
import Loader from "../../loader/Loader";
import { DataContext } from "../../../context/DataContext";

export default function ZoneCreate() {
    const navigate = useNavigate();
    const { setRowData, setData } = useContext(DataContext);

    const [formData, setFormData] = useState({
        name: "",
        latitude: "",
        longitude: "",
        zoneImage: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [imagePreview, setImagePreview] = useState();
    const imageFile = useRef();

    const validateFormValue = (fieldName, value) => { 
        let isNumeric = true;
        let error;

        switch (fieldName) {
            case "latitude":
                isNumeric = /^-?\d+(\.\d+)?$/.test(value);
                if (!isNumeric)
                    error = "Latitude and Longitude can only contain numbers";
                break;
            case "longitude":
                isNumeric = /^-?\d+(\.\d+)?$/.test(value);
                if (!isNumeric)
                    error = "Latitude and Longitude can only contain numbers";
                break;
            case "name":
                if (value.length === 0)
                    error += "\nName field is required";
                break;
            default:
                break;
            
        }

        return error;
    }

    const handleFormValueChange = (fieldName, e) => {
        const newValue = e.nativeEvent.target.value;

        setFormData({
            ...formData,
            [fieldName]: newValue
        });
    };

    const fileToBase64 = async (file) => {
        if (file !== undefined) {
            const reader = new FileReader();

            reader.onloadend = () => {
                let base64data = reader.result;
                setImagePreview(base64data);
            };

            reader.readAsDataURL(file);
            return;
        }

        setImagePreview(null);
    };

    const handleChangeImage = () => (event) => {
        if (event.target.files) {
            const file = event.target.files[0];
            imageFile.current = file;
            fileToBase64(file);
        }
    };

    const handleCreate = async () => {
        setError();

        let error;
        Object.entries(formData).forEach((entry) => {
            error = validateFormValue(entry[0], entry[1]);
        });

        if (error) {
            setError(error);
            return;
        }

        setIsLoading(true);

        const _formData = new FormData();
        _formData.append("imageUrl", imageFile.current);
        _formData.append("name", formData.name);
        _formData.append("latitude", formData.latitude);
        _formData.append("longitude", formData.longitude);

        const result = await userService.createZone(_formData);
        setRowData(result);

        const refreshedZones = await userService.getAllZones();
        setData(refreshedZones);

        navigate('/dataGrid/rowDetails');

        setIsLoading(false);
    };

    const isCreateDisabled = useMemo(() => {
        return !formData.name || !formData.latitude || !formData.longitude;
    }, [formData]);

    return (
        <div>
            <h3 className="details-heading">Create new zone</h3>
            <div className="details-container">
                <div className="details-box">
                    <div className="input-container">
                        <label>Name</label>
                        <input
                            type="text"
                            className="input-fields"
                            value={formData.name}
                            onChange={(e) => handleFormValueChange("name", e)}
                        />
                    </div>
                    <div className="input-container">
                        <label>Latitude</label>
                        <input
                            type="text"
                            className="input-fields"
                            value={formData.latitude}
                            onChange={(e) => handleFormValueChange("latitude", e)}
                        />
                    </div>
                    <div className="input-container">
                        <label>Longitude</label>
                        <input
                            type="text"
                            className="input-fields"
                            value={formData.longitude}
                            onChange={(e) => handleFormValueChange("longitude", e)}
                        />
                    </div>
                    <div className="input-container">
                        <label>Image</label>
                        {
                            imagePreview ? (
                                <CardMedia
                                    component="img"
                                    src={imagePreview}
                                    alt="Location image"
                                />
                            ) : <p style={{ marginLeft: 15 }}>No image found</p>
                        }
                        <label htmlFor="zone-image-file">
                            <Input
                                style={{ display: "none" }}
                                accept=".png, .jpg, .jpeg"
                                id="zone-image-file"
                                type="file"
                                key="image"
                                onChange={handleChangeImage()}
                            />
                            <Button
                                variant="contained"
                                component="span"
                                startIcon={<CloudUploadIcon />}
                                sx={{ marginTop: 1.5 }}
                            >
                                Upload Image
                            </Button>
                        </label>
                    </div>
                    <div className="input-container">
                        <Button
                            variant="contained"
                            component="span"
                            sx={{ marginTop: 1.5 }}
                            disabled={isCreateDisabled}
                            onClick={handleCreate}
                        >
                            Create
                        </Button>
                    </div>
                    {
                        error && (
                            <Alert variant="standard" severity="error">{error}</Alert>
                        )
                    }
                </div>
            </div>
            {
                isLoading && (
                    <div className="action-blocking-overlay">
                        <Loader />
                    </div>
                )
            }
        </div>
    );
}
