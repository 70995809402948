import React, { useMemo, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router";
import { Button, CardMedia, Input } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { userService } from "../../../services";
import Loader from "../../loader/Loader";
import { DataContext } from "../../../context/DataContext";

import "./GymInformation.scss";

export default function GymCreate() {
    const navigate = useNavigate();
    const { setRowData, setData } = useContext(DataContext);

    const [name, setName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState();
    const imageFile = useRef();

    const fileToBase64 = async (file) => {
        if (file !== undefined) {
            const reader = new FileReader();

            reader.onloadend = () => {
                let base64data = reader.result;
                setImagePreview(base64data);
            };

            reader.readAsDataURL(file);
            return;
        }

        setImagePreview(null);
    };

    const handleChangeImage = () => (event) => {
        if (event.target.files) {
            const file = event.target.files[0];
            imageFile.current = file;
            fileToBase64(file);
        }
    };
    

    const handleOnNameChange = (e) => {
        const newName = e.nativeEvent.target.value;
        setName(newName);
    };

    const handleCreate = async () => {
        setIsLoading(true);

        const formData = new FormData();
        formData.append("imageUrl", imageFile.current);
        formData.append("name", name);

        const result = await userService.createGym(formData);
        setRowData(result);

        const refreshedGyms = await userService.getAllGyms();
        setData(refreshedGyms);

        navigate('/dataGrid/rowDetails');

        setIsLoading(false);
    };

    const isCreateDisabled = useMemo(() => {
        return !name.length;
    }, [name]);

    return (
        <div>
            <h3 className="details-heading">Create new gym</h3>
            <div className="details-container">
                <div className="details-box">
                    <div className="input-container">
                        <label>Name</label>
                        <input
                            type="text"
                            className="input-fields"
                            id="gymName"
                            value={name}
                            onChange={handleOnNameChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>Image</label>
                        {
                            imagePreview ? (
                                <CardMedia
                                    component="img"
                                    src={imagePreview}
                                    alt="Location image"
                                />
                            ) : <p style={{ marginLeft: 15 }}>No image found</p>
                        }
                        
                        <label htmlFor="gym-image-file">
                            <Input
                                style={{ display: "none" }}
                                accept=".png, .jpg, .jpeg"
                                id="gym-image-file"
                                type="file"
                                key="image"
                                onChange={handleChangeImage()}
                            />
                            <Button
                                variant="contained"
                                component="span"
                                startIcon={<CloudUploadIcon />}
                                sx={{ marginTop: 1.5 }}
                            >
                                Upload Image
                            </Button>
                        </label>
                        <Button
                            variant="contained"
                            component="span"
                            sx={{ marginTop: 1.5 }}
                            disabled={isCreateDisabled}
                            onClick={handleCreate}
                        >
                            Create
                        </Button>
                    </div>
                </div>
            </div>
            {
                isLoading && (
                    <div className="action-blocking-overlay">
                        <Loader />
                    </div>
                )
            }
        </div>
    );
}
