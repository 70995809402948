const ROW_DETAILS = 'rowDetails';
const ROWS_DATA = 'rows';

export const setRowDetailsToStorage = (rowDetail) => {
    localStorage.setItem(ROW_DETAILS, JSON.stringify(rowDetail));
}

export const getRowDetailsFromStorage = () => {
    return JSON.parse(localStorage.getItem(ROW_DETAILS));
}

export const setRowDataToStorage = (rowsData) => {
    localStorage.setItem(ROWS_DATA, JSON.stringify(rowsData));
}

export const getRowDataFromStorage = () => {
    return JSON.parse(localStorage.getItem(ROWS_DATA));
}
