import { axiosInstance } from "../axios/axios";

export const login = async (payload) => {
  const data = await axiosInstance.post("/admin/login", payload);
  return data;
};

export const signUp = async (payload) => {
  const data = await axiosInstance.post("/admin", payload);
  return data;
};

export const verifyEmail = async (payload) => {
  const data = await axiosInstance.post("/admin/verify", payload);
  return data;
};

export const resendVerification = async (payload) => {
  const data = await axiosInstance.patch("/admin/resend-verification", payload);
  return data;
};

export const getPurchasesTrainer = async () => {
  const data = await axiosInstance.get("/bundle/get-purchases-trainer");

  return data;
};

export const getAllUsers = async () => {
  const data = await axiosInstance.get("/user?userType=3");
  return data;
};

export const getActiveBundlesByTrainer = async () => {
  const data = await axiosInstance.get("/bundle/active-bundles-trainer");
  return data;
};

export const getSessionsByStatusTrainerBooked = async () => {
  const data = await axiosInstance.get(
    "/session/trainer/sessions-by-status?status=1"
  );
  return data;
};

export const getSessionsByStatusTrainerCompleted = async () => {
  const data = await axiosInstance.get(
    "/session/trainer/sessions-by-status?status=2"
  );

  return data;
};

export const getSessionsByStatusTrainerCancelled = async () => {
  const data = await axiosInstance.get("/canceled-sessions/trainer");

  return data;
};

export const getSessionsByStatusTrainerExpired = async () => {
  const data = await axiosInstance.get(
    "/session/trainer/sessions-by-status?status=3"
  );
  return data;
};

export const getPurchasesExerciserFilter = async () => {
  const data = await axiosInstance.get("/bundle/get-purchases-client?");
  return data;
};

export const getActiveBundlesByExerciser = async () => {
  const data = await axiosInstance.get("/bundle/active-bundles-exerciser?");
  return data;
};

export const getSessionsByStatusExerciserBooked = async () => {
  const data = await axiosInstance.get(
    "/session/exerciser/sessions-by-status?status=1"
  );
  return data;
};

export const getSessionsByStatusExerciserCompleted = async () => {
  const data = await axiosInstance.get(
    "/session/exerciser/sessions-by-status?status=2"
  );
  return data;
};

export const getSessionsByStatusExerciserCancelled = async () => {
  const data = await axiosInstance.get("/canceled-sessions/exerciser");
  return data;
};

export const getSessionsByStatusExerciserExpired = async () => {
  const data = await axiosInstance.get(
    "/session/exerciser/sessions-by-status?status=3"
  );
  return data;
};

export const getNumberNewRegistratedExercisers = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/exerciser/filter");
  const male = await axiosInstance.get("/exerciser/filter?gender=0");
  const female = await axiosInstance.get("/exerciser/filter?gender=1");

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getNumberNewRegistratedTrainers = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/trainer/filter");
  const male = await axiosInstance.get("/trainer/filter?gender=0");
  const female = await axiosInstance.get("/trainer/filter?gender=1");

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getNumberOfNewExercisers = async (url) => {
  try {
    const data = await axiosInstance.get(`/exerciser/filter${url}`);
    return data;
  } catch (error) {}
};

export const getNumberOfIncativeExercisers = async (url) => {
  try {
    const data = await axiosInstance.get(`/exerciser/inactive${url}`);
    return data;
  } catch (error) {}
};

export const getNumberOfIncativeTrainers = async (url) => {
  try {
    const data = await axiosInstance.get(`/trainer/inactive${url}`);
    return data;
  } catch (error) {}
};

export const getNumberOfNewTrainers = async (url) => {
  try {
    const data = await axiosInstance.get(`/trainer/filter${url}`);
    return data;
  } catch (error) {}
};

export const getNumberPackagesPurchased = async (url) => {
  try {
    const data = await axiosInstance.get(`/bundle/count-bundles${url}`);
    return data;
  } catch (error) {}
};

export const getCountOfCompletedVsPurchasedSessions = async (url) => {
  try {
    const data = await axiosInstance.get(`/session/purchased-completed${url}`);
    return data;
  } catch (error) {}
};

export const getValueOfCompletedVsPurchased = async (url) => {
  try {
    const data = await axiosInstance.get(
      `/bundle/completed-purchased-value${url}`
    );
    return data;
  } catch (error) {}
};

export const getNumberSessionsPurchased = async (url) => {
  try {
    const data = await axiosInstance.get(`/bundle/total-sessions${url}`);
    return data;
  } catch (error) {}
};

export const getNumberOfTotalTrainings = async (url) => {
  try {
    const data = await axiosInstance.get(`/bundle/paid-total-sessions/${url}`);
    return data;
  } catch (error) {}
};

export const getTotalNumberOfOutstandingVsPurchasedTrainings = async (url) => {
  try {
    const data = await axiosInstance.get(
      `/bundle/outstanding-purchase-difference${url}`
    );
    return data;
  } catch (error) {
    // alert("No result for provided data");
  }
};

export const getTotalValueOfOutstandingVsPurchasedTrainings = async (url) => {
  try {
    const data = await axiosInstance.get(
      `/bundle/outstanding-purchased-value${url}`
    );
    return data;
  } catch (error) {
    // alert("No result for provided data");
  }
};

export const getTotalValue = async (url) => {
  try {
    const data = await axiosInstance.get(`/bundle/total-value${url}`);
    return data;
  } catch (error) {}
};

export const getCountOfCancelledSessions = async (url) => {
  try {
    const data = await axiosInstance.get(`/canceled-sessions/count${url}`);
    return data;
  } catch (error) {}
};

export const getAllTrainers = async () => {
  const data = await axiosInstance.get("/trainer");
  return data;
};

export const getAllTrainerLocations = async (trainerUserId) => {
  const data = await axiosInstance.get(`/trainer/${trainerUserId}/locations`);
  return data;
};

export const getAllAdmins = async () => {
  const data = await axiosInstance.get("/admin");
  return data;
};

export const getPercentageActiveTrainers = async (url) => {
  try {
    const data = await axiosInstance.get(
      `/trainer/active-all-difference${url}`
    );
    return data;
  } catch (error) {}
};

export const getPercentageActiveExercisers = async (url) => {
  try {
    const data = await axiosInstance.get(
      `/exerciser/active-all-difference${url}`
    );
    return data;
  } catch (error) {}
};

export const getAverageValueForSessions = async (url, status) => {
  try {
    const data = await axiosInstance.get(
      `/session/average-price-status${url}sessionStatus=${status}`
    );
    return data;
  } catch (error) {}
};

export const getAverageValueAllPackages = async (url) => {
  try {
    const data = await axiosInstance.get(`/session/average-price${url}`);
    return data;
  } catch (error) {}
};

export const getCountries = async () => {
  const data = await axiosInstance.get("/user/countries");
  return data;
};

export const getTrainings = async (url, status) => {
  try {
    const data = await axiosInstance.get(
      `/session/filter-count${url}&sessionStatus=${status}`
    );
    return data;
  } catch (error) {}
};

export const getSessionValue = async (url, status) => {
  try {
    const data = await axiosInstance.get(
      `/session/total-price${url}sessionStatus=${status}`
    );
    return data;
  } catch (error) {}
};

export const getPackageTypes = async () => {
  const data = await axiosInstance.get("/session-package");
  return data;
};

export const getSpeciality = async () => {
  const data = await axiosInstance.get("/goal");
  return data;
};

export const getExercisersAndTrainersData = async () => {
  const data = await axiosInstance.get("/user?userType=3");
  return data;
};

export const getAverageValueOfOustandingTraining = async (url) => {
  try {
    const data = await axiosInstance.get(
      `/bundle/average-value-outstanding${url}`
    );
    return data;
  } catch (error) {}
};

export const getNumberOfOutstandingTrainingByFilter = async (url) => {
  try {
    const data = await axiosInstance.get(`/bundle/number-of-outstanding${url}`);
    return data;
  } catch (error) {}
};

export const getOutstandingValue = async (url) => {
  try {
    const data = await axiosInstance.get(
      `/bundle/total-value-outstanding${url}`
    );
    return data;
  } catch (error) {}
};

export const getAverageValueForCancelledSessions = async (url) => {
  try {
    const data = await axiosInstance.get(
      `/canceled-sessions/average-value${url}`
    );
    return data;
  } catch (error) {}
};

export const getQueryValueOfCancelledSessions = async (url) => {
  try {
    const data = await axiosInstance.get(`/canceled-sessions/value${url}`);
    return data;
  } catch (error) {}
};

export const getInactiveExerciser = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/exerciser/inactive");
  const male = await axiosInstance.get("/exerciser/inactive?gender=0");
  const female = await axiosInstance.get("/exerciser/inactive?gender=1");

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getInactiveTrainers = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/trainer/inactive");
  const male = await axiosInstance.get("/trainer/inactive?gender=0");
  const female = await axiosInstance.get("/trainer/inactive?gender=1");

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getPercentageofActiveTrainers = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/trainer/active-all-difference");
  const male = await axiosInstance.get(
    "/trainer/active-all-difference?gender=0"
  );
  const female = await axiosInstance.get(
    "/trainer/active-all-difference?gender=1"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getPercentageofActiveExercisers = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/exerciser/active-all-difference");
  const male = await axiosInstance.get(
    "/exerciser/active-all-difference?gender=0"
  );
  const female = await axiosInstance.get(
    "/exerciser/active-all-difference?gender=1"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getNumberOfTrainingPackagesPurchased = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/bundle/count-bundles");
  const male = await axiosInstance.get("/bundle/count-bundles?gender=0");
  const female = await axiosInstance.get("/bundle/count-bundles?gender=1");

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getNumberOfTrainingSessionsPurchased = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/bundle/total-sessions");
  const male = await axiosInstance.get("/bundle/total-sessions?gender=0");
  const female = await axiosInstance.get("/bundle/total-sessions?gender=1");

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getNumberOfOutstandingTraining = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/bundle/number-of-outstanding");
  const male = await axiosInstance.get(
    "/bundle/number-of-outstanding?gender=0"
  );
  const female = await axiosInstance.get(
    "/bundle/number-of-outstanding?gender=1"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getAverageValueOfOutstandingTraining = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/bundle/average-value-outstanding");
  const male = await axiosInstance.get(
    "/bundle/average-value-outstanding?gender=0"
  );
  const female = await axiosInstance.get(
    "/bundle/average-value-outstanding?gender=1"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getNumberOfTotalTrainingPurchased = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/bundle/paid-total-sessions/");
  const male = await axiosInstance.get("/bundle/paid-total-sessions/?gender=0");
  const female = await axiosInstance.get(
    "/bundle/paid-total-sessions/?gender=1"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getNumberOfTrainingsCompleted = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get(
    "/session/filter-count?sessionStatus=2"
  );
  const male = await axiosInstance.get(
    "/session/filter-count?gender=0&sessionStatus=2"
  );
  const female = await axiosInstance.get(
    "/session/filter-count?gender=1&sessionStatus=2"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getNumberOfTrainingsExpired = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get(
    "/session/filter-count?sessionStatus=3"
  );

  responses.push(allUsers);

  return responses;
};

export const getNumberOfTrainingsCancelled = async () => {
  let responses = [];

  const all = await axiosInstance.get("/canceled-sessions/count");
  const male = await axiosInstance.get("/canceled-sessions/count?gender=0");
  const female = await axiosInstance.get("/canceled-sessions/count?gender=1");

  responses.push(all);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getNumberOfTrainingsBooked = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get(
    "/session/filter-count?sessionStatus=1"
  );
  const male = await axiosInstance.get(
    "/session/filter-count?gender=0&sessionStatus=1"
  );
  const female = await axiosInstance.get(
    "/session/filter-count?gender=1&sessionStatus=1"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

// export const getActiveSessionValue = async () => {
//     let responses = [];

//     const allUsers = await axiosInstance.get('/session/total-price?&sessionStatus=0');
//     const male = await axiosInstance.get('/session/total-price?gender=0&sessionStatus=0');
//     const female = await axiosInstance.get('/session/total-price?gender=1&sessionStatus=0');

//     responses.push(allUsers);
//     responses.push(male);
//     responses.push(female);

//     return responses
// };

export const getBookedSessionValue = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get(
    "/session/total-price?&sessionStatus=1"
  );
  const male = await axiosInstance.get(
    "/session/total-price?gender=0&sessionStatus=1"
  );
  const female = await axiosInstance.get(
    "/session/total-price?gender=1&sessionStatus=1"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getOutstandingSessionValue = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/bundle/total-value-outstanding");
  const male = await axiosInstance.get(
    "/bundle/total-value-outstanding?gender=0"
  );
  const female = await axiosInstance.get(
    "/bundle/total-value-outstanding?gender=1"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getCancelledSessionValue = async () => {
  let responses = [];

  const all = await axiosInstance.get("/canceled-sessions/value");
  const male = await axiosInstance.get("/canceled-sessions/value?gender=0");
  const female = await axiosInstance.get("/canceled-sessions/value?gender=1");

  responses.push(all);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getCompletedSessionValue = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get(
    "/session/total-price?&sessionStatus=2"
  );
  const male = await axiosInstance.get(
    "/session/total-price?gender=0&sessionStatus=2"
  );
  const female = await axiosInstance.get(
    "/session/total-price?gender=1&sessionStatus=2"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getExpiredSessionValue = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get(
    "/session/total-price?&sessionStatus=3"
  );
  const male = await axiosInstance.get(
    "/session/total-price?gender=0&sessionStatus=3"
  );
  const female = await axiosInstance.get(
    "/session/total-price?gender=1&sessionStatus=3"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getTotalSessionValue = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get("/bundle/total-value");
  const male = await axiosInstance.get("/bundle/total-value?gender=0");
  const female = await axiosInstance.get("/bundle/total-value?gender=1");

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getAverageValueOfBookedSessions = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get(
    "/session/average-price-status?sessionStatus=1"
  );
  const male = await axiosInstance.get(
    "/session/average-price-status?gender=0&sessionStatus=1"
  );
  const female = await axiosInstance.get(
    "/session/average-price-status?gender=1&sessionStatus=1"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getAverageValueOfCompletedSessions = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get(
    "/session/average-price-status?sessionStatus=2"
  );
  const male = await axiosInstance.get(
    "/session/average-price-status?gender=0&sessionStatus=2"
  );
  const female = await axiosInstance.get(
    "/session/average-price-status?gender=1&sessionStatus=2"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getAverageValueOfExpiredSessions = async () => {
  let responses = [];

  const allUsers = await axiosInstance.get(
    "/session/average-price-status?sessionStatus=3"
  );
  const male = await axiosInstance.get(
    "/session/average-price-status?gender=0&sessionStatus=3"
  );
  const female = await axiosInstance.get(
    "/session/average-price-status?gender=1&sessionStatus=3"
  );

  responses.push(allUsers);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getAverageValueOfCancelledSessions = async () => {
  let responses = [];

  const all = await axiosInstance.get("/canceled-sessions/average-value");
  const male = await axiosInstance.get(
    "/canceled-sessions/average-value?gender=0"
  );
  const female = await axiosInstance.get(
    "/canceled-sessions/average-value?gender=1"
  );

  responses.push(all);
  responses.push(male);
  responses.push(female);

  return responses;
};

export const getAverageValueFromAllPackages = async () => {
  const averageValue = await axiosInstance.get("/session/average-price");

  return averageValue;
};

export const getTotalNumberCompletedVsTotalNumberOfPurchasedTrainings =
  async () => {
    let responses = [];

    const allUsers = await axiosInstance.get("/session/purchased-completed");
    const male = await axiosInstance.get(
      "/session/purchased-completed?gender=0"
    );
    const female = await axiosInstance.get(
      "/session/purchased-completed?gender=1"
    );

    responses.push(allUsers);
    responses.push(male);
    responses.push(female);

    return responses;
  };

export const getTotalNumberExpiredVsTotalNumberOfPurchasedTrainings =
  async () => {
    let responses = [];

    const allUsers = await axiosInstance.get("/session/purchased-expired");
    const male = await axiosInstance.get("/session/purchased-expired");
    const female = await axiosInstance.get("/session/purchased-expired");

    responses.push(allUsers);
    responses.push(male);
    responses.push(female);

    return responses;
  };

export const getTotalNumberOutstandingVsTotalNumberOfPurchasedTrainings =
  async () => {
    let responses = [];

    const all = await axiosInstance.get(
      "/bundle/outstanding-purchase-difference"
    );
    const male = await axiosInstance.get(
      "/bundle/outstanding-purchase-difference?gender=0"
    );
    const female = await axiosInstance.get(
      "/bundle/outstanding-purchase-difference?gender=1"
    );

    responses.push(all);
    responses.push(male);
    responses.push(female);

    return responses;
  };

export const getTotalValueCompletedVsTotalValueOfPurchasedTrainings =
  async () => {
    let responses = [];

    const all = await axiosInstance.get("/bundle/completed-purchased-value");
    const male = await axiosInstance.get(
      "/bundle/completed-purchased-value?gender=0"
    );
    const female = await axiosInstance.get(
      "/bundle/completed-purchased-value?gender=1"
    );

    responses.push(all);
    responses.push(male);
    responses.push(female);

    return responses;
  };

export const getTotalValueOutstadndingVsTotalValueOfPurchasedTrainings =
  async () => {
    let responses = [];

    const all = await axiosInstance.get("/bundle/outstanding-purchased-value");
    const male = await axiosInstance.get(
      "/bundle/outstanding-purchased-value?gender=0"
    );
    const female = await axiosInstance.get(
      "/bundle/outstanding-purchased-value?gender=1"
    );

    responses.push(all);
    responses.push(male);
    responses.push(female);

    return responses;
  };

export const getAdminProfileInformation = async () => {
  const adminInfo = await axiosInstance.get("/admin/get-data");

  return adminInfo;
};

export const deleteAdmin = async (id) => {
  await axiosInstance.delete(`/admin/${id}`);
};

export const editAdmin = async (data, id) => {
  await axiosInstance.patch(`/admin/${id}`, data);
};

export const editPassword = async (data) => {
  try {
    await axiosInstance.post("/admin/reset-password", data);
  } catch (err) {
    return err;
  }
};

export const editTrainerImage = async (id, profilePhoto) => {
  await axiosInstance.post(`user/profile-photo-admin/${id}`, profilePhoto);
};

export const editUser = async(id, data) => {
  await axiosInstance.patch(`user/admin/${id}`, data);
}

export const updateLocationImage = async (userId, data) => {
  await axiosInstance.post(`trainer/upload-location-image/${userId}`, data);
};

export const toggleTrainerActivity = async (trainerId) => {
  return await axiosInstance.patch(`trainer/toggle-activity/${trainerId}`)
};

export const deleteTrainer = async (trainerUserId) => {
  const response = await axiosInstance.delete(`trainer/${trainerUserId}`);

  return response.data;
}

export const getAllGyms = async () => {
  const response = await axiosInstance.get('gym');

  return response.data;
}

export const updateGymImage = async(id, file) => {
  const response = await axiosInstance.post(`gym/upload-image/${id}`, file);

  return response.data;
}

export const updateGym = async(id, gym) => {
  const response = await axiosInstance.patch(`gym/${id}`, gym);

  return response.data;
}

export const createGym = async(gym) => {
  const response = await axiosInstance.post('gym', gym);

  return response.data;
}

export const deleteGym = async(id) => {
  const response = await axiosInstance.delete(`gym/${id}`);

  return response.data;
}

export const getAllZones = async () => {
  const response = await axiosInstance.get('zone');

  return response.data
}

export const updateZone = async(id, zone) => {
  const response = await axiosInstance.patch(`zone/${id}`, zone);

  return response.data;
}

export const updateZoneImage = async(id, file) => {
  const response = await axiosInstance.post(`zone/upload-image/${id}`, file);

  return response.data;
}

export const createZone = async(zone) => {
  const response = await axiosInstance.post('zone', zone);

  return response.data;
}

export const deleteZone = async(id) => {
  const response = await axiosInstance.delete(`zone/${id}`);

  return response.data;
}

export const getAllExercisers = async() => {
  const response = await axiosInstance.get("exerciser");

  return response.data;
}

export const deleteExerciser = async(userId) => {
  const response = await axiosInstance.delete(`exerciser/${userId}`);

  return response.data;
}

export const checkForTrainerSessions = async(trainerId) => {
  const response = await axiosInstance.get(`/trainer/check-sessions/${trainerId}`);

  return response.data;
}

export const checkForExerciserSessions = async (exerciserId) => {
  const response = await axiosInstance.get(`/exerciser/check-sessions/${exerciserId}`);

  return response.data;
}