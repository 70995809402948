import React, { useContext, useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { CategoryContext } from '../../../../context/CategoriesContext';
import { CalculateDataResult } from "../../../../helper/CalculateDataResult";
import * as  userApiClient from '../../../../apiClient/userApiClient';
import { DataContext } from '../../../../context/DataContext';

//style
import "./Trainers.scss"


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Trainers() {
  const {subCategory} = useContext(CategoryContext);
  const {data} = useContext(DataContext);

  const [all, setAll] = useState();
  const [male, setMale] = useState();
  const [female, setFemale] = useState();
  const [filterData, setFilterData] = useState();
  const [numberOfTrainers, setNumberOfTrainers] = useState(data?.data?.trainers?.length);
  const [countries, setCountries] = useState([]);
  const [specialty, setSpecialty] = useState([]);


  useEffect(() => {
    setAll(data.data.trainers.length);
    const maleTrainer = data.data.trainers.filter((trainer) => {
        return trainer?.userId?.gender == 0;
    })

    const femaleTrainer = data.data.trainers.filter((trainer) => {
        return trainer?.userId?.gender == 1;
    })
  
    setFemale(femaleTrainer.length);
    setMale(maleTrainer.length);
  },[])

  const getLabelFromSelectValue = (value) => { 

    if(value === "All") {
        delete filterData['nationality'];
        CalculateDataResult(data, filterData, setNumberOfTrainers, 'trainers');
        return;
      }

    if(value)
      formHandler('nationality', value);
  }

  const getLabelFromSelectSpeciality = (value) => { 

    if(value === "All") {
        delete filterData['specialty'];
        CalculateDataResult(data, filterData, setNumberOfTrainers, 'trainers');
        return;
      }

    if(value)
      formHandler('specialty', value);
  }

  const formHandler = (filed_name, value) => {
    if(!value) {
        delete filterData[filed_name];
  
        if(Object.keys(filterData).length == 0) {
          setNumberOfTrainers(all)
        } else if (Object.keys(filterData).length > 0) {
          CalculateDataResult(data, filterData, setNumberOfTrainers, 'trainers');
        }
  
      } else {
        setFilterData({
          ...filterData,
          [filed_name]: value
        });
      } 
  }

  const resetInputFields = () => {
    const inputs = document.querySelectorAll('#from-date, #to-date, #min-age, #max-age, #nationality, #area-of-specialty');

    inputs.forEach(input => {
      if(input.value && input.id !== "nationality" && input.id !== "specialty") input.value = "";

      if(input.id === "nationality" || input.id === "area-of-specialty") input.value = "All";

      setFilterData(null);  
      setNumberOfTrainers(all);
    })

  }

  useEffect(() => {
    if(filterData)
    CalculateDataResult(data, filterData, setNumberOfTrainers, "trainers");
  }, [filterData]);

  useEffect(async () => {
    const res = await userApiClient.getCountries();
    setCountries(res.data);
  }, []);

  useEffect(async () => {
    const res = await userApiClient.getSpeciality();
    setSpecialty(res.data);
  }, []);

  return (
      <div>
          <h3 className="reports-revenue-heading">{subCategory}</h3>
          <div className="reports-revenue-container">
              <div>
                  <Box id="reports-revenue-box" sx={{ width: "100%" }}>
                      <Grid
                          id="reports-revenue-element"
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                          <label>All</label>
                          <Item id="item-reports-revenue">{all}</Item>
                      </Grid>
                      <Grid
                          id="reports-revenue-element"
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                          <label>Male</label>
                          <Item id="item-reports-revenue">{male}</Item>
                      </Grid>
                      <Grid
                          id="reports-revenue-element"
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                          <label>Female</label>
                          <Item id="item-reports-revenue">{female}</Item>
                      </Grid>
                      <Grid
                        id="reports-revenue-element"
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Item id="item-reports-revenue" style={{background: "#1976d2", color: "white", marginTop: "20px", cursor: "pointer"}} onClick={resetInputFields}>Clear</Item>
                      </Grid>
                  </Box>
              </div>
              <div className="reports-revenue-input-container">
                  <div className="reports-revenue-box-one">
                      <div className="input-container">
                          <label>From Date</label>
                          <input
                              type="date"
                              className="input-fields"
                              id="from-date"
                            //   value={filterData?.fromDate}
                              onChange={(event) =>
                                  formHandler("fromDate", event.target.value)
                              }
                          />
                      </div>
                      <div className="input-container">
                          <label>To Date</label>
                          <input
                              type="date"
                              className="input-fields"
                              id="to-date"
                            //   value={filterData?.toDate}
                              onChange={(event) => formHandler("toDate", event.target.value)}
                          />
                      </div>
                      <div className="input-container">
                          <label>Min Age</label>
                          <input
                              className="input-fields"
                              id="min-age"
                            //   value={filterData?.minAge}
                              onChange={(event) => formHandler("minAge", event.target.value)}
                          />
                      </div>
                      <div className="input-container">
                          <label>Max Age</label>
                          <input
                              className="input-fields"
                              id="max-age"
                            //   value={filterData?.maxAge}
                              onChange={(event) => formHandler("maxAge", event.target.value)}
                          />
                      </div>
                      <div className="input-container">
                          <label>Nationality</label>
                          <select
                              className="input-fields"
                              name="countries"
                              id="nationality"
                              onChange={(event) =>
                                  getLabelFromSelectValue(event.target.value)
                              }
                          >
                              <option>All</option>
                              {countries.map((country) => (
                                  <option value={country} key={country}>
                                      {country}
                                  </option>
                              ))}
                          </select>
                      </div>
                  </div>
                  <div className="reports-revenue-box-two">
                      <div className="input-container" id="specialty-container">
                          <label>Specialty</label>
                          <select
                              className="input-fields"
                              name="specialty"
                              id="area-of-specialty"
                              onChange={(event) =>
                                getLabelFromSelectSpeciality(event.target.value)
                              }
                          >
                              <option>All</option>
                              {specialty.map((special) => (
                                  <option value={special._id} key={special._id}>
                                      {special.name}
                                  </option>
                              ))}
                          </select>
                      </div>
                      <div id='result-wrapper' className="input-container">
                          <label>Filter Result</label>
                          <p className="filter-result">{numberOfTrainers}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}