import React, {useContext} from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CategoryContext } from '../../context/CategoriesContext';
import { useNavigate } from 'react-router-dom';

//style
import "./Slider.scss";


const Slider = ({isOpen, onSliderClose}) => {
  const navigate = useNavigate();
  const { menageCategoriesState } = useContext(CategoryContext);

  const navigateToHomePage = () => {
    navigate('/home');
  }

  const formatSliderClassName = () => {
    const sliderClassName = 'slider-container';

    if(isOpen) 
      return sliderClassName.concat(" open");

    return sliderClassName;
  }

  return (
    <div className={formatSliderClassName()} id='slider'>
        <div className='slider-closer'><ChevronLeftIcon onClick={onSliderClose}/></div>
        <div onClick={onSliderClose} className='category-container'>
          <div onClick={() => menageCategoriesState('Customer-Service', navigateToHomePage)} className='slider-category'><ChevronRightIcon/><span className='category-title'>CUSTOMER SERVICE</span></div>
          <div onClick={() => menageCategoriesState('Coach-Panel', navigateToHomePage)} className='slider-category'><ChevronRightIcon/><span className='category-title'>COACH PANEL</span></div>
          <div onClick={() => menageCategoriesState('Customer-Panel', navigateToHomePage)} className='slider-category'><ChevronRightIcon/><span className='category-title'>CUSTOMER PANEL</span></div>
          <div onClick={() => menageCategoriesState('User-Reports', navigateToHomePage)} className='slider-category'><ChevronRightIcon/><span className='category-title'>USER REPORTS</span></div>
          <div onClick={() => menageCategoriesState('Training-Revenue', navigateToHomePage)} className='slider-category'><ChevronRightIcon/><span className='category-title'>TRAINING AND REVENUE TOTALS</span></div>
          <div onClick={() => menageCategoriesState('Admin-Panel', navigateToHomePage)} className='slider-category'><ChevronRightIcon/><span className='category-title'>ADMIN PANEL</span></div>
        </div>
    </div>
  )
}

export default Slider