import React, {useEffect, useContext} from 'react';
import { DataContext } from '../../context/DataContext';
import { CategoryContext } from '../../context/CategoriesContext';
import AllUsersDetails from "../../components/details/allUsers/AllUsers";
import ExerciserDetails from "../../components/details/exerciserDetails/exerciserDetails";
import CoachDetails from "../../components/details/coachDetails/CoachDetails";
import TrainerInformation from "../../components/details/trainerInformation/TrainerInformation";
import AdminInformation from '../../components/details/adminInformation/AdminInformation';
import GymInformation from '../../components/details/gym/GymInformation';
import GymCreate from '../../components/details/gym/GymCreate';
import ZoneInformation from '../../components/details/zone/ZoneInformation';
import ZoneCreate from '../../components/details/zone/ZoneCreate';
import PageTemplate from '../../components/templates/PageTemplate/PageTemplate';

import { getRowDetailsFromStorage } from '../../helper/storageFunctions';

//style
import "./details.scss";

export default function RowDetails() {
  const {rowData, setRowData} = useContext(DataContext);

  useEffect(() => {
    if(!rowData) {
      const rowDataStorage = getRowDetailsFromStorage();
      setRowData(rowDataStorage);
    }
  }, [])

  useEffect(() => {
    if(localStorage.getItem('loginTime')) {
        const loginObject = JSON.parse(localStorage.getItem('loginTime'));
        const loginTime = loginObject.timestamp;
        const now = new Date();
        now.setHours(now.getHours());
        let nowJSON = JSON.stringify(now);
        let nowParse = JSON.parse(nowJSON);
        
        if(nowParse > loginTime) {
            localStorage.clear()
        }
    }
  }, [])

  const showRowDetails = () => {
    if(rowData) {
      switch(rowData.rowType) {
        case 'all users':
          return <AllUsersDetails userData={rowData}/>
  
        case 'Purchase History Exerciser':
          return <ExerciserDetails userData={rowData}/>
  
        case 'Active Packages exerciser':
          return <ExerciserDetails userData={rowData}/>
  
        case 'Booked Training Exerciser':
          return <ExerciserDetails userData={rowData}/>
  
        case 'Completed Training Sessions History Exerciser':
          return <ExerciserDetails userData={rowData}/>

        case 'Cancelled Training Sessions History Exerciser':
          return <ExerciserDetails userData={rowData}/>

        case 'Expired Training Packages History Exerciser':
            return <ExerciserDetails userData={rowData}/>
          
        case 'Purchase History Coach':
          return <CoachDetails userData={rowData}/>
  
        case 'Active Packages Coach':
          return <CoachDetails userData={rowData}/>
  
        case 'Booked Training Coach': 
        return <CoachDetails userData={rowData}/>
  
        case 'Completed Training Sessions History Coach':
          return <CoachDetails userData={rowData}/>

        case 'Cancelled Training Sessions History Coach': 
          return <CoachDetails userData={rowData}/>  

        case 'Expired Training Packages History Coach':
            return <CoachDetails userData={rowData}/>
        
        case 'Coach Information':
          return <TrainerInformation userData={rowData}/>

        case 'Exerciser Information':
          return <ExerciserDetails userData={rowData} />

        case 'Admin Information':
          return <AdminInformation userData={rowData}/>

        case 'gym':
          return <GymInformation gym={rowData} />

        case 'zone':
          return <ZoneInformation zone={rowData} />

        case "Gym Create":
          return <GymCreate />

        case "Zone Create":
          return <ZoneCreate />
      }
    }
  } 

  return (
    <PageTemplate>
      {showRowDetails()}
    </PageTemplate>
  );
}