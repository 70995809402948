import React, { createContext, useState, useEffect, useCallback } from 'react';

import { axiosInstance } from '../axios/axios';

import { TokenToLocalStorage }  from "../helper/Token";
import { GetTokenFromLocalStorage }  from "../helper/Token";
import { removeFromLocalStorage } from "../helper/Token";
import { authenticationService } from '../services';

const AuthenticationContext = createContext();

const AuthenticationContextProvider = props => {
  const [token, setToken] = useState('');

  const setUserToken = token => {
    setToken(token);
    axiosInstance.defaults.headers.authorization = `Bearer ${token}`;
  };

  const saveUserToken = token => {
    setUserToken(token);
    TokenToLocalStorage(token)
  };

  const deleteUserToken = () => {
    axiosInstance.defaults.headers.authorization = '';
    setToken('');
    removeFromLocalStorage();
  };

  const getUserToken = useCallback(() => {
    const token = GetTokenFromLocalStorage()
    
    if(token)
      return setUserToken(token);
    
    setUserToken(null)
  }, []);

  useEffect(() => {
    if (!token ) {
      getUserToken();
    }
  }, [token, getUserToken]);

  async function login(data, successCallback, errorCallback) {
    try {
      const response = await authenticationService.login(data);
      saveUserToken(response.data.token);

      successCallback();
    } catch (error) {
      errorCallback(error?.response?.data.message);
    }
  }
  async function signUp(data, successCallback, errorCallback) {
    try {
      
      await authenticationService.signUp(data);

      successCallback();
    } catch (error) {
      errorCallback(error?.response?.data.message);
    }
  }

  async function verifyEmail(data, successCallback, errorCallback) {
    try {
      const response = await authenticationService.verifyEmail(data);
      saveUserToken(response.data.token);
      successCallback();
    } catch (error) {
      errorCallback(error?.response?.data);
    }
  }

  async function resendVerification(data, successCallback, errorCallback) {
    try {
      await authenticationService.resendVerification(data);
     
      successCallback();
    } catch (error) {
      errorCallback(error?.response?.data);
    }
  }

  const providerValue = {
    token,
    deleteUserToken,
    login,
    signUp,
    verifyEmail,
    resendVerification,
  };

  return (
    <AuthenticationContext.Provider value={providerValue}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

const LoginConsumer = AuthenticationContext.Consumer;

export { AuthenticationContext, AuthenticationContextProvider, LoginConsumer };
