import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


// Components
import Login from "./pages/auth/LoginScreen/login";
import Register from "./pages/auth/RegisterScreen/register";
import VerifyEmail from "./pages/auth/VerifyEmail/verifyEmail";
import HomePage from "./pages/home/home";
import DataGrid from "./pages/dataGrid/DataGrid";
import RowDetails from "./pages/details/details";
import AddAdminPage from "./pages/admins/addAdminPage/AddAdminPage"
import EditAdmin from "./pages/admins/editAdminPage/EditAdmin";
import EditAdminPassword from "./pages/admins/editAdminPasswordPage/EditAdminPassword";

function App () {

  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/home" element={<HomePage />} />
          <Route exact path="/dataGrid" element={<DataGrid />} />
          <Route exact path="/dataGrid/rowDetails" element={<RowDetails />} />
          <Route exact path="/addAdminPage" element={<AddAdminPage />} />
          <Route exact path="/editAdmin" element={<EditAdmin />} />
          <Route exact path="/editAdminPassword" element={<EditAdminPassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
