import React, { useContext, useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import * as  userApiClient from '../../../../apiClient/userApiClient';
import { CategoryContext } from '../../../../context/CategoriesContext';


//style
import "./TotalNumberExpiredVsTotalNumberOfPurchasedTrainings.scss"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function TotalNumberExpiredVsTotalNumberOfPurchasedTrainings() {
  const {subCategory} = useContext(CategoryContext);

  const [filterData, setFilterData] = useState();
  const [numberOfSessions, setNumberOfSessions] = useState();
  const [countries, setCountries] = useState([]);
  const [sessionTypes, setSessionTypes] = useState([]);

  const fetchFilterData = async () => {
    let url = "?";

    Object.entries(filterData).map(([key, value]) => {
    
      url = url.concat(`${key}=${value}&`);
    });

    

    let response = await userApiClient.getNumberOfTotalTrainings(url);
    
    setNumberOfSessions(response.data.numberOfTrainingsPurchased);
  }

  const getLabelFromSelectValue = (value) => {

    if(value)
      formHandler('nationality', value);
  }

  const getLabelFromSelectPackageType = (value) => {
    
    if(value);
      formHandler('sessionPackageId', value);
  }

  const formHandler = (filed_name, value) => {
    setFilterData({
      ...filterData,
      [filed_name]: value
    });
  }

  useEffect(() => {
    if(filterData)
      fetchFilterData();
  }, [filterData]);

  useEffect(async () => {
    const res = await userApiClient.getCountries();
    setCountries(res.data);
  }, []);

  useEffect(async () => {
    const res = await userApiClient.getPackageTypes();
    setSessionTypes(res.data);
  }, []);

  return (
      <div>
          <h3 className="reports-revenue-heading">{subCategory}</h3>
          <div className="reports-revenue-container">
              <div className={`${subCategory == 'Percetange Of Total Number Of Expired Training Vs Total Number Of Purchased Training' ? "revenue-container" : "reports-revenue-container"}`}>
                  <div className="reports-revenue-box-one">
                      <div className="input-container">
                          <label>From Date</label>
                          <input
                              type="date"
                              className="input-fields"
                              id="from-date"
                              value={filterData?.fromDate}
                              onChange={(event) =>
                                  formHandler("fromDate", event.target.value)
                              }
                          />
                      </div>
                      <div className="input-container">
                          <label>To Date</label>
                          <input
                              type="date"
                              className="input-fields"
                              id="to-date"
                              value={filterData?.toDate}
                              onChange={(event) => formHandler("toDate", event.target.value)}
                          />
                      </div>
                      <div className="input-container">
                          <label>Min Age</label>
                          <input
                              className="input-fields"
                              id="min-age"
                              value={filterData?.minAge}
                              onChange={(event) => formHandler("minAge", event.target.value)}
                          />
                      </div>
                      <div className="input-container">
                          <label>Max Age</label>
                          <input
                              className="input-fields"
                              id="max-age"
                              value={filterData?.maxAge}
                              onChange={(event) => formHandler("maxAge", event.target.value)}
                          />
                      </div>
                  </div>
                  <div className="reports-revenue-box-one">
                      <div className="input-container">
                          <label>Min Price</label>
                          <input
                              className="input-fields"
                              id="min-price"
                              value={filterData?.minPrice}
                              onChange={(event) => formHandler("minPrice", event.target.value)}
                          />
                      </div>
                      <div className="input-container">
                          <label>Max Price</label>
                          <input
                              className="input-fields"
                              id="max-Price"
                              value={filterData?.maxPrice}
                              onChange={(event) => formHandler("maxPrice", event.target.value)}
                          />
                      </div>
                      <div className="input-container">
                          <label>Exercisers Id</label>
                          <input
                              className="input-fields"
                              id="exercisers-id"
                              value={filterData?.exerciserId}
                              onChange={(event) => formHandler("exerciserId", event.target.value)}
                          />
                      </div>
                      <div className="input-container">
                          <label>Session Type</label>
                          <select
                              className="input-fields"
                              name="sessionType"
                              id="session"
                              onChange={(event) =>
                                getLabelFromSelectPackageType(event.target.value)
                              }
                          >
                              {sessionTypes.map((type) => (
                                  <option value={type._id} key={type.name}>
                                      {type.name}
                                  </option>
                              ))}
                          </select>
                      </div>
                  </div>
                  <div className="reports-revenue-box-two">     
                      <div className="input-container" id="specialty-nationality-container">
                          <label>Nationality</label>
                          <select
                              className="input-fields"
                              name="countries"
                              id="nationality"
                              onChange={(event) =>
                                  getLabelFromSelectValue(event.target.value)
                              }
                          >
                              {countries.map((country) => (
                                  <option value={country} key={country}>
                                      {country}
                                  </option>
                              ))}
                          </select>
                      </div>
                      <div id='result-container' className="input-container">
                          <label>Filter Result</label>
                          <p className="filter-result">{numberOfSessions}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}