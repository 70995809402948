import * as React from 'react';

import "./AllUsers.scss"

export default function DataDetails({userData}) {
const {name, lastName, username, userId, email, phone, 
         type, rowType, gender, createdAt } = userData;

    const HeaderName = () => {
        if(rowType.includes('Trainer')) {
            return rowType.split('Trainer')
        } else if(rowType.includes('Exerciser')) {
            return rowType.split('Exerciser')
        }else return rowType;
    }

  return (
      <div id='details'>
          <h3 className='details-heading'>{HeaderName()}</h3>

          <div className='details-container all-users'>
                <div className='details-box'>
                    <div id="grid-container">
                        <label>Name</label>
                        <div className='box-item' >{name}</div>
                    </div>
                    <div id="grid-container">
                        <label>Last Name</label>
                        <div className='box-item' >{lastName}</div>
                    </div>
                    <div id="grid-container">
                        <label>Username</label>
                        <div className='box-item' >{username}</div>
                    </div>
                    <div id="grid-container">
                        <label>User Id</label>
                        <div className='box-item'>{userId}</div>
                    </div>
                    <div id="grid-container">
                        <label>Phone Number</label>
                        <div className='box-item'>{phone}</div>
                    </div>
                </div>
                <div className='details-box'>
                    <div id="grid-container">
                        <label>Email</label>
                        <div className='box-item'>{email}</div>
                    </div>
                    <div id="grid-container">
                        <label>type</label>
                        <div className='box-item'>{type}</div>
                    </div>
                    <div id="grid-container">
                        <label>Created At</label>
                        <div className='box-item'>{createdAt}</div>
                    </div>
                    <div id="grid-container">
                        <label>Gender</label>
                        <div className='box-item'>{gender}</div>
                    </div>
                </div>
          </div>
      </div>  
  );
}