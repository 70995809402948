import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { AuthenticationContext } from '../../../context/AuthenticationContext';

import PageTemplate from '../../../components/templates/PageTemplate/PageTemplate';

export default function AddAdminPage() {
  const navigate = useNavigate();
  const { signUp } = useContext(AuthenticationContext);

  const [signUpData, setSignUpData] = useState({
    email: '',
    superAdminPassword: '',
    firstName: '',
    lastName: '',
  });

  const isButtonDisabled =
    signUpData.firstName === '' ||
    signUpData.lastName === '' ||
    signUpData.email === '' ||
    signUpData.superAdminPassword === '';

  const successCallback = () => {
    alert("Admin Successfully Added!")
    navigate('/home');
   };

  const errorCallback = error => {
    alert(error)
  };

  const handleChange = (name, value) => {
    setSignUpData({ ...signUpData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    signUp(signUpData, successCallback, errorCallback);
  };

  return (
    <PageTemplate>
      <div>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h1" variant="h5">
                Add admin
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      onChange={(event) => handleChange('firstName', event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      onChange={(event) => handleChange('lastName', event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      onChange={(event) => handleChange('email', event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="superAdminPassword"
                      label="Enter your password to confirm"
                      type="password"
                      id="superAdminPassword"
                      autoComplete="new-password"
                      onChange={(event) => handleChange('superAdminPassword', event.target.value)}
                    />
                  </Grid>
                </Grid>
                <Button
                  disabled={isButtonDisabled}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Crate admin
                </Button>
              </Box>
            </Box>
          </Container>
      </div>
    </PageTemplate>
  );
}