import React, { useContext, useEffect } from 'react';
import { CategoryContext } from '../../../context/CategoriesContext';
import CustomerInfo from '../../filterCategories/customerInfo/CustomerInfo';
import CustomerService from "../../filterCategories/customerService/CustomerService";
import CoachInfo from "../../filterCategories/coachInfo/CoachInfo";
import TrainingRevenue from "../../filterCategories/trainingAndRevenue/TrainingAndRevenu";
import UsersReports from "../../filterCategories/usersReports/UsersPerorts";
import AdminInfo from '../../filterCategories/adminInfo/AdminInfo';

//style
import "./Categories.scss";

const Categories = () => {
    const {showCustomerService, showCustomerPanel, showCoachPanel, 
        showUserReports, showTrainingRevenue, showAdminPanel} = useContext(CategoryContext);
    
    const customerService = () => {
        return (
            <CustomerService/>
        )
    }
    
    const customerPanel = () => {
        return (
            <CustomerInfo/>
        )
    }
    
    const coachPanel = () => {
        return (
            <CoachInfo/>
        )
    } 

    const trainingRevenue = () => {
        return (
            <TrainingRevenue/>
        )
    }
    
    const usersReport = () => {
        return (
            <UsersReports/>
        )
    }

    const adminPanel = () => {
        return (
            <AdminInfo/>
        )
    }


    return (
      
        <div className='main-content-container'>
           {showCustomerService && customerService()}
           {showCustomerPanel && customerPanel()}
           {showCoachPanel && coachPanel()}
           {showUserReports && usersReport()}
           {showTrainingRevenue && trainingRevenue()}
           {showAdminPanel && adminPanel()}
        </div>

     
    )
}

export default Categories
