import React, { createContext, useState } from 'react';


const LoaderContext = createContext();

const LoaderContextProvider = props => {

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [dataGridLoader, setDataGridLoader] = useState(false);
  
    const providerValue = {
      loaderVisible,
      dataGridLoader,
      setLoaderVisible,
      setDataGridLoader,
    };
  
    return (
      <LoaderContext.Provider value={providerValue}>
        {props.children}
      </LoaderContext.Provider>
    );
};

export { 
  LoaderContext,
  LoaderContextProvider,
};