import React from 'react';

import { AuthenticationContextProvider } from './AuthenticationContext';
import { CategoryContextProvider } from './CategoriesContext';
import { DataContextProvider } from './DataContext';
import { LoaderContextProvider } from './LoaderContext';

const GlobalContextProvider = props => {
  return (
    <AuthenticationContextProvider>
        <CategoryContextProvider>
          <DataContextProvider>
            <LoaderContextProvider>
              {props.children}
            </LoaderContextProvider>
          </DataContextProvider>
        </CategoryContextProvider>
    </AuthenticationContextProvider>
  );
};

export default GlobalContextProvider;
