import React, { useContext, useMemo, useState } from "react";
import { Alert, Button, Dialog } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router";

import Loader from "../../loader/Loader";

import * as  userApiClient from '../../../apiClient/userApiClient';
import { DataContext } from "../../../context/DataContext";
import { userService } from "../../../services";
import { getAllExercisers } from "../../../services/userService";

export default function DataDetails({userData}) {
    const {
        name, lastName, username, userId, email, timeOfPurchase, price, startDateTime,
        endDateTime, rowType, package: bundlePackage,
        nationality, gender, coachName, coachLastName,
        coachPhone, coachEmail, coachGender, trainingLocation, oneSessionLocation,
        remainingSessions, expiryDate, numberOfCompletedSessions,
        bookedDateTime, sessionStart, sessionEnd, trainingSessionNumber,
        cancelationDateTime, userSecondId, credit,
    } = userData;

    const navigate = useNavigate();
    const { setData, setRowData } = useContext(DataContext);
    const adminInfo = JSON.parse(localStorage.getItem('loggedAdmin'));
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteError, setDeleteError] = useState();
    const [internalName, setInternalName] = useState(name);
    const [internalCredit, setInternalCredit] = useState(credit);
    const [internalLastName, setInternalLastName] = useState(lastName);
    const [isLoading, setIsLoading] = useState(false);

    const HeaderName = () => {
        if(rowType.includes('Trainer')) {
            return rowType.split('Trainer')
        } else if(rowType.includes('Exerciser')) {
            return rowType.split('Exerciser')
        }else return rowType;
    }

    const showEditActions = (adminInfo.type === "Super Admin" || adminInfo.type === "Regular Admin") && rowType === "Exerciser Information";

    const handleOnClickDelete = () => {
        setShowDeleteConfirm(true);
    }
    
    const handleOnCloseDeleteConfirmDialog = async () => {
        setShowDeleteConfirm(false);
    }
    
    const handleOnConfirmDelete = async () => {
        setShowDeleteConfirm(false);
        setIsLoading(true);

        await userService.checkForExerciserSessions(userId, (error) => {
            setDeleteError(error.message);
            setIsLoading(false);
        })

        try {
            await userApiClient.deleteExerciser(userSecondId);
    
            const updatedExercisers = await userService.getAllExercisers();
            setData(updatedExercisers);

            setIsLoading(false);
        
            navigate('/dataGrid');
        } catch (error) {
            setDeleteError(error?.response?.data?.message);
            setShowDeleteConfirm(false);
            setIsLoading(false);
        }
    }

    const handleOnNameChange = (event) => {
        const newName = event.target.value;
        setInternalName(newName);
    }

    const handleOnCreditChange = (event) => {
        const newCredit = event.target.value;
        setInternalCredit(newCredit);
    }

    const handleOnLastNameChange = (event) => {
        const newLastName = event.target.value;
        setInternalLastName(newLastName);
    }

    const handleOnSave = async () => {
        setIsLoading(true);
    
        await userApiClient.editUser(userSecondId, { 
            firstName: internalName,
            lastName: internalLastName,
            credit: internalCredit
        });
    
        const updatedExercisers = await getAllExercisers();
        setRowData(updatedExercisers.find(exerciser => exerciser.userId === userId));
        setData(updatedExercisers);
    
        setIsLoading(false);
    }

    const isSaveDisabled = useMemo(() => {
        return internalName === name 
            && internalLastName === lastName
            && internalCredit === credit
    }, [internalName, internalLastName, lastName, name, internalCredit, credit])

    return (
        <div id='details'>
            <h3 className='details-heading'>{HeaderName()}</h3>
            <div className='details-container'>
                <div className='details-box'>
                    {name &&
                        <div id="grid-container">
                            <label>Name</label>
                            <input
                                type="text"
                                className="input-fields--edit box-item"
                                id="name"
                                value={internalName}
                                onChange={handleOnNameChange}
                            />
                        </div>
                    }
                    {lastName &&
                        <div id="grid-container">
                            <label>Last name</label>
                            <input
                                type="text"
                                className="input-fields--edit box-item"
                                id="lastName"
                                value={internalLastName}
                                onChange={handleOnLastNameChange}
                            />
                        </div>
                    }
                    {username &&
                        <div id="grid-container">
                            <label>Username</label>
                            <div className='box-item input-fields--edit' >{username}</div>
                        </div>
                    }
                    {userId &&
                        <div id="grid-container">
                            <label>Exerciser Id</label>
                            <div className='input-fields--edit box-item'>{userId}</div>
                        </div>
                    }
                    {email &&
                        <div id="grid-container">
                            <label>Email</label>
                            <div className='input-fields--edit box-item'>{email}</div>
                        </div>
                    }
                    {nationality &&
                        <div id="grid-container">
                            <label>Nationality</label>
                            <div className='input-fields--edit box-item'>{nationality}</div>
                        </div>
                    }
                    {gender &&
                        <div id="grid-container">
                            <label>Gender</label>
                            <div className='input-fields--edit box-item'>{gender}</div>
                        </div>
                    }
                    {credit !== null && credit !== undefined &&
                        <div id="grid-container">
                            <label>Credit</label>
                            <input
                                type="text"
                                className="input-fields--edit box-item"
                                id="name"
                                value={internalCredit}
                                onChange={handleOnCreditChange}
                            />
                        </div>
                    }
                    {
                        showEditActions && (
                            <Button
                                variant="contained"
                                color="error"
                                component="span"
                                startIcon={<DeleteIcon />}
                                sx={{ marginTop: 1.5, width: "61ch" }}
                                onClick={handleOnClickDelete}
                            >
                                Delete exerciser
                            </Button>
                        )
                        
                    }
                    {
                        deleteError && (
                            <Alert sx={{ marginTop: 1.5, width: "80ch" }} severity="error">Failed to delete <br/>{deleteError}</Alert>
                        )
                    }
                    {
                        showEditActions && (
                            <Button
                                variant="contained"
                                component="span"
                                disabled={isSaveDisabled}
                                onClick={handleOnSave}
                                sx={{ marginTop: 1.5, width: "61ch" }}
                            >
                                Save
                            </Button>
                        )
                    }
                </div>
                <div className='details-box'>
                        {bundlePackage &&
                            <div id="grid-container">
                                <label>Package</label>
                                <div className='box-item'>{bundlePackage}</div>
                            </div>
                        }
                        {timeOfPurchase &&  
                            <div id="grid-container">
                                <label>Time And Date Of Purchase</label>
                                <div className='box-item'>{timeOfPurchase}</div>
                            </div>
                        }
                        {price && 
                            <div id="grid-container">
                                <label>Price</label>
                                <div className='box-item'>{price}</div>
                            </div> 
                        }
                        {expiryDate &&
                            <div id="grid-container">
                                <label>Package Expiry Date</label>
                                <div className='box-item'>{expiryDate}</div>
                            </div>
                        }
                        {remainingSessions !== undefined &&
                            <div id="grid-container">
                                <label>Session Remaining</label>
                                <div className='box-item'>{remainingSessions}</div>
                            </div>
                        }
                        {coachName &&
                            <div id="grid-container">
                                <label>Coach Name</label>
                                <div className='box-item'>{coachName}</div>
                            </div>
                        }
                        {coachLastName && 
                            <div id="grid-container">
                                <label>Coach Last Name</label>
                                <div className='box-item'>{coachLastName}</div>
                            </div>
                        }
                </div>
                <div className='details-box'>
                        {coachPhone && 
                            <div id="grid-container">
                                <label>Coach Phone Number</label>
                                <div className='box-item'>{coachPhone}</div>
                            </div>
                        }
                        {coachEmail && 
                            <div id="grid-container">
                                <label>Coach Email</label>
                                <div className='box-item'>{coachEmail}</div>
                            </div>
                        }
                        {coachGender && 
                            <div id="grid-container">
                                <label>Coach Gender</label>
                                <div className='box-item'>{coachGender}</div>
                            </div>             
                        }
                        {trainingSessionNumber && 
                            <div id="grid-container">
                                <label>Training Session Number</label>
                                <div className='box-item'>{trainingSessionNumber}</div>
                            </div>             
                        }
                        {startDateTime &&
                            <div id="grid-container">
                                <label>Session Start</label>
                                <div  className='box-item' id="session-time" >{startDateTime.map(date => {
                                    return <div>{date}&nbsp;</div>
                                    })}
                                </div>
                            </div>
                        }
                        {endDateTime &&
                            <div id="grid-container">
                                <label>Session End</label>
                                <div className='box-item' id="session-time" >{endDateTime.map(date => {
                                    return <div>{date}&nbsp;</div>
                                    })}</div>
                            </div>                         
                        }
                        {sessionStart &&
                            <div id="grid-container">
                                <label>Session Start</label>
                                <div className='box-item'>{sessionStart}</div>
                            </div>
                        }
                        {sessionEnd &&
                            <div id="grid-container">
                                <label>Session End</label>
                                <div className='box-item'>{sessionEnd}</div>
                            </div>
                        }
                        {bookedDateTime &&
                            <div id="grid-container">
                                <label>Booking Date and Time</label>
                                <div className='box-item'>{bookedDateTime}</div>
                            </div>
                        }
                        {trainingLocation && 
                        <div id="grid-container">
                            <label>Session Location</label>
                            <div className='box-item' id="session-time" >{trainingLocation.map(location => {
                                return <div>{location}&nbsp;</div>
                                })}</div>
                        </div>
                        }
                        {oneSessionLocation &&
                            <div id="grid-container">
                                <label>Session Location</label>
                                <div className='box-item'>{oneSessionLocation}</div>
                            </div>
                        } 
                        {numberOfCompletedSessions !== undefined &&
                            <div id="grid-container">
                                <label>Number Of Completed Sessions</label>
                                <div className='box-item'>{numberOfCompletedSessions}</div>
                            </div>
                        }
                        {cancelationDateTime &&
                            <div id="grid-container">
                                <label>Cancelation Date and Time</label>
                                <div className='box-item'>{cancelationDateTime}</div>
                            </div>
                        }
                </div>
            </div>
            <Dialog open={showDeleteConfirm} onClose={handleOnCloseDeleteConfirmDialog}>
                <div className="delete-dialog-container">
                    <p>Are you sure you want to delete this exerciser?</p>
                    <div className="delete-dialog-actions-container">
                        <Button
                            variant="contained"
                            onClick={handleOnConfirmDelete}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ marginLeft: 1 }}
                            onClick={handleOnCloseDeleteConfirmDialog}
                        >
                            No
                        </Button>
                    </div>
                </div>
            </Dialog>
            {
                isLoading && (
                    <div className="action-blocking-overlay">
                        <Loader />
                    </div>
                )
            }
        </div>
    );
}