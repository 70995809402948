import * as React from 'react';

export default function DataDetails({userData}) {
const {name, lastName, username, userId, email, timeOfPurchase, price, startDateTime,
        endDateTime, phone, rowType, package: bundlePackage,
        nationality, gender, exerciserName, exerciserLastName,
        exerciserEmail, exerciserGender, trainingLocation, oneSessionLocation,
        remainingSessions, expiryDate, numberOfCompletedSessions,
        bookedDateTime, sessionStart, sessionEnd, trainingSessionNumber,
        cancelationDateTime} = userData;

    const HeaderName = () => {
        if(rowType.includes('Trainer')) {
            return rowType.split('Trainer')
        } else if(rowType.includes('Exerciser')) {
            return rowType.split('Exerciser')
        }else return rowType;
    }

  return (
      <div id='details'>
          <h3 className='details-heading'>{HeaderName()}</h3>

          <div className='details-container'>
                <div className='details-box'>
                    {name &&
                        <div id="grid-container">
                            <label>Name</label>
                            <div className='box-item' >{name}</div>
                        </div>
                    }
                    {lastName &&
                        <div id="grid-container">
                            <label>Last Name</label>
                            <div className='box-item' >{lastName}</div>
                        </div>
                    }
                    {username &&
                        <div id="grid-container">
                            <label>Username</label>
                            <div className='box-item' >{username}</div>
                        </div>
                    }
                    {userId &&
                        <div id="grid-container">
                            <label>Coach Id</label>
                            <div className='box-item'>{userId}</div>
                        </div>
                    }
                    {email &&
                        <div id="grid-container">
                            <label>Email</label>
                            <div className='box-item'>{email}</div>
                        </div>
                    }
                    {phone &&
                        <div id="grid-container">
                            <label>Phone Number</label>
                            <div className='box-item'>{phone}</div>
                        </div>
                    }
                    {nationality &&
                        <div id="grid-container">
                            <label>Nationality</label>
                            <div className='box-item'>{nationality}</div>
                        </div>
                    }
                </div>
                <div className='details-box'>
                    {gender &&
                        <div id="grid-container">
                            <label>Gender</label>
                            <div className='box-item'>{gender}</div>
                        </div>
                    }
                    {exerciserName &&
                        <div id="grid-container">
                            <label>Exerciser Name</label>
                            <div className='box-item'>{exerciserName}</div>
                        </div>
                    }
                    {bundlePackage &&
                        <div id="grid-container">
                            <label>Package</label>
                            <div className='box-item'>{bundlePackage}</div>
                        </div>
                    }
                    {timeOfPurchase &&  
                        <div id="grid-container">
                            <label>Time And Date Of Purchase</label>
                            <div className='box-item'>{timeOfPurchase}</div>
                        </div>
                    }
                    {price && 
                        <div id="grid-container">
                            <label>Price</label>
                            <div className='box-item'>{price}</div>
                        </div> 
                    }
                    {expiryDate &&
                        <div id="grid-container">
                            <label>Package Expiry Date</label>
                            <div className='box-item'>{expiryDate}</div>
                        </div>
                    }
                    {remainingSessions !== undefined &&
                        <div id="grid-container">
                            <label>Session Remaining</label>
                            <div className='box-item'>{remainingSessions}</div>
                        </div>
                    }
                </div>
                <div className='details-box'>
                    {exerciserLastName && 
                        <div id="grid-container">
                            <label>Exerciser Last Name</label>
                            <div className='box-item'>{exerciserLastName}</div>
                        </div>
                    }
                    {exerciserEmail && 
                        <div id="grid-container">
                            <label>Exerciser Email</label>
                            <div className='box-item'>{exerciserEmail}</div>
                        </div>
                    }
                    {exerciserGender && 
                        <div id="grid-container">
                            <label>Exerciser Gender</label>
                            <div className='box-item'>{exerciserGender}</div>
                        </div>             
                    }
                    {trainingSessionNumber && 
                        <div id="grid-container">
                            <label>Training Session Number</label>
                            <div className='box-item'>{trainingSessionNumber}</div>
                        </div>             
                    }
                    {startDateTime &&
                        <div id="grid-container">
                            <label>Session Start</label>
                            <div  className='box-item' id="session-time" >{startDateTime.map(date => {
                                return <div>{date}&nbsp;</div>
                                })}
                            </div>
                        </div>
                    }
                    {endDateTime &&
                        <div id="grid-container">
                            <label>Session End</label>
                            <div className='box-item' id="session-time" >{endDateTime.map(date => {
                                return <div>{date}&nbsp;</div>
                                })}</div>
                        </div>                         
                    }
                    {sessionStart &&
                        <div id="grid-container">
                            <label>Session Start</label>
                            <div className='box-item'>{sessionStart}</div>
                        </div>
                    }
                    {sessionEnd &&
                        <div id="grid-container">
                            <label>Session End</label>
                            <div className='box-item'>{sessionEnd}</div>
                        </div>
                    }
                    {bookedDateTime &&
                        <div id="grid-container">
                            <label>Booking Date and Time</label>
                            <div className='box-item'>{bookedDateTime}</div>
                        </div>
                    }
                    {trainingLocation && 
                        <div id="grid-container">
                            <label>Session Location</label>
                            <div className='box-item' id="session-time" >{trainingLocation.map(location => {
                                return <div>{location}&nbsp;</div>
                                })}</div>
                        </div>
                    }
                    {numberOfCompletedSessions !== undefined &&
                        <div id="grid-container">
                            <label>Number Of Completed Sessions</label>
                            <div className='box-item'>{numberOfCompletedSessions}</div>
                        </div>
                    } 
                    {oneSessionLocation &&
                        <div id="grid-container">
                            <label>Session Location</label>
                            <div className='box-item'>{oneSessionLocation}</div>
                        </div>
                    } 
                    {cancelationDateTime &&
                        <div id="grid-container">
                            <label>Cancelation Date and Time</label>
                            <div className='box-item'>{cancelationDateTime}</div>
                        </div>
                    }
                </div>
          </div>
      </div>
    
  );
}