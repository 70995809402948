import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Button, CardMedia, Dialog, Input } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

import { DataContext } from "../../../context/DataContext";
import { userService } from "../../../services";
import Loader from "../../loader/Loader";

import "./GymInformation.scss";

export default function GymInformation({ gym }) {
    const {
        rowType,
        name,
        imageUrl,
        _id
    } = gym;

    const navigate = useNavigate();
    const { setData, setRowData, data } = useContext(DataContext);

    const [internalName, setInternalName] = useState(name);
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const handleUploadImage = async (file) => {
        let formData = new FormData();
        if (file) {
            setIsLoading(true);

            formData.append("imageUrl", file);
            const result = await userService.updateGymImage(_id, formData);
            setRowData(result);
            
            const updatedGyms = await userService.getAllGyms();
            setData(updatedGyms);

            setIsLoading(false);
        }
    };

    const handleChangeImage = () => (event) => {
        if (event.target.files) {
            const file = event.target.files[0];
            handleUploadImage(file);
        }
    };

    const handleOnNameChange = (e) => {
        const newName = e.nativeEvent.target.value;
        setInternalName(newName);
    };

    const handleOnSave = async () => {
        setIsLoading(true);

        await userService.updateGym(_id, {
            name: internalName,
        });

        const updatedGyms = await userService.getAllGyms();
        setData(updatedGyms);
        setRowData(updatedGyms.find(gym => gym._id === _id));

        setIsLoading(false);
    };

    const handleOnClickDelete = () => {
        setShowDeleteConfirm(true);
    }

    const handleOnCloseDeleteConfirmDialog = () => {
        setShowDeleteConfirm(false);
    }

    const handleOnConfirmDelete = async () => {
        setIsLoading(true);

        await userService.deleteGym(_id);

        const updatedGyms = await userService.getAllGyms();
        setData(updatedGyms);

        navigate('/dataGrid');

        setIsLoading(false);
    }

    const isSaveDisabled = useMemo(() => {
        if (internalName === name)
            return true;
        return false;
    }, [internalName, name, data]);

    return (
        <div>
            <h3 className="details-heading">{rowType}</h3>
            <div className="details-container">
                <div className="details-box">
                    <div className="input-container">
                        <label>Name</label>
                        <input
                            type="text"
                            className="input-fields"
                            id="gymName"
                            value={internalName}
                            onChange={handleOnNameChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>Image</label>
                        {
                            imageUrl?.length ? (
                                <CardMedia
                                    component="img"
                                    src={imageUrl}
                                    alt="Location image"
                                />
                            ) : <p style={{ marginLeft: 15 }}>No image found</p>
                        }
                        
                        <label htmlFor="gym-image-file">
                            <Input
                                style={{ display: "none" }}
                                accept=".png, .jpg, .jpeg"
                                id="gym-image-file"
                                type="file"
                                key="image"
                                onChange={handleChangeImage()}
                            />
                            <Button
                                variant="contained"
                                component="span"
                                startIcon={<CloudUploadIcon />}
                                sx={{ marginTop: 1.5 }}
                            >
                                Upload Image
                            </Button>
                        </label>
                        <Button
                            variant="contained"
                            component="span"
                            disabled={isSaveDisabled}
                            onClick={handleOnSave}
                            sx={{ marginTop: 1.5 }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            component="span"
                            startIcon={<DeleteIcon />}
                            sx={{ marginTop: 1.5 }}
                            onClick={handleOnClickDelete}
                        >
                            Delete gym
                        </Button>
                    </div>
                </div>
            </div>
            <Dialog open={showDeleteConfirm} onClose={handleOnCloseDeleteConfirmDialog}>
                <div className="delete-gym-confirm-dialog-container">
                    <p>Are you sure you want to delete this gym?</p>
                    <div className="delete-gym-confirmation-dialog-actions-container">
                        <Button
                            variant="contained"
                            onClick={handleOnConfirmDelete}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ marginLeft: 1 }}
                            onClick={handleOnCloseDeleteConfirmDialog}
                        >
                            No
                        </Button>
                    </div>
                </div>
            </Dialog>
            {
                isLoading && (
                    <div className="action-blocking-overlay">
                        <Loader />
                    </div>
                )
            }
        </div>
    );
}
