import React, { useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import { CategoryContext } from '../../context/CategoriesContext';
import { DataContext } from '../../context/DataContext';
import {LoaderContext} from "../../context/LoaderContext";

import Loader from "../loader/Loader";

import { userService } from '../../services';

import { setRowDataToStorage } from '../../helper/storageFunctions';

import MockData from "../../mock/MockData";

//style
import "./GridComponent.scss";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
  
export default function GridComponent(props) {
  const navigate = useNavigate();
  const {loaderVisible, setLoaderVisible} = useContext(LoaderContext)
  const { setData } = useContext(DataContext);
  const { setSubCategory } = useContext(CategoryContext);

  const  onClick = async (subCategory) => {
    if (subCategory == 'Create New Admin') {
       navigate('/addAdminPage');
    } else {
      
      let endPointName = MockData[subCategory];
      setLoaderVisible(!loaderVisible);
      
      const result = await userService[endPointName]();

      setLoaderVisible(false);
      if((result?.length >= 1 || result?.hasOwnProperty('all') || result?.data?.hasOwnProperty('exercisers')) && subCategory !== "Expired Training Package History Exerciser"
      && subCategory !== "Expired Training Package History Coach" ) {
        setData(result);
        setRowDataToStorage(result);
        localStorage.setItem('col', JSON.stringify(subCategory));
        setSubCategory(subCategory);
        
        navigate('/dataGrid');
      } else if(result?.length === 0 || subCategory == "Expired Training Package History Exerciser"
      || subCategory == "Expired Training Package History Coach") {
        alert(`No Result For ${subCategory}!`);
      }else {
        alert("Something went wrong!")
      }
    }
    
  }

  const splitSubCategory = (subCategory) => {
    let newSubCategory;
    if(subCategory.includes('trainer')) {
      newSubCategory =  subCategory.split('trainer');
    } else if(subCategory.includes('exerciser')) {
      newSubCategory = subCategory.split('exerciser');
    }else newSubCategory = subCategory;

    return newSubCategory;
  }


  return (
    <div>
      {loaderVisible && <Loader/>}

      <div className='categories-container'>

        <Box sx={{ width: '100%' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {props.subCategories.map(subCategory => {
          return <Grid key={subCategory} item xs={6}>
                      <Item onClick={() => onClick(subCategory)} id='sub-category'>{splitSubCategory(subCategory)}</Item>
                  </Grid>
            })}
          </Grid>
        </Box>
      </div>
    </div>
    
  );
}
