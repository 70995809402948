import * as  userApiClient from '../apiClient/userApiClient';

export const login = async data => {

  const payload = {
    email: data.email.toLowerCase().trim(),
    password: data.password,
  };

  const token = await userApiClient.login(payload);
  return token;
};

export const signUp = async data => {

  const token = await userApiClient.signUp(data);

  return token;
};

export const verifyEmail = async data => {

  const token = await userApiClient.verifyEmail(data);

  return token;
};

export const resendVerification = async data => {

  const token = await userApiClient.resendVerification(data);

  return token;
};