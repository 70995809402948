import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { CategoryContext } from '../../context/CategoriesContext';
import { DataContext } from '../../context/DataContext';
import Columns from "../../mock/columns";
import { useNavigate } from 'react-router-dom';

import { setRowDetailsToStorage } from '../../helper/storageFunctions';

//style
import './GridData.scss';

const subCategoriesWithCreateButton = [
  "Manage Gyms",
  "Manage Zones"
];

const createPagePerSubcategory = {
  "Manage Gyms": "Gym Create",
  "Manage Zones": "Zone Create"
};


export default function GridData () {
  const navigate = useNavigate();
  const { subCategory } = useContext(CategoryContext);
  const { data, setRowData } = useContext(DataContext);
  const [sortModel, setSortModel] = useState([]);

  let col = Columns[subCategory];
  const rows = data;

  rows.forEach((row, index) => {
    row.id = index;
  });

  useEffect(() => {
    const createdAt = col.find(c => c.field === "createdAt");
    const timeOfPurchase = col.find(c => c.field === "timeOfPurchase");

    if (timeOfPurchase) {
      setSortModel([
        { field: "timeOfPurchase", sort: "desc" }
      ]);
      return;
    }

    if (createdAt) {
      setSortModel([
        { field: "createdAt", sort: "desc" }
      ]);
    }
  }, [subCategory]);

  const showTimeAndDateModal = (rowObject) => {
      navigate('/dataGrid/rowDetails');
      setRowData(rowObject.row);
      setRowDetailsToStorage(rowObject.row);
  }

  const handleCreateNew = () => {
    navigate('/dataGrid/rowDetails');
    setRowData({
      rowType: createPagePerSubcategory[subCategory]
    });
  }

  const showCreateButton = useMemo(() => {
    if (subCategoriesWithCreateButton.includes(subCategory))
      return true;
    return false;
  }, [subCategory]);

  const rowHeight = useMemo(() => {
    if (subCategory === "Manage Gyms" || subCategory === "Manage Zones")
      return 150;

    return 60;
  }, [subCategory]);

  return (
    <div className='data-grid-container'>
      <DataGrid
        onRowClick={(rowObject) => showTimeAndDateModal(rowObject)}
        rows={rows}
        columns={col}
        autoPageSize
        rowsPerPageOptions={[12]}
        disableColumnSelector
        columnThreshold={0}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        rowHeight={rowHeight}
      />
      {
        showCreateButton && (
          <div className='create-button-container'>
            <Button 
              variant="contained"
              startIcon={<AddBoxIcon/>}
              sx={{ width: 150, height: 40 }}
              onClick={handleCreateNew}
            >
              Create new
            </Button>
          </div>
        )
      }
    </div>
  )
  
}

