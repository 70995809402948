import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';

import * as  userApiClient from '../../../apiClient/userApiClient';

import PageTemplate from '../../../components/templates/PageTemplate/PageTemplate';

export default function EditAdmin() {
  const adminInfo = JSON.parse(localStorage.getItem('loggedAdmin'));

  const Navigate = useNavigate();

  const [editData, setEditData] = useState({
    firstName: '',
    lastName: '',
  });

  const isButtonDisabled =
    editData.firstName === '' ||
    editData.lastName === '';


  const handleChange = (name, value) => {
    setEditData({ ...editData, [name]: value });
  };

  const handleSubmitInfoChange = async (event) => {
    event.preventDefault();
    await userApiClient.editAdmin(editData, adminInfo.userId);
    adminInfo.name = editData.firstName
    adminInfo.lastName = editData.lastName
    localStorage.setItem('loggedAdmin', JSON.stringify(adminInfo));
    Navigate('/home');
  };

  return (
    <PageTemplate>
      <div>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Edit Profile
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmitInfoChange} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    placeholder={adminInfo.name}
                    required
                    fullWidth
                    id="firstName"
                    label={adminInfo.name}
                    autoFocus
                    onChange={(event) => handleChange('firstName', event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label={adminInfo.lastName}
                    name="lastName"
                    placeholder={adminInfo.lastName}
                    autoComplete="family-name"
                    onChange={(event) => handleChange('lastName', event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label={adminInfo.email}
                    name="email"
                    placeholder={adminInfo.email}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Button
                disabled={isButtonDisabled}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
    </PageTemplate>
  );
}