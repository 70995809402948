import React, { useContext, useEffect } from "react";

import { DataContext } from "../../context/DataContext";
import { CategoryContext } from "../../context/CategoriesContext";

import PageTemplate from '../../components/templates/PageTemplate/PageTemplate';
import GridData from "../../components/gridData/GridData";
import {NewTrainers, Trainers, InactiveTrainers,
        PercentageActiveTrainers} from "../../components/reportsAndRevenues/trainersReports/index";
import {NewExercisers, Exercisers, InactiveExercisers,
        PercentageActiveExercisers} from "../../components/reportsAndRevenues/exerciserReports/index";
import { 
        NumberOfPackagesPurchased, NumberOfSessionsPurchased, TotalTrainingPurchased, TrainingCompleted, TrainingExpired,
        TrainingBooked, TrainingCancelled,ValueOfBookedTrainings, ValueOfOutstandingTrainings, ValueOfCompletedTrainings,
        ValueOfExpiredTrainings, ValueOfCancelledTrainings, TotalValueOfTrainingPackagesPurchased, AverageValueOfAllPackagesPurchased,
        AverageValueBookedSessions, AverageValueCompletedSessions, AverageValueOfCancelledTraining, AverageValueExpiredSessions, NumberOfOutstandingTraining, 
        AverageValueOfOustandingTraining, TotalValueOutstadndingVsTotalValueOfPurchasedTrainings, TotalValueCompletedVsTotalValueOfPurchasedTrainings, 
        TotalNumberOutstandingVsTotalNumberOfPurchasedTrainings, TotalNumberCompletedVsTotalNumberOfPurchasedTrainings
} from "../../components/reportsAndRevenues/revenues";
import Loader from "../../components/loader/Loader";        
import { LoaderContext } from "../../context/LoaderContext";
import { getRowDataFromStorage } from "../../helper/storageFunctions";


const DataGrid = () => {
    const { data, setData } = useContext(DataContext);
    const { subCategory, setSubCategory } = useContext(CategoryContext);
    const { dataGridLoader, setDataGridLoader } = useContext(LoaderContext);


    useEffect(() => {
        if(!data) {
            const dataStorage = getRowDataFromStorage();
            window.onbeforeunload = setData(dataStorage);

            const subCategoryStorage = JSON.parse(localStorage.getItem('col'));
            window.onbeforeunload = setSubCategory(subCategoryStorage);
        }    
    }, [])

    useEffect(() => {
        if(localStorage.getItem('loginTime')) {
            const loginObject = JSON.parse(localStorage.getItem('loginTime'));
            const loginTime = loginObject.timestamp;
            const now = new Date();
            now.setHours(now.getHours());
            let nowJSON = JSON.stringify(now);
            let nowParse = JSON.parse(nowJSON);
            
            if(nowParse > loginTime) {
                localStorage.clear()
            }
        }
      }, [])
    
    const showGridData = () => {
        if(data?.length >= 1) {
            return <GridData/>
        }
    }

    const showReportAndRevenues = () => {
            switch(subCategory) {
                case 'Number Of New Registered Exercisers' :
                    return  <NewExercisers data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Number Of New Registered Coaches' :
                    return    <NewTrainers data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/> 
                case 'Number Of Registered Exercisers' :
                    return    <Exercisers/> 
                case 'Number Of Registered Coaches' :
                    return <Trainers/>  
                case 'Number Of Inactive Exercisers' : 
                    return <InactiveExercisers data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>     
                case 'Number Of Inactive Coaches' :
                    return <InactiveTrainers data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>   
                case 'Percentage Of Total Number Of Active Coaches vs Total Number Of Registered Coaches' : 
                    return <PercentageActiveTrainers data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>   
                case 'Percentage Of Total Number Of Active Exercisers vs Total Number Of Registered Exercisers' :
                    return <PercentageActiveExercisers data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/> 
                case 'Number Of Training Packages Purchased' :
                    return <NumberOfPackagesPurchased data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>    
                case 'Number Of Training Sessions Purchased' :
                    return <NumberOfSessionsPurchased data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Number Of Outstanding Training' :
                    return <NumberOfOutstandingTraining data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Total Number Of Training Purchased In All The Packages Purchased' :
                    return <TotalTrainingPurchased data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>  
                case 'Number Of Training Completed' :
                    return <TrainingCompleted data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>    
                case 'Number Of Expired Training' :
                    return <TrainingExpired data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>    
                case 'Number Of Cancelled Training' :
                        return <TrainingCancelled data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/> 
                case 'Number Of Booked Training' :
                    return <TrainingBooked data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>       
                // case 'Value Of Active Training Packages Purchased' :
                //     return <ValueOfActiveTrainings data={data} loader={setDataGridContext}/>  
                case 'Value Of Booked Training Packages Purchased' :
                    return <ValueOfBookedTrainings data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>   
                case 'Value Of Completed Training Packages Purchased' :
                    return <ValueOfCompletedTrainings data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Value Of Expired Training Packages Purchased' :
                    return <ValueOfExpiredTrainings data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Value Of Outstanding Training Packages Purchased' :
                    return <ValueOfOutstandingTrainings data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Value Of Cancelled Training' :
                    return <ValueOfCancelledTrainings data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Total Value Of Training Packages Purchased' :
                    return <TotalValueOfTrainingPackagesPurchased data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>  
                   case 'Average Value Of Training From All Training Packages Purchased' :
                        return <AverageValueOfAllPackagesPurchased data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>     
                case 'Average Value Of Booked Training' :
                    return <AverageValueBookedSessions data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Average Value Of Completed Training' :
                    return <AverageValueCompletedSessions data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/> 
                case 'Average Value Of Expired Training' :
                    return <AverageValueExpiredSessions data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Average Value Of Outstanding Training' :
                    return <AverageValueOfOustandingTraining data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Average Value Of Cancelled Training' :
                    return <AverageValueOfCancelledTraining data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Percetange Of Total Number Completed Training Vs Total Number Of Purchased Training' :
                    return <TotalNumberCompletedVsTotalNumberOfPurchasedTrainings data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                // case 'Percetange Of Total Number Of Expired Training Vs Total Number Of Purchased Training' :
                //     return <TotalNumberExpiredVsTotalNumberOfPurchasedTrainings/>
                case 'Percetange Of Total Number Of Outstanding Training Vs Total Number Of Purchased Training' :
                  return <TotalNumberOutstandingVsTotalNumberOfPurchasedTrainings data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Percetange Of Total Value Of Completed Training Vs Total Value Of Purchased Training' :
                  return <TotalValueCompletedVsTotalValueOfPurchasedTrainings data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                case 'Percetange Of Total Value Of Outstanding Training Vs Total Value Of Purchased Training' :
                  return <TotalValueOutstadndingVsTotalValueOfPurchasedTrainings data={data} setLoader={setDataGridLoader} loader={dataGridLoader}/>
                // case 'Percetange Of Total Value Of Expired Training Vs Total Value Of Purchased Training' :
                //   return <TotalValueExpiredVsTotalValueOfPurchasedTrainings/>
            
                }
    }

    return (
        <PageTemplate>
           {dataGridLoader && <Loader/>}
           {showGridData()}
           {showReportAndRevenues()}
        </PageTemplate>
    )
}

export default DataGrid;