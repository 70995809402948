import React from "react";

const ImageCell = ({ row }) => {
    const { imageUrl } = row;
    if (!imageUrl) {
        return <p>No image</p>
    }

    return (
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
            <img style={{ height: "95%" }} src={imageUrl} />
        </div>
    )
}

export default ImageCell