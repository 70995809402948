import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { AuthenticationContext } from '../../../context/AuthenticationContext';

import { removeFromLocalStorage } from "../../../helper/Token";


const theme = createTheme();

export default function VerifyEmail() {
  removeFromLocalStorage();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
  };

  const { verifyEmail } = useContext(AuthenticationContext);

  const [verifyData, setVerifyData] = useState({
    code: '',
  });

  const handleChange = (name, value) => {
    setVerifyData({ ...verifyData, [name]: value });
  };

  const [errorMessage, setErrorMessage] = useState();


  const successCallback = () => {
  };

  const errorCallback = (error) => {
    setErrorMessage(error);
  };

  const handleVerifyEmail = () => {
    verifyEmail(verifyData, successCallback, errorCallback);
  };

  const isVerifyButtonDisabled =
    verifyData.email === '' || verifyData.code === '';

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Verify email
          </Typography>
          <Box component="form" onSubmit={handleVerifyEmail} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="verification-code"
              label="Verification code"
              id="verification-code"
              autoComplete="verification-code"
              onChange={(event) => handleChange('code', event.target.value)}
            />
            <Button
              disabled={isVerifyButtonDisabled}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Verify
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}