import React, { useEffect, useState } from 'react';
import GridComponent from "../../gridComponent/GridComponent";

//style
import "./AdminInfo.scss";

export default function AdminInfo () {
  const adminInfo = JSON.parse(localStorage.getItem('loggedAdmin'));
  
  const [subCategories, setSubCategories] = useState([]);
  useEffect(() => {
    if (adminInfo.type != 'Super Admin') {
      setSubCategories(["Manage Coaches", "Manage Exercisers"])
    } else {
      setSubCategories(["Manage Admins", "Create New Admin", "Manage Coaches", "Manage Exercisers", "Manage Gyms", "Manage Zones"])
    }
  }, [])

  return (

    <>
        <GridComponent
          subCategories={subCategories}
        />
    </>


  )
}
