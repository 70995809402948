export const CalculateDataResult = (data, filterData, setNumberOfUsers, type) => {
    let users = []; 

    if(filterData.fromDate) {
        users = data.data[type].filter(user => {
            return user?.userId?.CreatedAt >= filterData.fromDate;
        })

        if(users.length == 0) {
            setNumberOfUsers(0);
            return;
        }
    }

    if(filterData.toDate && users.length > 0) {
        const toDateusers = users.filter(user => {
            return user?.userId?.CreatedAt <= filterData.toDate;
        })
        users = toDateusers;
        
        if(users.length == 0) {
            setNumberOfUsers(0);
            return;
        }

    } else if(filterData.toDate) {
        users = data.data[type].filter(user => {
            return user?.userId?.CreatedAt <= filterData.toDate;
        })
    }

    if(filterData.minAge && users.length > 0) {
        const minAgeUsers = users.filter(user => {
            const dateOfBirth = new Date().getFullYear() - new Date(user?.userId?.dateOfBirth).getFullYear();
            if(dateOfBirth >= filterData.minAge) {
                return user
            }
        })
        users = minAgeUsers;

        if(users.length == 0) {
            setNumberOfUsers(0);
            return;
        }

    } else if(filterData.minAge) {
        users = data.data[type].filter(user => {
            const dateOfBirth = new Date().getFullYear() - new Date(user?.userId?.dateOfBirth).getFullYear();
            if(dateOfBirth >= filterData.minAge) {
                return user
            }
        })
    }

    if(filterData.maxAge && users.length > 0) {
        const maxAgeUsers = users.filter(user => {
            const dateOfBirth = new Date().getFullYear() - new Date(user?.userId?.dateOfBirth).getFullYear();
            if(dateOfBirth <= filterData.maxAge) {
                return user
            }
        })
        users = maxAgeUsers;

        if(users.length == 0) {
            setNumberOfUsers(0);
            return;
        }

    } else if(filterData.maxAge) {
        users = data.data[type].filter(user => {
            const dateOfBirth = new Date().getFullYear() - new Date(user?.userId?.dateOfBirth).getFullYear();
            if(dateOfBirth <= filterData.maxAge) {
                return user
            }
        })
    }

    if(filterData.nationality && users.length > 0) {
        const usersNationality = users.filter(user => {
            return user?.userId?.nationality == filterData.nationality
        })
        users = usersNationality;

        if(users.length == 0) {
            setNumberOfUsers(0);
            return;
        }

    } else if(filterData.nationality) {
        users = data.data[type].filter(user => {
            return user?.userId?.nationality == filterData.nationality
        })
    }

    if(filterData.specialty && users.length > 0) {
        const usersSpecialty = users.filter(user => {
            return user?.goalIds == filterData.specialty
        })
        users = usersSpecialty;

        if(users.length == 0) {
            setNumberOfUsers(0);
            return;
        }

    } else if(filterData.specialty) {
        users = data.data[type].filter(user => {
            return user?.goalIds == filterData.specialty
        })
    }

    if(type === "exercisers" && Object.keys(filterData).length == 0) {
        users = data.data.exercisers;
    }

    if(type === "trainers" && Object.keys(filterData).length == 0) {
        users = data.data.trainers;
    }

    setNumberOfUsers(users.length);


  }