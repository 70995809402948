import React, { createContext, useContext, useState } from 'react';

import { CategoryContext } from './CategoriesContext';

import { userService } from '../services/index';

import MockData from "../mock/MockData";


const DataContext = createContext();

const DataContextProvider = props => {
    const  {subCategory} = useContext(CategoryContext);

    const [data, setData] = useState(null);
    const [rowData, setRowData] = useState(false);

    const getUserData = async() => {
      const endPointName = MockData[subCategory];

      const userData = await userService[endPointName](); 
      setData(userData);
    }
  
    const providerValue = {
        data, setData, rowData, setRowData, getUserData
    };
  
    return (
      <DataContext.Provider value={providerValue}>
        {props.children}
      </DataContext.Provider>
    );
};

export { DataContext, DataContextProvider };