import React, { useContext, useEffect } from 'react';
import { CategoryContext } from '../../context/CategoriesContext';
import Categories from "../../components/mainContent/filters/Categories";
import PageTemplate from '../../components/templates/PageTemplate/PageTemplate';
import { GetTokenFromLocalStorage } from '../../helper/Token';
import { Navigate } from "react-router-dom";

const HomePage = () => {
    let token = GetTokenFromLocalStorage();
    const {setSubCategory} = useContext(CategoryContext); 

    useEffect(() => {
        setSubCategory(undefined);
    }, [])

    useEffect(() => {
        if(localStorage.getItem('loginTime')) {
            const loginObject = JSON.parse(localStorage.getItem('loginTime'));
            const loginTime = loginObject.timestamp;
            const now = new Date();
            now.setHours(now.getHours());
            let nowJSON = JSON.stringify(now);
            let nowParse = JSON.parse(nowJSON);
            
            if(nowParse > loginTime) {
                localStorage.clear()
            }
        }
      }, [])

    if(!token) {
        return <Navigate to="/" replace />;
    }

    return (
        <PageTemplate>
            <Categories/>
        </PageTemplate>
    );
}


export default HomePage;