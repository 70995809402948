import { useState } from "react";
import Header from "../../header/Header";
import Slider from "../../slider/Slider";

export default function PageTemplate(props) {
    const { children } = props;
    const [ isSliderOpen, setIsSliderOpen ] = useState(false)

    return (
        <div>
            <Header isOpen={isSliderOpen} onSliderOpen={() => setIsSliderOpen(true)}/>
            <Slider isOpen={isSliderOpen} onSliderClose={() => setIsSliderOpen(false)}/>
            {children}
        </div>
    );
}   