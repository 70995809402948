import React from 'react';
import GridComponent from "../../gridComponent/GridComponent";

//style
import "./TrainingAndRevenue.scss";

export default function TrainingAndRevenue () {
    const subCategories = ['Number Of Training Packages Purchased', 'Number Of Training Sessions Purchased', 
        'Total Number Of Training Purchased In All The Packages Purchased', "Number Of Training Completed",
        "Number Of Expired Training", "Number Of Booked Training", "Number Of Outstanding Training",
        "Number Of Cancelled Training","Value Of Booked Training Packages Purchased",
        'Value Of Completed Training Packages Purchased', 'Value Of Cancelled Training',
        'Value Of Outstanding Training Packages Purchased','Total Value Of Training Packages Purchased',
        'Average Value Of Training From All Training Packages Purchased', 'Average Value Of Booked Training',
        'Average Value Of Completed Training','Average Value Of Outstanding Training', 'Average Value Of Cancelled Training',
        'Percetange Of Total Number Completed Training Vs Total Number Of Purchased Training',
        // 'Percetange Of Total Number Of Expired Training Vs Total Number Of Purchased Training',
        'Percetange Of Total Number Of Outstanding Training Vs Total Number Of Purchased Training',
        'Percetange Of Total Value Of Completed Training Vs Total Value Of Purchased Training',
        'Percetange Of Total Value Of Outstanding Training Vs Total Value Of Purchased Training',
        // 'Percetange Of Total Value Of Expired Training Vs Total Value Of Purchased Training',
        ]
        

    return (
      
        <>
            <GridComponent
            subCategories={subCategories}
            />
        </>

     
    )
}
