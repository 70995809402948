import React from 'react';
import GridComponent from "../../gridComponent/GridComponent";

//style
import "./UsersReports.scss";

export default function CoachPanel () {
    const subCategories = ['Number Of New Registered Exercisers', 'Number Of Registered Exercisers',
        "Number Of New Registered Coaches", "Number Of Registered Coaches",
        "Number Of Inactive Exercisers", "Number Of Inactive Coaches",
        "Percentage Of Total Number Of Active Coaches vs Total Number Of Registered Coaches","Percentage Of Total Number Of Active Exercisers vs Total Number Of Registered Exercisers"]

    return (
      
        <>
            <GridComponent
            subCategories={subCategories}
            />
        </>

     
    )
}

