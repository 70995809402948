export const TokenToLocalStorage = (token) => {
    localStorage.setItem('token', token);
}

export const GetTokenFromLocalStorage = () => {
    return localStorage.getItem('token')
}

export const removeFromLocalStorage = () => {
    return localStorage.getItem('token')
}