import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import * as  userApiClient from '../../../apiClient/userApiClient';

import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function AdminInformation({userData}) {
  const { name, lastName, userId, email, type, timeOfPurchase, rowType, isFromProfile } = userData;

  const navigate = useNavigate();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const deleteAdmin = async () => {
    await userApiClient.deleteAdmin(userId);
    navigate('/home');
  }

  const editProfile = async () => {
    navigate('/editAdmin');
  }

  const editPassword = async () => {
    navigate('/editAdminPassword')
  }

  return (
    <div>
      <h3 className='details-heading'>{rowType}</h3>
      <div className="details-container admin-coach-container">
        <div className="details-box">
          {name !== undefined && (
            <div id="grid-container">
              <label>Name</label>
              <div className="box-item">{name}</div>
            </div>
          )}
          {lastName !== undefined && (
            <div id="grid-container">
              <label>Last Name</label>
              <div className="box-item">{lastName}</div>
            </div>
          )}
          {userId !== undefined && (
            <div id="grid-container">
              <label>Id</label>
              <div className="box-item">{userId}</div>
            </div>
          )}
        </div>
        <div className="details-box">
          {email !== undefined && (
            <div id="grid-container">
              <label>Email</label>
              <div className="box-item">{email}</div>
            </div>
          )}
          {timeOfPurchase !== undefined && (
            <div id="grid-container">
              <label>Time And Date Of Purchase</label>
              <div className="box-item">{timeOfPurchase}</div>
            </div>
          )}
          {type !== undefined && (
            <div id="grid-container">
              <label>Gender</label>
              <div className="box-item">{type}</div>
            </div>
          )}
        </div>
      </div>
      {type == 'Regular Admin' && !isFromProfile &&
        <div className='centered-content-container'>
          <Button
            variant='contained'
            component='span'
            sx={{ m: 1.5, width: '50ch' }}
            onClick={deleteAdmin}
          >
            Delete Admin
          </Button>
        </div>
      }
      {isFromProfile &&
        <div className='centered-content-container'>
          {type == 'Super Admin' &&
            <Button
              variant='contained'
              component='span'
              sx={{ m: 1.5, width: '50ch' }}
              onClick={editProfile}
            >
              Edit Profile
            </Button>
          }
          <Button
            variant='contained'
            component='span'
            sx={{ m: 1.5, width: '50ch' }}
            onClick={editPassword}
          >
            Edit Password
          </Button>
        </div>
      }
    </div>

  );
}